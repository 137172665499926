import CIcon from '@coreui/icons-react';
import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { BIcon } from '../icons/BIcon';
import { CProgress, CProgressBar, CProgressStacked } from '@coreui/react-pro';

interface CustomAudioPlayerProps {
    src: string;
}

const AudioPlayerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const PlayPauseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

// const ProgressBar = styled.input`
//   width: 100%;
//   cursor: pointer;
//   height: 4px !important;
// `;


const ProgressBar = styled.input`
  width: 100%;
  height: 5px;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background: linear-gradient(to right, #f00 0%, #f00 0%, #eee 100%, #fff 100%);
  border-radius: 5px;
  outline: none;

  &::-webkit-slider-runnable-track {
    height: 5px;
    background: none;
  }

  &::-moz-range-track {
    height: 5px;
    background: none;
  }

  &::-ms-track {
    height: 5px;
    background: none;
    border-color: transparent;
    color: transparent;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 12px;
    height: 12px;
    background: #000;
    border-radius: 50%;
    cursor: pointer;
    margin-top: -4px;
  }

  &::-moz-range-thumb {
    width: 15px;
    height: 15px;
    background: #000;
    border-radius: 50%;
    cursor: pointer;
  }

  &::-ms-thumb {
    width: 15px;
    height: 15px;
    background: #000;
    border-radius: 50%;
    cursor: pointer;
  }
`;

const TimeDisplay = styled.span`
  font-size: 14px;
  width: 50px;
  text-align: center;
`;

const CustomAudioPlayer: React.FC<CustomAudioPlayerProps> = ({ src }) => {
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState(0);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
            audioRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);
        }
        return () => {
            if (audioRef.current) {
                audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
                audioRef.current.removeEventListener('loadedmetadata', handleLoadedMetadata);
            }
        };
    }, []);

    const handlePlayPause = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const handleTimeUpdate = () => {
        if (audioRef.current) {
            const currentTime = audioRef.current.currentTime;
            const duration = audioRef.current.duration;
            setProgress((currentTime / duration) * 100);
        }
    };

    const handleLoadedMetadata = () => {
        if (audioRef.current) {
            setDuration(audioRef.current.duration);
        }
    };

    const handleProgressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            if (audioRef.current) {
                const newProgress = Number(event.target.value);
                audioRef.current.currentTime = (newProgress / 100) * duration;
                setProgress(newProgress);
            }
        } catch (error) {
            console.log(error);
        }

    };

    const formatTime = (time: number): string => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <AudioPlayerContainer>
            <PlayPauseButton onClick={handlePlayPause}>
                {isPlaying ? <BIcon icon={'pause'} className='text-danger' /> : <BIcon icon={'play-fill'} className='text-danger' />}
            </PlayPauseButton>
            <ProgressBar
                type="range"
                min="0"
                max="100"
                value={progress}
                onChange={handleProgressChange}

            />
            {/* <CProgressBar
                // type='range'
                // min="0"
                // max="100"
                animated
                color='black'
                className='bg-dark text-dark h-25'
                value={progress}
                onChange={handleProgressChange}
            /> */}
            {/* <CProgress height={10} color='danger'>
                <CProgressBar value={25}></CProgressBar>
            </CProgress> */}
            {/* <CProgressStacked /> */}
            <TimeDisplay>{formatTime(audioRef.current?.currentTime || 0)}</TimeDisplay>
            <audio ref={audioRef} src={src}></audio>
        </AudioPlayerContainer>
    );
};

export default CustomAudioPlayer;
