import { useEffect, useState } from 'react';
import './scss/style.scss'
import "@coreui/coreui-pro/dist/js/coreui.bundle.min.js";
import "@coreui/coreui-pro/dist/css/coreui.min.css";
import "primereact/resources/themes/nova-accent/theme.css";
import "primereact/resources/primereact.min.css";
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import SideBar from './layout/SideBar';
import Dashboard from './views/dashboard/Dashboard';
import Page404 from './views/pages/page404/Page404';
import { Login } from './views/pages/login/Login';
import { SignUp } from './views/pages/signup/SignUp';
import { Accounts } from './views/administration/Accounts';
import { Payments } from './views/payments/Payments';
import { Transactions } from './views/administration/Transactions';
import { Messages } from './views/messages/Messages';
import { Send } from './views/messages/Send';
import { Campaigns } from './views/campaigns/Campaigns';
import { SendCampaign } from './views/campaigns/SendCampaign';
import { Groups } from './views/groups/Groups';
import { GroupDetails } from './views/groups/GroupDetails';
import { Contacts } from './views/contacts/Contacts';
import { CampaingMessages } from './views/campaigns/CampaignsMessages';
import { CreateGroups } from './views/groups/CreateGroups';
import { GroupManage } from './views/groups/GroupManage';
import { Apis } from './views/developer/Apis';
import { ErrorCodes } from './views/developer/ErrorCodes';
import { UsersAdministrator } from './views/controlpanel/UsersAdministrator';
import { AccountsAdministrator } from './views/controlpanel/AccountsAdministrator';
import { ProvidersAdministrator } from './views/controlpanel/ProvidersAdministrator';
import { ErrorCodesAdminsitrator } from './views/controlpanel/ErrorCodesAdministrator';
import { PlansAdministrator } from './views/controlpanel/PlansAdministrator';
import { LoginAsUser } from './views/controlpanel/LoginAsUser';
import { useIdleTimer } from 'react-idle-timer'
import { env } from './Utils/Environment';
import * as MarketsmsService from "./services/MarketsmsService";
import { getBaseToken, getToken, userLogout, saveBaseToken, saveToken, verifyRefreshToken } from './Utils/Auth';
import { Templates } from './views/whatsapp/Templates';
import { getDeviceData, showToastTR } from './Utils/BaseApp';
import { getToken as getTokenFirebase, onMessage } from "firebase/messaging";
import { messaging } from './firebase/FirebaseConfig';
import { ToastContainer } from 'react-toastify';
import LoadingContainer from './components/loading/LoadingContainer';
import ConfirmContainer from './components/confirm/ConfirmContainer';
import { Documentation } from './views/developer/Documentation';
import { Profile } from './views/administration/Profile';
import { ChangePassword } from './views/settings/ChangePassword';
import { Help } from './views/settings/Help';
import { WhatsappIntegration } from './views/whatsapp/WhatsappIntegration';
import { WhatsappConversations } from './views/whatsapp/WhatsappConversations';
import { CreateTemplates } from './views/whatsapp/CreateTemplates';
import { WhatsappCampaignsSend } from './views/whatsapp/WhatsappCampaignsSend';
import { WhatsappCampaigns } from './views/whatsapp/WhatsappCampaigns';
import { WhatsappCampaignsMessages } from './views/whatsapp/WhatsappCampaignsMessages';
import { WhatsappNumbers } from './views/whatsapp/WhatsappNumbers';
import { WhatsappNumbersSettings } from './views/whatsapp/WhatsappNumbersSettings';

function App() {

  async function saveDevice() {
    getDeviceData().then((response) => {
      var device_id = response.device_id;
      var device_model = response.browser;
      var device_os = response.os;
      localStorage.setItem('device_id', device_id)
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          getTokenFirebase(messaging, {
            vapidKey: env('VAPID_KEY'),
          }).then(token => {
            MarketsmsService.saveDevice({ token_notification_firebase: token, device_id: device_id, device_model: device_model, device_os: device_os, app_mobile_version: env('APP_VERSION') })
          });
        } else if (permission === "denied") {
          MarketsmsService.saveDevice({ device_id: device_id, device_model: device_model, device_os: device_os, app_mobile_version: env('APP_VERSION') })
        } else {
          MarketsmsService.saveDevice({ device_id: device_id, device_model: device_model, device_os: device_os, app_mobile_version: env('APP_VERSION') })
        }
      });
    })
  }

  useEffect(() => {
    saveDevice()
  }, []);

  onMessage(messaging, (payload) => {
    console.log(payload);
    var pathname = window.location.pathname;
    const isLoginPage = (pathname === "/login" || pathname === "/signup");

    console.log("Aqui se debe revisar que tipo de login es");
    if (!isLoginPage) {
      console.log("Aqui se debe enviar la notificación");
      showToastTR(payload?.notification?.title ?? "Algo", 3000, 'info')
    }
  });

  const [remaining, setRemaining] = useState<number>(0);

  const onIdle = () => {
    var pathname = window.location.pathname;
    const isLoginPage = (pathname === "/login" || pathname === "/signup");
    if (!isLoginPage) {
      userLogout();
    } else {
      console.log("No se debe cerrar por que estoy en el inicio de sesión")
    }
  };

  const onActive = () => {
    // console.log("Usuario activo");
  };

  const onAction = () => {
    var pathname = window.location.pathname;
    const isLoginPage = (pathname === "/login" || pathname === "/signup");

    if (!isLoginPage) {
      try {

        // Se deben actualizar los dos tokens
        const token = getToken();
        const baseToken = getBaseToken();
        if (token === baseToken) {
          if (verifyRefreshToken(token)) {
            MarketsmsService.refreshToken().then((response) => {
              saveBaseToken(response.data.access_token)
              saveToken(response.data.access_token)
            });
          }
        } else {
          if (verifyRefreshToken(token)) {
            MarketsmsService.refreshToken().then((response) => {
              saveToken(response.data.access_token)
            });
          }

          if (verifyRefreshToken(baseToken)) {
            MarketsmsService.refreshBaseToken().then((response) => {
              saveBaseToken(response.data.access_token)
            });
          }
        }

      } catch (error) {
        console.log(error);
        userLogout()
      }

    }
  }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: parseInt(env('INACTIVITY_SESSION_TIME')) * 60 * 1000, // 5 minutos
    throttle: 500,
  });

  useEffect(() => {
    if (window.location.pathname !== '/login') {
      const interval = setInterval(() => {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }, 500);

      return () => {
        clearInterval(interval);
      };
    }
  }, [getRemainingTime]);

  return (
    <BrowserRouter>
      <LoadingContainer message={""} />
      <ConfirmContainer />
      <ToastContainer />
      <Routes>
        <Route path='/' element={<Navigate to="/login" />} />
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<SignUp />} />
        <Route path='/dashboard' element={<SideBar><Dashboard /></SideBar>} />
        <Route path='/messages/logs' element={<SideBar><Messages /></SideBar>} />
        <Route path='/messages/send' element={<SideBar><Send /></SideBar>} />
        <Route path='/campaigns/logs' element={<SideBar><Campaigns /></SideBar>} />
        <Route path='/campaign/:id/messages' element={<SideBar><CampaingMessages /></SideBar>} />
        <Route path='/campaigns/send' element={<SideBar><SendCampaign /></SideBar>} />
        <Route path='/whatsapp/integration' element={<SideBar><WhatsappIntegration /></SideBar>} />
        <Route path='/whatsapp/numbers' element={<SideBar><WhatsappNumbers /></SideBar>} />
        <Route path='/whatsapp/numbers/:id/settings' element={<SideBar><WhatsappNumbersSettings /></SideBar>} />
        <Route path='/whatsapp/templates' element={<SideBar><Templates /></SideBar>} />
        <Route path='/whatsapp/template/create' element={<SideBar><CreateTemplates /></SideBar>} />
        <Route path='/whatsapp/conversations' element={<SideBar><WhatsappConversations /></SideBar>} />
        <Route path='/whatsapp/campaigns/send' element={<SideBar><WhatsappCampaignsSend/></SideBar>} />
        <Route path='/whatsapp/campaigns' element={<SideBar><WhatsappCampaigns/></SideBar>} />
        <Route path='/whatsapp/campaign/:id/messages' element={<SideBar><WhatsappCampaignsMessages/></SideBar>} />
        <Route path='/groups' element={<SideBar><Groups /></SideBar>} />
        <Route path='/groups/add' element={<SideBar><CreateGroups /></SideBar>} />
        <Route path='/group/:id' element={<SideBar><GroupDetails /></SideBar>} />
        <Route path='/group/:id/manage' element={<SideBar><GroupManage /></SideBar>} />
        <Route path='/contacts' element={<SideBar><Contacts /></SideBar>} />
        <Route path='/*' element={<SideBar><Page404 /></SideBar>} />
        {/* Apis Developer */}
        <Route path='/developer/documentation' element={<SideBar><Documentation /></SideBar>} />
        <Route path='/developer/apis' element={<SideBar><Apis /></SideBar>} />
        <Route path='/developer/errorcodes' element={<SideBar><ErrorCodes /></SideBar>} />
        {/* Administration */}
        <Route path='/user/administration/profile' element={<SideBar><Profile /></SideBar>} />
        <Route path='/user/administration/accounts' element={<SideBar><Accounts /></SideBar>} />
        <Route path='/user/administration/accounts/plan/:id' element={<SideBar><Accounts action='createAccount' /></SideBar>} />
        <Route path='/user/administration/accounts/payments/:id' element={<SideBar><Payments /></SideBar>} />
        <Route path='/user/administration/transactions' element={<SideBar><Transactions /></SideBar>} />
        {/* Settings */}
        <Route path='/user/settings/changepassword' element={<SideBar><ChangePassword /></SideBar>} />
        <Route path='/user/settings/help' element={<SideBar><Help /></SideBar>} />
        {/* Apis Admin */}
        <Route path='/admin/cpanel/users' element={<SideBar><UsersAdministrator /></SideBar>} />
        <Route path='/admin/cpanel/accounts' element={<SideBar><AccountsAdministrator /></SideBar>} />
        <Route path='/admin/cpanel/providers' element={<SideBar><ProvidersAdministrator /></SideBar>} />
        <Route path='/admin/cpanel/loginasuser' element={<SideBar><LoginAsUser /></SideBar>} />
        <Route path='/admin/cpanel/plans' element={<SideBar><PlansAdministrator /></SideBar>} />
        <Route path='/admin/cpanel/errorcodes' element={<SideBar><ErrorCodesAdminsitrator /></SideBar>} />
        <Route path='/admin/*' element={<SideBar><Page404 /></SideBar>} />
        <Route path='*' element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;