import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCol,
  CRow
} from "@coreui/react-pro";
import { CChart } from "@coreui/react-chartjs";
import { useEffect, useState } from "react";
import * as MarketsmsService from "../../services/MarketsmsService";
import { Traffic, TrafficDataModel } from "../../models/Traffic";
import { getCurrentAccount } from "../../Utils/BaseApp";
import CIcon from "@coreui/icons-react";
import { cilCloudDownload } from "@coreui/icons";

const Dashboard = () => {
  const [successfulCases, setSuccessfulCases] = useState<number[]>();
  const [failedCases, setFailedCases] = useState<number[]>();
  const [labels, setLabels] = useState<number[]>([]);
  const [trafficData, setTrafficData] = useState<TrafficDataModel>();
  const [selectedOption, setSelectedOption] = useState('daily');
  const [range, setRange] = useState("");

  const handleOptionChange = (option: any) => {
    setSelectedOption(option);
  };

  useEffect(() => {

    getCurrentAccount().then((account) => {

      if (account) {
        MarketsmsService.getTraficMessagesByAccount(selectedOption).then((response) => {
          const trafficData = response.data.traffic;
          setTrafficData(response.data)
          generateData(trafficData)
        })
      } else {
        MarketsmsService.getTraficMessagesByUser(selectedOption).then((response) => {
          const trafficData = response.data.traffic;
          setTrafficData(response.data)
          generateData(trafficData)
        })
      }
    })

  }, [selectedOption])

  const generateData = (traffic: Traffic[]) => {
    const successfulCasesData = traffic.map((item: any) => parseInt(item.success));
    setSuccessfulCases(successfulCasesData);
    const failedCasesData = traffic.map((item: any) => parseInt(item.error));
    setFailedCases(failedCasesData);
    const labelsData = traffic.map((item: any) => item.field);
    setLabels(labelsData);
  }

  useEffect(() => {
    const date = new Date();

    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const days = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado',];
    if (selectedOption === "daily") {
      if (labels.length > 1) {
        setRange(labels[0] + " a " + labels.slice(-1) + " del " + days[date.getDay()]);
      } else {
        setRange(days[date.getDay()] + " " + date.getDay() + " de " + months[date.getMonth()]);
      }
    } else if (selectedOption === "weekly") {
      if (labels.length > 1) {
        setRange(labels[0] + " al " + labels.slice(-1) + " de " + months[date.getMonth()]);
      } else {
        setRange(labels[0] + " de " + months[date.getMonth()]);
      }
    } else if (selectedOption === "monthly") {
      if (labels.length > 1) {
        setRange(labels[0] + " al " + labels.slice(-1) + " de " + months[date.getMonth()]);
      } else {
        setRange(labels[0] + " de " + months[date.getMonth()]);
      }
    } else if (selectedOption === "annually") {
      if (labels.length > 1) {
        setRange(labels[0] + " a " + labels.slice(-1) + " del " + date.getFullYear());
      } else {
        setRange(labels[0] + " de " + date.getFullYear());
      }
    }
  }, [labels]);

  const downloadCSV = () => {
    const datasets = [
      {
        label: "Casos Exitosos",
        data: successfulCases ?? [],
      },
      {
        label: "Casos Fallidos",
        data: failedCases ?? [],
      },
    ];

    const header = ["Label", ...labels]; // Incluir los labels como encabezados
    const csvContent =
      "data:text/csv;charset=utf-8," +
      [header.join(","), ...datasets.map((dataset) => `${dataset.label},${dataset.data.join(",")}`)].join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "reporte_" + selectedOption + ".csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
  };

  return (
    <>
      {/* <WidgetsDropdown /> */}
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={6}>
              <h4 id="traffic" className="card-title mb-0">
                Trafico
              </h4>
              <div className="small text-medium-emphasis">
                {range}
                <br />
                <br />
                <strong>Total exitosos:</strong> {trafficData?.total_success}
                <br />
                <strong>Total fallidos:</strong> {trafficData?.total_error}
              </div>
            </CCol>
            <CCol sm={6} className="d-flex justify-content-end">
              <div className="btn-toolbar d-none d-md-block">
                <CButtonGroup role="group" aria-label="Toolbar with buttons">
                  <CButton
                    color="outline-secondary"
                    onClick={() => handleOptionChange('daily')}
                    active={selectedOption === 'daily'}
                  >
                    Dia
                  </CButton>
                  <CButton
                    color="outline-secondary"
                    onClick={() => handleOptionChange('weekly')}
                    active={selectedOption === 'weekly'}
                  >
                    Semana
                  </CButton>
                  <CButton
                    color="outline-secondary"
                    onClick={() => handleOptionChange('monthly')}
                    active={selectedOption === 'monthly'}
                  >
                    Mes
                  </CButton>

                  <CButton
                    color="outline-secondary"
                    onClick={() => handleOptionChange('annually')}
                    active={selectedOption === 'annually'}
                  >
                    Año
                  </CButton>
                </CButtonGroup>
                <CButton color="primary" type="button">
                  <CIcon icon={cilCloudDownload} onClick={downloadCSV} />
                </CButton>
              </div>
            </CCol>
          </CRow>
          <CChart
            type="bar"
            height={95}
            data={{
              datasets: [
                {
                  label: "Casos Exitosos",
                  backgroundColor: "#0b8300",
                  borderColor: "white",
                  borderWidth: 5,
                  borderRadius: 50,
                  barThickness: 25, // Ancho de las barras
                  data: successfulCases ?? [],
                },
                {
                  label: "Casos Fallidos",
                  backgroundColor: "#FF0000",
                  borderColor: "white",
                  borderWidth: 5,
                  barThickness: 25, // Ancho de las barras
                  borderRadius: 50,
                  data: failedCases ?? [],
                },
              ],
              labels: labels ?? [],
            }}
          />
        </CCardBody>
      </CCard>

      {/* <CRow>
        <CCol sm={5}>
          <h4 id="traffic" className="card-title mb-0">
            Trafico por tipo de mensajes
          </h4>
          <div className="small text-medium-emphasis">Hoy</div>
        </CCol>
      </CRow>

      <CRow className="mt-4">
        <WidgetsAvailable sent={2} failed={4} available={9} icon={custom} />
        <WidgetsAvailable sent={2} failed={4} available={9} icon={otp} />
        <WidgetsAvailable sent={2} failed={4} available={9} icon={campaign} />
      </CRow> */}
    </>
  );
};

export default Dashboard;
