// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { Providers } from './redux/providers';

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   // <React.StrictMode>
//   <Providers>
//     <App />
//   </Providers>
//   // <Provider >
//   //   <App />
//   // </Provider>,

//   // </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Providers } from './redux/providers';

// Crear el root de React
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Renderizar la aplicación
root.render(
  <Providers>
    <App />
  </Providers>
);

// Registrar el Service Worker de Firebase para manejar notificaciones en segundo plano
// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker
//     .register('/firebase-messaging-sw.js')
//     .then((registration) => {
//       console.log('Service Worker registrado con éxito:', registration);
//     })
//     .catch((error) => {
//       console.log('Error al registrar el Service Worker:', error);
//     });
// }

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then(registration => {
      console.log('Service Worker registrado con éxito:', registration);

      // Escucha mensajes del Service Worker
      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data && event.data.type === 'playSound') {
          const audio = new Audio('./assets/sounds/notification.pm3');
          audio.play().catch(error => console.error('Error al reproducir el sonido:', error));
        }
      });
    })
    .catch(error => console.error('Error al registrar el Service Worker:', error));
}


// Si deseas medir el rendimiento en tu aplicación
reportWebVitals();
