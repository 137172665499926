import React from "react";
import {
    CCard,
    CCardHeader,
    CCardBody,
    CRow,
    CCol,
    CImage,
    CButton,
    CCardFooter,
    CFormInput,
    CForm,
    CFormSelect,
    CBadge
} from "@coreui/react-pro";

import SwaggerDocs from "../../components/swagger/SwaggerDocs";
import CIcon from "@coreui/icons-react";
import { cilPencil } from "@coreui/icons";

interface ProfileProps { }

export const Profile: React.FC<ProfileProps> = ({ }: ProfileProps) => {

    return (
        <div className="col-12">
            <CCard className="mb-4">
                <CCardHeader>
                    <strong>Perfil</strong>
                </CCardHeader>
                <CCardBody>



                    <CRow className="justify-content-center mb-3">
                        <CCol xs="auto" className="position-relative  p-1" style={{ width: '10%' }}>
                            <CImage src={'https://cdn-icons-png.flaticon.com/512/3135/3135768.png'} className="w-100" />
                            <div className="position-absolute bottom-0 end-0 m-1">
                                <input
                                    type="file"
                                    accept=".jpg,.jpeg,.png"
                                    style={{ display: "none" }}
                                // onChange={(e) => handleFileSelect(e.target.files)}
                                // ref={fileInput as React.RefObject<HTMLInputElement>}
                                />
                                <CButton
                                    color="info"
                                    // variant="outline"
                                    shape="square"
                                    size="sm"
                                // onClick={() => fileInput.current && fileInput.current.click()}
                                >
                                    <CIcon icon={cilPencil} />
                                </CButton>
                            </div>
                        </CCol>
                    </CRow>
                    <CBadge color="success" className="mb-3">Administrador</CBadge>
                    <CForm>
                        <CFormInput
                            type="text"
                            id="exampleFormControlInput1"
                            label="Nombre"
                            placeholder="Ejm.Holman"
                            // text="Must be 8-20 characters long."
                            aria-describedby="exampleFormControlInputHelpInline"
                        />
                        <br />
                        <CFormInput
                            type="text"
                            id="exampleFormControlInput1"
                            label="Apellido"
                            placeholder="Ejm.Pulido"
                            // text="Must be 8-20 characters long."
                            aria-describedby="exampleFormControlInputHelpInline"
                        />
                        <br />
                        <CFormInput
                            type="email"
                            id="exampleFormControlInput1"
                            label="Correo electrónico"
                            placeholder="nombre@ejemplo.com"
                            // text="Must be 8-20 characters long."
                            aria-describedby="exampleFormControlInputHelpInline"
                        />
                        <br />
                        <CFormSelect
                            aria-label="Default select example"
                            label="Tipo de identificación"
                            options={[
                                { label: 'Selecciona el tipo de identificación', value: '' },
                                { label: 'NIT', value: '1' },
                                { label: 'C.C', value: '2' },
                                { label: 'T.I', value: '2' },
                            ]}
                        />
                        <br />
                        <CFormInput
                            type="number"
                            id="exampleFormControlInput1"
                            label="Número de identificación"
                            placeholder="1234567891"
                            // text="Must be 8-20 characters long."
                            aria-describedby="exampleFormControlInputHelpInline"
                        />
                        <br />
                        <CFormInput
                            type="number"
                            id="exampleFormControlInput1"
                            label="Número de teléfono"
                            placeholder="3012345678"
                            // text="Must be 8-20 characters long."
                            aria-describedby="exampleFormControlInputHelpInline"
                        />
                    </CForm>

                </CCardBody>
                <CCardFooter>
                    <CButton>Actualizar Perfil</CButton>
                </CCardFooter>
            </CCard>
        </div>
    );
};
