import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardHeader,
  CCardBody,
  CSmartTable,
  CButton,
  CTabContent,
  CCol,
  CFormInput,
  CForm,
  CDateRangePicker,
  CRow,
  CFormCheck,
  CFormLabel,
  CHeaderDivider,
} from "@coreui/react-pro";
import * as MarketsmsService from "../../services/MarketsmsService";
import {
  customMessage,
  showToastTR,
} from "../../Utils/BaseApp";
import { GroupsItems } from "../../models/Groups";
import { useNavigate } from "react-router-dom";
import { ContactsItems } from "../../models/Contacts";

interface CreateGroupsProps { }

export const CreateGroups: React.FC<
  CreateGroupsProps
> = ({ }: CreateGroupsProps) => {

  const [birthday, setBirthday] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [contacts, setContacts] = useState<ContactsItems[]>([]);
  const [filteredContacts, setFilteredContacts] = useState<any[]>([]);
  const [groups, setGroups] = useState<GroupsItems[]>([]);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const columns = [
    {
      key: "id",
      _style: { width: "5%" },
    },
    {
      key: "number",
      label: "Número",
      _style: { width: "15%" },
    },
    {
      key: "first_name",
      label: "Nombre",
      _style: { width: "20%" },
    },
    {
      key: "last_name",
      label: "Apellido",
      _style: { width: "20%" },
    },
    {
      key: "birthday",
      label: "Cumpleaños",
      _style: { width: "15%" },
    },
    {
      key: "created_at",
      label: "Fecha de creación",
      _style: { width: "25%" },
    },
  ];
  // useEffect(() => {
  //   getGroups();
  // }, []); 

  const formatDate = (date: Date | null) => {
    if (!date) return "";

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    getContactsFilter();
  }, [])

  const getContactsFilter = async () => {

    if (startDate || birthday) {
      var filters = await getFilters("params");
      console.log(filters);
      if (filters) {

        MarketsmsService.getContactsFilter(filters).then((response) => {
          if (response) {
            if (response.status === 200) {
              setContacts(response.data.items);
            } else {
              console.log("Response");
              setContacts([]);
            }
          }
        });
      }
    } else {
      MarketsmsService.getContactsByAccount().then((response) => {
        console.log(response);
        if (response?.status === 200) {
          setContacts(response.data.items);
        }
      })
      console.log("Buscar todos los contactos");
    }
  };

  const getFilters = (type: string): any => {
    var filters = {};
    if (type === "params") {
      console.log("Es params");
      if (birthday) {
        filters = "birthday=true";
      } else if (startDate) {
        console.log("Entra a start dte");
        filters = "start_date=" + formatDate(startDate);
        if (endDate) {
          filters += "&end_date=" + formatDate(endDate);
        }
      }
    } else {
      filters = {};
      console.log("birtday Body");
      console.log(birthday);
      if (birthday) {
        filters = { birthday: true };
      } else if (startDate) {
        filters = { start_date: formatDate(startDate) };
        if (endDate) {
          filters = {
            start_date: formatDate(startDate),
            end_date: formatDate(endDate),
          };
        }
      } else {
        filters = { all: true };
      }
    }
    return filters;
  };

  // const handleSearch = async () => {
  //   getContactsFilter();
  // };

  const handleSubmit = async () => {

    if (filteredContacts.length) {
      console.log("Se debe crear el grupo enviando los contactos por parametro");
      var postContacts = { contacts: filteredContacts }
      console.log(postContacts);


      await new Promise((resolve) => {
        setFormData((prevState) => {
          const updatedFormData = { ...prevState, ...postContacts };
          resolve(updatedFormData);
          return updatedFormData;
        });
      }).then((form_data: any) => {
        console.log(form_data);
        console.log(formData);
        MarketsmsService.createGroup(form_data).then((response) => {
          if (response) {
            if (response.status === 200) {
              showToastTR(customMessage(response.code_app), 200, "success");
              navigate('/group/' + response.data?.id);
            } else {
              showToastTR(customMessage(response.code_app), 2000, "error");
            }
          }
        });
      });
    } else {
      console.log("Enrtra aqui");
      var filters = getFilters("body");
      await new Promise((resolve) => {
        setFormData((prevState) => {
          const updatedFormData = { ...prevState, ...filters };
          setFormData(updatedFormData)
          resolve(updatedFormData);
          return updatedFormData;
        });
      }).then((form_data: any) => {
        if (filters) {
          MarketsmsService.createGroupByFilter(form_data).then((response) => {
            if (response) {
              if (response.status === 200) {
                showToastTR(customMessage(response.code_app), 200, "success");
                navigate('/group/' + response.data?.id);
              } else {
                showToastTR(customMessage(response.code_app), 2000, "error");
              }
            }
          })

        }
      });
    }
  };

  useEffect(() => {
    if (birthday) {
      setStartDate(null);
      setEndDate(null);
      getContactsFilter()
    }
  }, [birthday]);

  useEffect(() => {
    if (startDate) {
      setBirthday(false);
      if (endDate) {
        getContactsFilter()
      }
    }
  }, [startDate]);


  useEffect(() => {
    if (startDate) {
      setBirthday(false);
      getContactsFilter()
    }
  }, [endDate]);

  const dateRangePicker = document.getElementById("date-range-picker");

  dateRangePicker?.addEventListener(
    "startDateChange.coreui.date-range-picker",
    (event: Event & { date?: Date }) => {
      const date = event.date;
      if (date) {
        setStartDate(date);
      }
    }
  );

  dateRangePicker?.addEventListener(
    "endDateChange.coreui.date-range-picker",
    (event: Event & { date?: Date }) => {
      const date = event.date;
      if (date) {
        setEndDate(date);
      }
    }
  );


  const customRanges = {
    'Hoy  ': [new Date(), new Date()],
    'Ayer': [
      new Date(new Date().setDate(new Date().getDate() - 1)),
      new Date(new Date().setDate(new Date().getDate() - 1)),
    ],
    'Últimos 7 Dias': [new Date(new Date().setDate(new Date().getDate() - 6)), new Date(new Date())],
    'Últimos 30 Dias': [new Date(new Date().setDate(new Date().getDate() - 29)), new Date(new Date())],
    'Este Mes': [
      new Date(new Date().setDate(1)),
      new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
    ],
    'Último Mes': [
      new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
      new Date(new Date().getFullYear(), new Date().getMonth(), 0),
    ],
  }

  return (
    <div className="col-12">
      <CCard className="mb-4">
        <CCardHeader>
          <strong>Crear Grupo</strong>
        </CCardHeader>
        <CCardBody>
          {/* Aqui van los inputs para nombre y descripción de los grupos */}
          <CForm
            className="row g-3 needs-validation"
            noValidate
          // validated={validated}
          // onSubmit={handleSubmit}
          >
            <CCol md={6}>
              <CFormInput
                value={formData.name}
                type="text"
                name="name"
                minLength={2}
                placeholder="Nombre del grupo"
                feedbackValid="Luce bien!"
                feedbackInvalid="Por favor escriba un nombre valido para el grupo."
                id="validationCustom01"
                label="Nombre"
                onChange={handleChange}
              />
            </CCol>
            <CCol md={6}>
              <CFormInput
                value={formData.description}
                type="text"
                name="description"
                minLength={2}
                placeholder="Descripción del grupo"
                feedbackValid="Luce bien!"
                feedbackInvalid="Por favor escriba una descripción valida para el grupo."
                id="validationCustom01"
                label="Descripción"
                onChange={handleChange}
              />
            </CCol>{" "}
            <CCol md={6}>
              {/* <CDateRangePicker
                // startDate="2022/08/03"
                // endDate="2022/08/17"
                format="yyyy-MM-dd"
                label="Rango de fecha ss"
                locale="en-US"
                onEndDateChange={(date) => { setEndDate(date) }}
                onStartDateChange={(date) => { setStartDate(date) }}
              // maxDate={new Date(2024, 1, 1)}
              /> */}
              <CDateRangePicker
                format="yyyy-MM-dd"
                label="Rango de fecha"
                locale="en-US"
                ranges={customRanges}
                onEndDateChange={(date) => { setEndDate(date) }}
                onStartDateChange={(date) => { setStartDate(date) }}
              />
            </CCol>
            <CCol md={6}>
              <CFormLabel>Buscar por cumpleaños</CFormLabel>
              <CFormCheck
                id="checkSaveSource"
                type="checkbox"
                className="mt-2"
                checked={birthday}
                onChange={() => setBirthday(!birthday)}
                label="Personas que cumplen años hoy"
              />
            </CCol>
            <CHeaderDivider />
            {/* <CRow xs={{ cols: "auto" }} className="mt-3">
              <CButton color="primary" className="ms-2" onClick={handleSearch}>
                {startDate || birthday ? 'Buscar' : 'Todos los contactos'}
              </CButton>
            </CRow> */}
            <CTabContent className="rounded-bottom mt-4">
              <p className="text-success">* La selección de contactos es opcional. Si no seleccionas ninguno, se agregarán todos.</p>
              <CSmartTable
                activePage={2}
                cleaner
                clickableRows
                columns={columns}
                selectAll={false}
                columnFilter
                columnSorter
                items={contacts}

                itemsPerPageSelect
                itemsPerPage={10}
                pagination
                itemsNumber={232}
                onSelectedItemsChange={(items) => {
                  const ids = items.map((item) => item.id);
                  setFilteredContacts(ids)
                }}
                scopedColumns={{}}
                selectable
                // sorterValue={{ column: "id", state: "asc" }}
                tableFilter
                tableProps={{
                  className: "add-this-class",
                  responsive: true,
                  striped: true,
                  hover: true
                }}
                tableBodyProps={{
                  className: "align-middle",
                }}
              />
            </CTabContent>
            <CRow xs={{ cols: "auto" }}>
              <CButton color="primary" className="ms-2" onClick={handleSubmit}>
                Crear
              </CButton>
            </CRow>
          </CForm>
        </CCardBody>
      </CCard>
    </div>
  );
};
