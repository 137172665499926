import React, { useEffect, useRef, useState } from "react";
import {
    CCard,
    CCardHeader,
    CCardBody,
    CButton,
    CRow,
    CCol,
    CCardTitle,
    CCardText,
    CFormTextarea,
    CCardFooter,
    CFormInput,
    CFormLabel,
    CFormSwitch,
} from "@coreui/react-pro";

import * as MarketsmsService from "../../services/MarketsmsService";

import { useNavigate, useParams } from "react-router-dom";
import { BIcon } from "../../components/icons/BIcon";
import { customMessage, getDefaultPhoneNumber, showToastTC, showToastTR } from "../../Utils/BaseApp";
import { WhatsappPhoneNumbersAutomationsCommandsModel, WhatsappPhoneNumbersAutomationsPromptsModel, WhatsappPhoneNumbersAutomationsWelcomeMessageModel } from "../../models/WhatsappNumbers";
import { cibOpenCollective } from "@coreui/icons";

interface WhatsappNumbersSettingsProps { }

export const WhatsappNumbersSettings: React.FC<WhatsappNumbersSettingsProps> = ({ }: WhatsappNumbersSettingsProps) => {
    const { id } = useParams();
    const [commands, setCommands] = useState<WhatsappPhoneNumbersAutomationsCommandsModel[]>([]);
    const [topics, setTopics] = useState<WhatsappPhoneNumbersAutomationsPromptsModel[]>([]);
    const [welcomeMessageText, setWelcomeMessageText] = useState("");
    const [welcomeMessage, setWelcomeMessage] = useState<WhatsappPhoneNumbersAutomationsWelcomeMessageModel>({message:"", enable:false});
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [phoneNumber, setPhoneNumber] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        const phone_number_id = getDefaultPhoneNumber();
        if (phone_number_id != null) {
            setPhoneNumber(phone_number_id);
            MarketsmsService.getPhoneAutomations(phone_number_id).then((response) => {
                if (response.status == 200) {
                    setCommands(response.data.commands);
                    setTopics(response.data.prompts);
                    setWelcomeMessage(response.data.welcome_message)
                    setWelcomeMessageText(response.data.welcome_message?.message ?? "")
                }
            })
        } else {
            showToastTC("No contiene un numero de telefono por defecto, seleccionalo por favor", 5000, "error");
            setTimeout(() => {
                navigate("/whatsapp/numbers");
            }, 5000);
        }
    }, [])

    const formatText = (text: string) => {
        let formattedText = text;
        formattedText = formattedText.replace(/\*([^\*]+)\*/g, '<strong>$1</strong>');
        formattedText = formattedText.replace(/_([^_]+)_/g, '<em>$1</em>');
        formattedText = formattedText.replace(/~([^~]+)~/g, '<del>$1</del>');
        formattedText = formattedText.replace(/```([^`]+)```/g, '<code style="color:inherit">$1</code>');
        formattedText = formattedText.replace(/\n/g, '<br/>'); // Convertir saltos de línea a <br/>
        return <p className="m-0 fs-8 mt-2 text-gray" dangerouslySetInnerHTML={{ __html: formattedText }} />;
    };

    const insertTextAtCursor = (text: string) => {
        if (!textAreaRef.current) return;
        console.log("Aqui");
        const { selectionStart, selectionEnd } = textAreaRef.current;
        if (selectionStart !== null && selectionEnd !== null) {
            console.log("Aqui 68");
            const newText =
                welcomeMessageText.substring(0, selectionStart) +
                text +
                welcomeMessageText.substring(selectionEnd);
            setWelcomeMessageText(newText);
            console.log("Aqui 74");

            console.log(selectionStart);
            console.log(text.length);
            console.log(text.length / 2);

            var newPosition = newText.length;
            // if (variableNumber == 0) {
            newPosition = selectionStart + (text.length / 2);
            // }

            setTimeout(() => { // Esperar a que se actualice el DOM
                textAreaRef?.current?.setSelectionRange(newPosition, newPosition);
                textAreaRef?.current?.focus();
            }, 0);
        }
    }





    const addCommandInput = () => {
        // TODO::Dejarlo inicialmente en 10 máximo, eso se debe traer por el plan actual del usuario
        if (commands.length > 9) {
            showToastTR("Solo se permiten 4 comandos", 3000, "info");
        } else {
            if (commands[commands.length - 1].command != "") {
                const newCommand: WhatsappPhoneNumbersAutomationsCommandsModel = {
                    command: "",
                    description: "",
                    message: ""
                };
                setCommands([...commands, newCommand]);
            }
        }
    }

    const handleChangeCommand = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const { name, value } = event.target;
        if (commands[index]) {
            const newCommands = [...commands];
            newCommands[index] = {
                ...newCommands[index],
                [name]: value,
            };
            setCommands(newCommands);
        }
    };


    const addTopicInput = () => {
        if (topics.length > 3) {
            showToastTR("Solo se permiten 4 temas para romper el hielo", 3000, "info");
        } else {
            if (topics[topics.length - 1].topic != "") {
                const newTopic: WhatsappPhoneNumbersAutomationsPromptsModel = {
                    topic: "",
                    message: ""
                };
                setTopics([...topics, newTopic]);
            }
        }
    }

    const handleChangeTopic = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const { name, value } = event.target;
        if (topics[index]) {
            const newTopics = [...topics];
            newTopics[index] = {
                ...newTopics[index],
                [name]: value,
            };
            setTopics(newTopics);
        }
    };


    const handleSwitchChange = () => {
        console.log("Entra aqui 159");
        var data = {};
        if (welcomeMessage != undefined) {

            var newWelcomeMessage = { ...welcomeMessage };
            newWelcomeMessage = {
                ...newWelcomeMessage,
                enable: !welcomeMessage.enable,
            };
            console.log(newWelcomeMessage);
            setWelcomeMessage(newWelcomeMessage);
            data = { enable_welcome_message: newWelcomeMessage.enable };
            saveConversationalComponents(data);
        } else {

            data = { enable_welcome_message: true };
            // setWelcomeMessage(data);
            saveConversationalComponents(data);

        }

    }

    useEffect(() => {
        if (welcomeMessage != undefined) {
            var newWelcomeMessage = { ...welcomeMessage };
            newWelcomeMessage = {
                ...newWelcomeMessage,
                message: welcomeMessageText,
            };
            setWelcomeMessage(newWelcomeMessage);
        }
    }, [welcomeMessageText]);

    const handleSubmit = () => {

        var dataCommands: any = [];

        commands.forEach((element, index) => {
            if (element.command != "") {
                var command = {
                    command: element.command,
                    description: element.description,
                    message: element.message
                };
                dataCommands.push(command);
            }
        });

        var dataTopics: any = [];
        topics.forEach((element, index) => {
            if (element.topic != "") {
                var topic = {
                    topic: element.topic,
                    message: element.message
                };
                dataTopics.push(topic);
            }
        });


        var data = {
            enable_welcome_message: welcomeMessage?.enable,
            welcome_message: welcomeMessage?.message,
            prompts: dataTopics,
            commands: dataCommands
        };
        console.log(welcomeMessage);
        console.log(commands);
        console.log(topics);
        console.log(data);

        saveConversationalComponents(data);
    }

    const saveConversationalComponents = (body: object) => {
        console.log("Entra hasta aqui");
        MarketsmsService.saveConversationalComponents(body, phoneNumber).then((response) => {
            if (response.status === 200) {
                showToastTR(customMessage(response.code_app), 3000, "success");
            } else {
                showToastTR(customMessage(response.code_app), 3000, "error");
            }
        });
    }

    return (
        <div className="col-12">
            <CCard className="mb-4">
                <CCardHeader>
                    <strong>Configuraciones para {id}</strong>
                </CCardHeader>
                <CCardBody>
                    <CRow className="align-items-stretch">

                        <CCol xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="d-flex">
                            <CCard className="mb-3 shadow-lg flex-fill">
                                <CCardBody>
                                    <CCardTitle className="mb-3 fs-6 fw-bold text-info">Comandos</CCardTitle>
                                    <CCardText className="fs-7">
                                        Los comandos son instrucciones o palabras clave que los clientes pueden enviar para recibir respuestas automáticas o realizar acciones específicas sin necesidad de interactuar directamente con un representante.
                                    </CCardText>
                                    <strong>Ejemplo</strong>
                                    <p>{formatText(`/productos: Obtén información sobre nuestros productos y servicios.
                                    /pedido: Consulta el estado de tu pedido.
                                    /horarios: Conoce nuestros horarios de atención y ubicaciones de las tiendas.
                                    /ayuda: Para cualquier otra pregunta o asistencia adicional.`)}</p>

                                    <CRow className="bg-white align-items-center align-items-stretch"> {/* gx-3 para mayor espacio horizontal */}
                                        {commands?.map((command, index) => (
                                            <CCol
                                                key={index}
                                                className="p-1" // Agrega padding para mejor visibilidad y mantiene el fondo gris
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                                xxl={12}
                                            >
                                                <div className="bg-gray  p-1">
                                                    <CRow>
                                                        <CCol md={4} className="pe-0">
                                                            <CFormLabel className="p-0 mb-0 fw-semibold fs-7">Texto del comando</CFormLabel>
                                                            <CFormInput name="command" placeholder={command.command} value={command.command} onChange={(e) => { handleChangeCommand(e, index) }} aria-label="webhook" aria-describedby="basic-addon1" />
                                                        </CCol>
                                                        <CCol md={8}>
                                                            <CFormLabel className="p-0 mb-0 fw-semibold fs-7">Descripción del comando</CFormLabel>
                                                            <CFormInput name="description" placeholder={command.description} value={command.description} onChange={(e) => { handleChangeCommand(e, index) }} aria-label="webhook" aria-describedby="basic-addon1" />
                                                        </CCol>
                                                    </CRow>

                                                    <CFormLabel className="p-0 mb-0 mt-2 fw-semibold fs-7">Mensaje para responder al comando</CFormLabel>
                                                    <CFormTextarea name="message" placeholder={command.message} value={command.message} onChange={(e) => { handleChangeCommand(e, index) }} aria-label="webhook" aria-describedby="basic-addon1" />
                                                </div>
                                            </CCol>
                                        ))}
                                    </CRow>
                                    {/* <CButton>Agregar comando</CButton> */}

                                    {/* <CRow className="mt-2">
                                        <CCol>
                                            <CButton>Agregar comando</CButton>
                                        </CCol>
                                        <CCol className="text-end">
                                            <CButton className="bg-info">Guardar</CButton>
                                        </CCol>
                                    </CRow> */}

                                    <CRow className="mt-2">
                                        <CCol>
                                            <CButton onClick={addCommandInput}>Agregar comando</CButton>
                                        </CCol>
                                    </CRow>

                                </CCardBody>
                                <CCardFooter>
                                    <CCol className="text-end">
                                        <CButton className="bg-info" onClickCapture={handleSubmit}>Guardar</CButton>
                                    </CCol>
                                </CCardFooter>
                            </CCard>
                        </CCol>
                        <CCol xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="d-flex">
                            {/* <CCard className="mb-3 shadow-lg flex-fill">
                                <CCardBody>
                                    <CCardTitle className="mb-3 fs-6 fw-bold text-info">Para romper el hielo</CCardTitle>
                                    <CCardText className="fs-7">
                                        Estas son preguntas habituales que las personas pueden hacerte fácilmente.
                                        Estos mensajes son palabras o frases clave que puedes usar para captar la atención de los clientes y dirigirlos hacia temas o consultas frecuentes. Funcionan como puntos de partida para que los usuarios encuentren respuestas o inicien una conversación de manera sencilla y directa.
                                    </CCardText>
                                    <strong>Ejemplo</strong>
                                    <p>{formatText(`¿Puedo saber el precio de un producto?
                                        ¿Cómo puedo realizar una compra?
                                        ¿Que polita de cambio o devoluciones manejan?`)}</p>
                                </CCardBody>
                            </CCard> */}


                            <CCard className="mb-3 shadow-lg flex-fill">
                                <CCardBody>
                                    <CCardTitle className="mb-3 fs-6 fw-bold text-info">Temas para romper el hielo</CCardTitle>
                                    <CCardText className="fs-7">
                                        Estas son preguntas habituales que las personas pueden hacerte fácilmente.
                                        Estos mensajes son palabras o frases clave que puedes usar para captar la atención de los clientes y dirigirlos hacia temas o consultas frecuentes. Funcionan como puntos de partida para que los usuarios encuentren respuestas o inicien una conversación de manera sencilla y directa.
                                    </CCardText>
                                    <strong>Ejemplo</strong>
                                    <p>{formatText(`¿Puedo saber el precio de un producto?
                                        ¿Cómo puedo realizar una compra?
                                        ¿Que politica de cambio o devoluciones manejan?`)}</p>

                                    <CRow className="bg-white align-items-center align-items-stretch"> {/* gx-3 para mayor espacio horizontal */}
                                        {topics?.map((topic, index) => (
                                            <CCol key={index} className="p-1" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <div className="bg-gray  p-1">
                                                    <CFormLabel className="p-0 mb-0 fw-semibold fs-7">Tema para roper el hielo {index + 1}</CFormLabel>
                                                    <CFormInput placeholder={topic.topic} value={topic.topic} name="topic" onChange={(e) => { handleChangeTopic(e, index) }} aria-label="webhook" aria-describedby="basic-addon1" />
                                                    {/* <CFormLabel className="p-0 mb-0 mt-2 fw-semibold fs-7">Descripción del comando</CFormLabel>
                                                    <CFormTextarea placeholder="Descripción de comando" aria-label="webhook" aria-describedby="basic-addon1" /> */}
                                                    <CFormLabel className="p-0 mb-0 mt-2 fw-semibold fs-7">Mensaje para responder al tema</CFormLabel>
                                                    <CFormTextarea placeholder={topic.message} value={topic.message} name="message" onChange={(e) => { handleChangeTopic(e, index) }} aria-label="webhook" aria-describedby="basic-addon1" />
                                                </div>
                                            </CCol>
                                        ))}
                                    </CRow>
                                    <CRow className="mt-2">
                                        <CCol>
                                            <CButton onClick={addTopicInput}>Agregar tema</CButton>
                                        </CCol>
                                    </CRow>
                                </CCardBody>
                                <CCardFooter>
                                    <CCol className="text-end">
                                        <CButton className="bg-info" onClick={handleSubmit}>Guardar</CButton>
                                    </CCol>
                                </CCardFooter>
                            </CCard>
                        </CCol>

                        <CCol xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="d-flex">
                            <CCard className="mb-3 shadow-lg flex-fill">
                                <CCardBody className="d-flex flex-column">
                                    <CCardTitle className="mb-3 fs-6 fw-bold text-info">
                                        <CRow>
                                            <CCol>Mensaje de bienvenida</CCol>
                                            {/* <CCol className={`text-end text-${welcomeMessage?.enable ? "success" : "danger"}`}> <div className="d-flex"> {welcomeMessage?.enable ? "Activo" : "Inactivo"} <CFormSwitch className="text-end ms-2 " id="formSwitchCheckDefault" /></div></CCol> */}
                                            <CCol className="d-flex justify-content-end align-items-center">
                                                <div className={`text-${welcomeMessage?.enable ? "success" : "danger"} d-flex align-items-center`}>
                                                    <span className="me-2">{welcomeMessage?.enable ? "Activo" : "Inactivo"}</span>
                                                    <CFormSwitch
                                                        className="cursor-pointer m-0"
                                                        id="formSwitchCheckDefault"
                                                        checked={welcomeMessage?.enable}
                                                        onChange={handleSwitchChange}
                                                    />
                                                </div>
                                            </CCol>
                                        </CRow>
                                    </CCardTitle>
                                    <p className="fs-7">Este es el primer mensaje que envías a los clientes cuando inician una conversación con tu negocio. Su objetivo es darles una cálida bienvenida y guiarlos sobre cómo interactuar con tu empresa.</p>
                                    {welcomeMessageText &&
                                        <>
                                            <strong>Previsualización</strong>
                                            <p>{formatText(welcomeMessageText)}</p>
                                        </>
                                    }
                                    <CFormTextarea value={welcomeMessageText} onChange={(e) => setWelcomeMessageText(e.target.value)} ref={textAreaRef} rows={8} placeholder={`¡Hola y bienvenido a MarketSms!👋\n\nEstamos encantados de tenerte aquí. Para que puedas obtener la información que necesitas de manera rápida y sencilla, hemos establecido algunos comandos útiles. Solo tienes que escribir el número o palabra clave correspondiente a tu consulta.\nSi necesitas ayuda adicional, simplemente escríbenos lo que necesites y estaremos encantados de asistirte.`} />
                                    <CRow className="text-end my-2">
                                        <CCol sm={7}></CCol>
                                        <CCol sm={5} >
                                            <CRow className="pe-2 text-end align-items-end">
                                                <CCol sm={1}></CCol>
                                                {/* <CCol className="p-0 m-0" title="Inserta un emoji"><CButton color="light" className="shadow-none px-0 mx-1" style={{ minWidth: "35px" }}><strong><BIcon icon="emoji-smile" /></strong></CButton></CCol> */}
                                                <CCol className="p-0 m-0" title="Negrita"><CButton onClick={() => insertTextAtCursor("**")} color="light" className="shadow-none px-0 mx-1" style={{ minWidth: "35px" }}><strong>B</strong></CButton></CCol>
                                                <CCol className="p-0 m-0" title="Cursiva"><CButton onClick={() => insertTextAtCursor("__")} color="light" className="shadow-none px-0 mx-1" style={{ minWidth: "35px" }}><em>I</em></CButton></CCol>
                                                <CCol className="p-0 m-0" title="Subrayado"><CButton onClick={() => insertTextAtCursor("~~")} color="light" className="shadow-none px-0 mx-1" style={{ minWidth: "35px" }}><del>S</del></CButton></CCol>
                                                <CCol className="p-0 m-0" title="Monospace"><CButton onClick={() => insertTextAtCursor("``````")} color="light" className="shadow-none px-0 mx-1" style={{ minWidth: "35px" }}><strong><BIcon icon="code-slash" /></strong></CButton></CCol>
                                            </CRow>
                                        </CCol>
                                    </CRow>
                                </CCardBody>
                                <CCardFooter className="text-end">
                                    <CButton className="bg-info" onClick={handleSubmit}>Guardar</CButton>
                                </CCardFooter>
                            </CCard>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
        </div>
    );
};