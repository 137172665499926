import React, { useEffect, useState } from "react";
import {
  CSmartTable,
  CCard,
  CCardHeader,
  CCardBody,
  CTabContent,
  CTabPane,
  CBadge,
  CButton,
} from "@coreui/react-pro";

import * as MarketsmsService from "../../services/MarketsmsService";

import { getBadge, showToastTC } from "../../Utils/BaseApp";
import { PlansItems } from "../../models/Plans";
import CIcon from "@coreui/icons-react";
import { cilCopy, cilPencil, cilPlus, cilPowerStandby } from "@coreui/icons";
import { BIcon } from "../../components/icons/BIcon";

interface PlansProps { }

export const PlansAdministrator: React.FC<PlansProps> = ({ }: PlansProps) => {
  const [plans, setPlans] = useState<PlansItems[] | null>(null);

  const getPlans = async () => {

    var response = await MarketsmsService.getPlans();

    if (response) {
      if (response.status === 200) {
        setPlans(response.data.items);
      }
    }
  };

  useEffect(() => {
    getPlans();
  }, []);

  const columns = [
    {
      key: "id",
      label: "Id",
      _style: { width: "5%" },
    },
    {
      key: "name",
      label: "Nombre",
      _style: { width: "20%" },
    },
    {
      key: "description",
      label: "descripción",
      _style: { width: "30%" },
    },
    {
      key: "status_name",
      label: "Estado",
      _style: { width: "10%" },
    },
    {
      key: "color",
      label: "Color",
      _style: { width: "10%" },
    },
    {
      key: "value",
      label: "Valor",
      _style: { width: "10%" },
    },
    {
      key: "operations",
      label: "Acción",
      _style: { width: "10%" },
    }
  ];

  return (
    <div className="col-12">
      <CCard className="mb-4">
        <CCardHeader>
          <strong>Planes</strong>
        </CCardHeader>
        <CCardBody>
          <CButton 
          // onClick={() => setVisible(!visible)}
          >
            <CIcon icon={cilPlus} /> Agregar
          </CButton>
          <CTabContent className="rounded-bottom">
            <CTabPane
              className="p-3 active preview show"
              role="tabpanel"
              id="preview-578"
            >
              <CSmartTable
                activePage={2}
                cleaner
                clickableRows
                columns={columns}
                columnFilter
                columnSorter
                loading={plans === null ? true : false}
                items={plans ?? []}
                itemsPerPageSelect
                itemsPerPage={10}
                pagination
                scopedColumns={{
                  status_name: (plan: PlansItems) => (
                    <td>
                      <CBadge color={getBadge(plan.status_id)}
                        title={plan.status_id == 1 ? 'Deshabilitar' : 'Habilitar'}
                      >
                        {plan.status_name}
                      </CBadge>
                    </td>
                  ),
                  color: (plan: PlansItems) => (
                    <td>
                      <CBadge style={{ backgroundColor: plan.color }}>
                        {plan.color}
                        <CIcon icon={cilCopy} className="ms-2" onClick={() => {
                          navigator.clipboard.writeText(plan.color);
                          showToastTC("Copiado!", 100, "info");
                        }} />
                      </CBadge>
                    </td>
                  ),
                  operations: (plan: PlansItems) => {
                    return (
                      <td className="py-2 text-center">

                        <CButton
                          color="info"
                          variant="outline"
                          shape="square"
                          className="m-1"
                          size="sm"
                        >
                          <CIcon icon={cilPencil} />
                        </CButton>
                      </td>
                    );
                  },


                  // operations: (plan: PlansItems) => {
                  //   return (
                  //     <td className="py-2 text-center">
                  //       <CButton
                  //         color={plan.status_id == 1 ? 'danger' : 'success'}
                  //         title={plan.status_id == 1 ? 'Deshabilitar' : 'Habilitar'}
                  //         variant="outline"
                  //         shape="rounded-pill"
                  //         className="m-1"
                  //         size="sm"
                  //         onClick={() => {
                  //           // showDetails(account);
                  //         }}
                  //       >
                  //         <CIcon icon={cilPowerStandby} />
                  //       </CButton>
                  //     </td>
                  //   );
                  // },
                }}
                sorterValue={{ column: "status", state: "asc" }}
                tableFilter
                tableProps={{
                  className: "add-this-class",
                  responsive: true,
                  striped: true,
                  hover: true,
                }}
                tableBodyProps={{
                  className: "align-middle",
                }}
              />
            </CTabPane>
          </CTabContent>
        </CCardBody>
      </CCard>
    </div>
  );
};