import React, { useEffect, useRef, useState } from "react";
import {
    CCard,
    CCardHeader,
    CCardBody,
    CButton,
    CCol,
    CFormInput,
    CImage,
    CRow,
    CFormSelect,
    CFormCheck,
    CFormTextarea,
    CDropdown,
    CDropdownToggle,
    CDropdownMenu,
    CDropdownItem,
    CDropdownDivider,
    CCardFooter,
    CInputGroup,
    CInputGroupText,
} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import { cilPlus } from "@coreui/icons";
import { BIcon } from "../../components/icons/BIcon";
import backgroundChat from "../../assets/whatsapp/background_chat.png";
import image from "../../assets/whatsapp/media/image.png";
import video from "../../assets/whatsapp/media/video.png";
import document from "../../assets/whatsapp/media/document.png";
import location from "../../assets/whatsapp/media/location.png";

import { customMessage, getCurrentAccount, getDefaultPhoneNumber, showToastTC, showToastTR, validateUrl } from "../../Utils/BaseApp";
import { AccountsItems } from "../../models/Accounts";
import CustomVideo from "../../components/video/CustomVideo";
import { WhatsappTemplatesButtonsModel, WhatsappTemplatesCreateModel, WhatsappTemplatesModel } from "../../models/WhatsappTemplates";
import { Link, useNavigate } from "react-router-dom";
import * as MarketsmsService from "../../services/MarketsmsService";
import { ppid } from "process";



interface TemplatesProps { }

export const CreateTemplates: React.FC<TemplatesProps> = ({ }: TemplatesProps) => {
    const [cursorPosition, setCursorPosition] = useState<number>(0);
    const [footerContent, setFooterContent] = useState("");
    // const [headerText, setHeaderText] = useState("");
    const [headerType, setHeaderType] = useState(0);
    const [accountDefault, setAccountDefault] = useState<AccountsItems | null>(null);
    const [category, setCategory] = useState(0);
    const [language, setLanguage] = useState(0);
    const [allowCategoryChange, setAllowCategoryChange] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getCurrentAccount().then((response) => {
            if (response) {
                setAccountDefault(response)
            }
        })
    }, [])

    function formatTime(date: any) {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const amOrPm = hours >= 12 ? 'pm' : 'am';
        const formattedHours = hours % 12 || 12; // Convertir a formato de 12 horas
        return `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${amOrPm}`;
    }

    const handleTypeChange = (e: any) => {
        setHeaderText("");
        setPreviewUrl(null);
        const selectedType = parseInt(e.target.value);
        setHeaderType(selectedType);
    };

    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [nameMedia, setNameMedia] = useState<string | null>(null);
    const fileInputMediaRef = useRef<HTMLInputElement>(null);

    const handleFileChange = (files: FileList | null) => {
        if (files && files.length > 0) {
            const file = files[0];

            // Verificar el tipo de archivo y tamaño máximo permitido
            let maxSize = 0;
            let validTypes = '';

            if (file.type.startsWith('image/')) {
                maxSize = 2 * 1024 * 1024; // 2MB para imágenes
                validTypes = 'image/*';
            } else if (file.type.startsWith('video/')) {
                maxSize = 16 * 1024 * 1024; // 16MB para videos
                validTypes = 'video/*';
            } else {
                alert('Tipo de archivo no permitido.');
                resetFileInputMedia(); // Reiniciar el input de tipo file
                return;
            }

            // Verificar el tamaño del archivo
            if (file.size > maxSize) {
                alert(`El archivo excede el tamaño máximo permitido de ${maxSize / (1024 * 1024)}MB.`);
                resetFileInputMedia(); // Reiniciar el input de tipo file    
                return;
            }

            getCurrentAccount().then((account) => {
                if (account) {
                    MarketsmsService.uploadMediaTemplate(file, account.id, nameMedia).then((response) => {
                        if (response.data) {
                            setPreviewUrl(response.data.url);
                            setNameMedia(response.data.name.split('.')[0]);

                            setHeaderContent(response.data.url.replaceAll(response.data.name, '{{1}}'));

                            setHeaderVariablesData([response.data.name]);

                            const reader = new FileReader();
                            reader.onload = () => {
                                setPreviewUrl(reader.result as string);
                            };
                            reader.readAsDataURL(file);
                        }
                    });
                } else {
                    showToastTR('Parece que no has seleccionado la cuenta para crear el template', 4000, 'error');
                }
            });
        }
    };


    const resetFileInputMedia = () => {
        if (fileInputMediaRef.current) {
            fileInputMediaRef.current.value = ''; // Limpiar el valor del input de tipo file
        }
    };

    const [buttons, setButtons] = useState<WhatsappTemplatesButtonsModel[]>([]);
    const [templateName, setTemplateName] = useState("");

    const sortButtons = (array: WhatsappTemplatesButtonsModel[], order: string) => {
        return array.sort((a, b) => {
            // Comparación basada en la propiedad 'action_type'
            if (order === 'ASC') {
                // Orden ascendente
                if (a.action_type < b.action_type) {
                    return -1;
                }
                if (a.action_type > b.action_type) {
                    return 1;
                }
            } else if (order === 'DESC') {
                // Orden descendente
                if (a.action_type > b.action_type) {
                    return -1;
                }
                if (a.action_type < b.action_type) {
                    return 1;
                }
            }
            // Si son iguales o el orden no está definido correctamente, retorna 0
            return 0;
        });
    };

    const addButton = (action_type: number, type?: number | undefined) => {
        const newButton = {
            action_type: action_type,
            type: type ?? 0,
            text: type == 4 ? 'Copiar código de oferta' : (type == 3 ? 'Llamar al número de teléfono' : (type == 1 ? 'Respuesta rápida' : (type == 2 ? 'Visita el sitio web' : ''))),
            content: type == 1 ? 'Respuesta rápida' : ""
        };
        var previewButtons = [...buttons, newButton];
        if (previewButtons[0].action_type == 1) {
            sortButtons(previewButtons, 'ASC');
        } else {
            sortButtons(previewButtons, 'DESC');
        }
        setButtons(previewButtons);
    };

    const [countQuickReply, setCountQuickReply] = useState(0);
    const [countWebsite, setCountWebsite] = useState(0);
    const [countPhone, setCountPhone] = useState(0);
    const [countCopyCode, setCountCopyCode] = useState(0);

    useEffect(() => {
        // Reiniciar contadores
        setCountQuickReply(0);
        setCountWebsite(0);
        setCountPhone(0);
        setCountCopyCode(0);

        // Calcular nuevos contadores
        const newCounts = buttons.reduce((counts, element) => {
            switch (element.type) {
                case 1:
                    return { ...counts, countQuickReply: counts.countQuickReply + 1 };
                case 2:
                    return { ...counts, countWebsite: counts.countWebsite + 1 };
                case 3:
                    return { ...counts, countPhone: counts.countPhone + 1 };
                case 4:
                    return { ...counts, countCopyCode: counts.countCopyCode + 1 };
                default:
                    return counts;
            }
        }, {
            countQuickReply: 0,
            countWebsite: 0,
            countPhone: 0,
            countCopyCode: 0
        });

        // Actualizar estados
        setCountQuickReply(newCounts.countQuickReply);
        setCountWebsite(newCounts.countWebsite);
        setCountPhone(newCounts.countPhone);
        setCountCopyCode(newCounts.countCopyCode);

    }, [buttons]);


    const deleteButton = (index: number) => {
        const copyButtons = [...buttons];
        copyButtons.splice(index, 1);
        setButtons(copyButtons);
    };

    const updateTextButton = (index: number, text: string) => {
        const copyButtons = [...buttons];
        copyButtons[index].text = text;
        setButtons(copyButtons);
    }

    const updateContentButton = (index: number, text: string) => {
        const copyButtons = [...buttons];
        copyButtons[index].content = text;

        if (copyButtons[index].url_type == 1) {
            console.log("Aqui viene");
            inputWebsiteUrlRef?.current?.setSelectionRange(text.length - 5, text.length - 5);
            inputWebsiteUrlRef?.current?.focus();
        }
        setButtons(copyButtons);
    }

    const updateUrlTypeButton = (index: number, url_type: number) => {
        console.log("updateUrlTypeButton");
        const copyButtons = [...buttons];
        copyButtons[index].url_type = url_type;
        setButtons(copyButtons);
    }

    const updateExampleButton = (index: number, example: string) => {
        console.log("updateUrlTypeButton");
        const copyButtons = [...buttons];
        copyButtons[index].example = example.replaceAll(' ', '_');
        setButtons(copyButtons);
    }


    // Funciones reutilizables

    function isInteger(valor: any): boolean {
        const numero = Number(valor);
        return typeof numero === 'number' && Number.isInteger(numero);
    }

    // Función para formatear el texto según convenciones
    const formatText = (text: string) => {
        let formattedText = text;
        formattedText = formattedText.replace(/\*([^\*]+)\*/g, '<strong>$1</strong>');
        formattedText = formattedText.replace(/_([^_]+)_/g, '<em>$1</em>');
        formattedText = formattedText.replace(/~([^~]+)~/g, '<del>$1</del>');
        formattedText = formattedText.replace(/```([^`]+)```/g, '<code style="color:inherit">$1</code>');
        formattedText = formattedText.replace(/\n/g, '<br/>'); // Convertir saltos de línea a <br/>
        return <p className="m-0 fs-7 mt-2 text-black" dangerouslySetInnerHTML={{ __html: formattedText }} />;
    };

    const handleSubmit = () => {

        console.log("-------------------------handleSubmit-------------------------");
        console.log(headerContent);
        console.log(headerText);
        const newTemplate: WhatsappTemplatesModel = {
            name: templateName,
            language: language,
            category: category,
            allow_category_change: allowCategoryChange,
            components: {
                header: {
                    format: headerType.toString(),
                    content: headerContent && headerContent != "" ? headerContent : headerText,
                    example: headerVariablesData[0]
                },
                body: {
                    content: bodyText,
                    examples: bodyVariablesData
                },
                footer: {
                    content: footerContent
                },
                buttons: buttons
            }
        };


        const postNewTemplate: WhatsappTemplatesCreateModel = {
            whatsapp_account_id: 0,
            template: newTemplate
        };

        console.log(postNewTemplate);

        getCurrentAccount().then((account) => {
            if (account?.id) {

                const phone_number_id = getDefaultPhoneNumber();

                if (phone_number_id != null) {
                    MarketsmsService.createTemplate(postNewTemplate, phone_number_id, account.id).then((response) => {
                        console.log(response);

                        if (response.status == 200) {
                            showToastTR(customMessage(response.code_app), 3000, 'success');
                            setTimeout(() => {
                                navigate("/whatsapp/templates");
                            }, 3000);
                        } else {
                            showToastTR(customMessage(response.code_app), 3000, 'error');
                        }
                    });
                } else {
                    showToastTC("No contiene un numero de telefono por defecto, seleccionalo por favor", 5000, "error");
                    setTimeout(() => {
                        navigate("/whatsapp/numbers");
                    }, 5000);
                }

            }
        })
    }

















    // HEADER SECTION

    const [headerText, setHeaderText] = useState("");
    const [headerContent, setHeaderContent] = useState("");
    const [headerTextPreview, setHeaderTextPreview] = useState("");
    const inputHeaderRef = useRef<HTMLInputElement>(null);


    // Función para insertar texto en la posición del cursor
    const insertTextAtCursorHeader = (text: string) => {
        if (!inputHeaderRef.current) return;

        // Revisar si ya tiene una variable
        if (headerText.includes('{{1}}')) {
            showToastTR("Solo se puede agregar una variable en el encabezado", 2000, 'error');
        } else {
            text = "{{1}}";
            const { selectionStart, selectionEnd } = inputHeaderRef.current;
            if (selectionStart !== null && selectionEnd !== null) {
                const newText = headerText.substring(0, selectionStart) + text + headerText.substring(selectionEnd);
                setHeaderText(newText);
                inputHeaderRef?.current?.setSelectionRange(newText.length, newText.length);
                inputHeaderRef?.current?.focus();
            }
        }
    };


    // Función para que agregue los botones para las variables del body
    const [headerVariables, setHeaderVariables] = useState<string[]>([]);

    useEffect(() => {
        console.log("Se cambia el texto del body");
        // Expresión regular para encontrar patrones como {{1}}, {{2}}, etc.
        // const regex = /\{\{\d+\}\}/g;
        const regex = /\{\{([^{}]+)\}\}/g;
        var lastVariable = 0;

        // Aplicar la expresión regular al texto
        const resultados = headerText.match(regex);

        if (resultados) {
            console.log("Se encontraron los siguientes patrones:");
            setHeaderVariables([]);
            resultados.forEach(resultado => {
                var variable = resultado.replace(/{{|}}/g, '');
                if (isInteger(variable)) {
                    console.log("Es un número");
                    lastVariable = parseInt(variable);
                    setHeaderVariables(prevHeaderVariables => [...prevHeaderVariables, resultado]);
                } else {
                    console.log("No es un número");
                    setHeaderText(headerText.replaceAll('{{' + variable + '}}', ''));
                    showToastTR('El parámetro de la variable no esta permitido', 1000, 'error');
                }
            });
        }
        const startVariable = /{{(?![0-9a-zA-Z])/g;

        var newText = headerText;
        console.log("---------------------------    LAST KEY PRESSED    -------------------------------");
        console.log(lastKeyPressedHeader);
        if (lastKeyPressedHeader != 'Backspace') {
            newText = headerText.replaceAll(startVariable, '{{' + (lastVariable + 1) + '}}')
        }

        setHeaderText(newText);
        setHeaderTextPreview(newText);
        console.log("NewText");
        console.log(newText);
        // setTimeout(() => {
        //     console.log(headerTextPreview);
        // }, 10);
    }, [headerText]);

    useEffect(() => {
        console.log("Cambio en headerTextPreview");
    }, [headerTextPreview])


    useEffect(() => {
        console.log("-----------headerVariables-----------");
        console.log(headerVariables);
        // const copyBodyVariables = [...bodyVariables];
        // setBodyVariables([...bodyVariables, "df"]);

    }, [headerVariables]);

    const [headerVariablesData, setHeaderVariablesData] = useState<string[]>([]);

    const handleChangeValueVariableHeader = (index: number, data: string) => {
        // Crear una copia del array de estado actual
        const updatedHeaderVariables = [...headerVariablesData];
        // Actualizar el elemento específico en la copia del array
        updatedHeaderVariables[index] = data;
        // Establecer el nuevo estado

        console.log(data);
        setHeaderVariablesData(updatedHeaderVariables);
        console.log(updatedHeaderVariables);
    }

    useEffect(() => {
        let updatedHeaderText = headerText; // Inicializar con el texto original

        headerVariablesData.forEach((element, index) => {
            updatedHeaderText = updatedHeaderText.replaceAll(`{{${index + 1}}}`, element);
        });

        setHeaderTextPreview(updatedHeaderText);
    }, [headerVariablesData, headerText]);









    const getWords = (text: string) => {
        var textClean = text.replaceAll('  ', '');
        return textClean.trim().split(' ').length;
    }




    // BODY SECTION

    const [bodyInvalid, setBodyInvalid] = useState("");
    const [bodyText, setBodyText] = useState("");
    const [bodyTextPreview, setBodyTextPreview] = useState("");
    const textAreaRef = useRef<HTMLTextAreaElement>(null);



    // Función para insertar texto en la posición del cursor
    const insertTextAtCursor = (text: string) => {
        if (!textAreaRef.current) return;
        var variableNumber = 0;
        if (text == '{{variable}}') {

            do {
                variableNumber++;
                if (variableNumber > 100) {
                    showToastTC("En el cuerpo del mensaje solo se aceptan 100 variables", 2000, 'error');
                    return;
                }
            } while (bodyText.includes("{{" + variableNumber + "}}"));
            text = "{{" + variableNumber + "}}";

            // consultar haber cuantas palabrasy cuantos variables hay
            console.log("variableNumber", variableNumber);
            console.log("getWords", getWords(bodyText));
            if (getWords(bodyText) >= (2 * (variableNumber) + 1)) {
                console.log("Hay mas palabras que variables");
            } else {
                console.log("No se cumple la condición");
            }

        }

        const { selectionStart, selectionEnd } = textAreaRef.current;
        if (selectionStart !== null && selectionEnd !== null) {
            const newText =
                bodyText.substring(0, selectionStart) +
                text +
                bodyText.substring(selectionEnd);
            setBodyText(newText);

            console.log(selectionStart);
            console.log(text.length);
            console.log(text.length / 2);

            var newPosition = newText.length;
            if (variableNumber == 0) {
                newPosition = selectionStart + (text.length / 2);
            }

            setTimeout(() => { // Esperar a que se actualice el DOM
                textAreaRef?.current?.setSelectionRange(newPosition, newPosition);
                textAreaRef?.current?.focus();
            }, 0);
        }
    };


    // Función para obtener la última tecla pulsada
    const [lastKeyPressedHeader, setLastKeyPressedHeader] = useState<string>('');
    const [lastKeyPressedBody, setLastKeyPressedBody] = useState<string>('');

    const handleKeyDownHeader = (event: React.KeyboardEvent<HTMLInputElement>) => {
        // Obtén la tecla pulsada
        const key = event.key;
        console.log('Tecla pulsada:', key);

        // Actualiza el estado con la última tecla pulsada
        setLastKeyPressedHeader(key);
    }

    const handleKeyDownBody = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        // Obtén la tecla pulsada
        const key = event.key;
        console.log('Tecla pulsada:', key);
        setLastKeyPressedBody(key);
    }



    // Función para que agregue los botones para las variables del body
    const [bodyVariables, setBodyVariables] = useState<string[]>([]);

    useEffect(() => {
        var variableNumber = 1;

        while (bodyText.includes("{{" + variableNumber + "}}")) {
            variableNumber++;
        }

        console.log("Numero de variables ", variableNumber - 1);
        console.log("getWords", getWords(bodyText));
        console.log("Primero", (getWords(bodyText) - (variableNumber - 1)));
        console.log("Segundo", ((2 * (variableNumber - 1) + 1)));
        if ((getWords(bodyText) - (variableNumber - 1)) >= ((2 * (variableNumber - 1) + 1))) {
            console.log("Se cumple condición");
            setBodyInvalid("");
        } else {
            console.log("No se cumple condición");
            setBodyInvalid("Esta plantilla contiene demasiados parámetros variables en relación con la longitud del mensaje. Debes disminuir el número de parámetros o aumentar la longitud del mensaje.");
        }



        // if ((getWords(bodyText) - variableNumber) >= (2 * (variableNumber - 1) + 1)) {
        //     console.log("Hay mas palabras que variables");
        //     setBodyInvalid("");
        // } else {
        //     console.log("No se cumple la condición");
        //     setBodyInvalid("Esta plantilla contiene demasiados parámetros variables en relación con la longitud del mensaje. Debes disminuir el número de parámetros o aumentar la longitud del mensaje.");
        // }







        console.log("Se cambia el texto del body");
        // Expresión regular para encontrar patrones como {{1}}, {{2}}, etc.
        // const regex = /\{\{\d+\}\}/g;
        const regex = /\{\{([^{}]+)\}\}/g;
        var lastVariable = 0;

        // Aplicar la expresión regular al texto
        const resultados = bodyText.match(regex);

        if (resultados) {
            console.log("Se encontraron los siguientes patrones:");
            setBodyVariables([]);
            resultados.forEach(resultado => {
                var variable = resultado.replace(/{{|}}/g, '');
                if (isInteger(variable)) {
                    console.log("Es un número");
                    lastVariable = parseInt(variable);
                    setBodyVariables(prevBodyVariables => [...prevBodyVariables, resultado]);
                } else {
                    console.log("No es un número");
                    setBodyText(bodyText.replaceAll('{{' + variable + '}}', ''));
                    showToastTR('El parámetro de la variable no esta permitido', 1000, 'error');
                }
            });
        }

        var newText = bodyText;
        if (lastKeyPressedBody != 'Backspace') {
            const startVariable = /{{(?![0-9a-zA-Z])/g;
            newText = bodyText.replaceAll(startVariable, '{{' + (lastVariable + 1) + '}}');
        }



        setBodyText(newText);
        setBodyTextPreview(newText);
    }, [bodyText]);


    useEffect(() => {
        console.log("-----------bodyVariables-----------");
        console.log(bodyVariables);
        // const copyBodyVariables = [...bodyVariables];
        // setBodyVariables([...bodyVariables, "df"]);

    }, [bodyVariables]);

    const [bodyVariablesData, setBodyVariablesData] = useState<string[]>([]);

    const handleChangeValueVariableBody = (index: number, data: string) => {
        // Crear una copia del array de estado actual
        const updatedBodyVariables = [...bodyVariablesData];
        // Actualizar el elemento específico en la copia del array
        updatedBodyVariables[index] = data;
        // Establecer el nuevo estado
        setBodyVariablesData(updatedBodyVariables);
    }

    useEffect(() => {
        let updatedBodyText = bodyText; // Inicializar con el texto original

        bodyVariablesData.forEach((element, index) => {
            updatedBodyText = updatedBodyText.replaceAll(`{{${index + 1}}}`, element);
        });

        setBodyTextPreview(updatedBodyText);
    }, [bodyVariablesData, bodyText]);


    const [headerTypeUrl, setHeaderTypeUrl] = useState<number[]>([]);

    // const handleTypeChangeUrl = (e: any) => {
    //     const selectedTypeUrl = parseInt(e.target.value);
    //     setHeaderTypeUrl([...selectedTypeUrl]);
    // };


    const inputWebsiteUrlRef = useRef<HTMLInputElement>(null);



    // const [buttonsVariables, setButtonsVariables] = useState<string[]>([]);


    return (
        <div className="col-12">
            <CCard className="mb-4">
                <CCardHeader className="p-0 d-flex align-items-center ps-1">
                    <Link to={'/whatsapp/templates'} >
                        <BIcon icon="arrow-left-short" className="fs-3 me-1 pt-2 text-danger cursor-pointer" />
                    </Link>
                    <strong>Crear plantilla</strong>
                </CCardHeader>
                <CCardBody>
                    <CRow>
                        <CCol md={8}>
                            <CRow>
                                <CCol>
                                    <CFormInput
                                        placeholder="Nombre"
                                        className="mb-2"
                                        value={templateName}
                                        onChange={(e) => { setTemplateName(e.target.value.toLowerCase().replaceAll(" ", "_").replace(/[^\w]|/g, "")) }}
                                    />
                                </CCol>
                                <CCol md={3}>
                                    <CFormSelect
                                        size="sm"
                                        className="mb-2"
                                        aria-label="Small select example"
                                        onChange={(e) => { setLanguage(parseInt(e.target.value + "")) }}
                                        value={language}
                                    >
                                        <option>Lenguaje</option>
                                        <option value="1">Español</option>
                                        <option value="2">Ingles</option>
                                    </CFormSelect>
                                </CCol>
                                <CCol>
                                    <CFormSelect
                                        size="sm"
                                        className="mb-2"
                                        aria-label="Small select example"
                                        onChange={(e) => { setCategory(parseInt(e.target.value + "")) }}
                                        value={category}
                                    >
                                        <option>Escoger categoria</option>
                                        <option value="1">Marketing</option>
                                        {/* <option value="2">Utilidad</option>
                                        <option value="3">Autenticación</option>
                                        <option value="4">Servicio</option> */}
                                    </CFormSelect>
                                    <CFormCheck
                                        id="checkSaveSource"
                                        type="checkbox"
                                        label="Permitir cambio de categoria"
                                        className="mb-0 cursor-pointer fs-7"
                                        checked={allowCategoryChange}
                                        onChange={() => { setAllowCategoryChange(!allowCategoryChange) }}
                                    />
                                </CCol>
                            </CRow>
                            <div>
                                <CFormSelect
                                    id="selectHeader"
                                    size="sm"
                                    className="form-select mb-3"
                                    aria-label="Small select example"
                                    onChange={handleTypeChange}
                                    value={headerType}
                                    label="Selecciona el tipo de encabezado:"
                                >
                                    <option value="0">Ninguna</option>
                                    <option value="1">Mensaje de texto</option>
                                    <option value="2">Imagen</option>
                                    <option value="3">Video</option>
                                    {/* <option value="4">Documento</option>
                                    <option value="5">Ubicación</option> */}
                                </CFormSelect>
                            </div>
                            {headerType === 1 && (
                                <div className="mb-2">
                                    <CFormInput
                                        id="headerText"
                                        type="text"
                                        placeholder="Escribe tu mensaje de encabezado"
                                        className="form-control"
                                        onKeyDown={handleKeyDownHeader}
                                        value={headerText}
                                        ref={inputHeaderRef}
                                        label="Mensaje de encabezado:"
                                        onChange={(e) => setHeaderText(e.target.value)}
                                    />
                                    <CRow className="text-end mb-2 me-1">
                                        <CCol sm={12} className="p-0 m-0 text-end mt-2 "> <CButton onClick={() => insertTextAtCursorHeader("{{variable}}")} color="light" className="shadow-none p-1 px-2 text-start">
                                            <CIcon icon={cilPlus} /> Agregar
                                        </CButton></CCol>
                                    </CRow>
                                    {headerVariables.map((variable, index) => {
                                        return (
                                            <CRow className="mb-2" key={index}>
                                                <CCol sm={1} className="text-end pe-0 fs-6 py-1">{variable}</CCol>
                                                <CCol sm={11}> <CFormInput
                                                    value={headerVariablesData[index]}
                                                    onChange={(e) => { handleChangeValueVariableHeader(index, e.target.value) }}
                                                /></CCol>
                                            </CRow>
                                        )
                                    })}
                                </div>

                            )}
                            {headerType === 2 && (
                                <div className="mb-2">
                                    <CFormInput
                                        id="imageFile"
                                        type="file"
                                        accept=".png, .jpeg"
                                        className="form-control"
                                        label="Subir imagen:"
                                        onChange={(e) => handleFileChange(e.target.files)}
                                        text="Tamaño máximo permitido 2MB"
                                        ref={fileInputMediaRef}
                                    />
                                </div>
                            )}
                            {headerType === 3 && (
                                <div className="mb-2">
                                    <CFormInput
                                        id="videoFile"
                                        type="file"
                                        accept=".3gp, .mp4"
                                        className="form-control"
                                        label="Subir video:"
                                        onChange={(e) => handleFileChange(e.target.files)}
                                        text="Tamaño máximo permitido 16MB"
                                        ref={fileInputMediaRef}
                                    />
                                </div>
                            )}
                            <CFormTextarea
                                placeholder="Ejemplo de texto..."
                                rows={3}
                                className="mt-0 mb-2"
                                onKeyDown={handleKeyDownBody}
                                feedbackInvalid={bodyInvalid}
                                invalid={bodyInvalid ? true : false}
                                label="Cuerpo"
                                value={bodyText}
                                onChange={(e) => setBodyText(e.target.value)}
                                ref={textAreaRef}
                            />
                            <CRow className="text-end mb-2">
                                <CCol sm={6}></CCol>
                                <CCol sm={6} >
                                    <CRow className="pe-2 text-end align-items-end">
                                        <CCol sm={1}></CCol>
                                        {/* <CCol className="p-0 m-0" title="Inserta un emoji"><CButton color="light" className="shadow-none px-0 mx-1" style={{ minWidth: "35px" }}><strong><BIcon icon="emoji-smile" /></strong></CButton></CCol> */}
                                        <CCol className="p-0 m-0" title="Negrita"><CButton onClick={() => insertTextAtCursor("**")} color="light" className="shadow-none px-0 mx-1" style={{ minWidth: "35px" }}><strong>B</strong></CButton></CCol>
                                        <CCol className="p-0 m-0" title="Cursiva"><CButton onClick={() => insertTextAtCursor("__")} color="light" className="shadow-none px-0 mx-1" style={{ minWidth: "35px" }}><em>I</em></CButton></CCol>
                                        <CCol className="p-0 m-0" title="Subrayado"><CButton onClick={() => insertTextAtCursor("~~")} color="light" className="shadow-none px-0 mx-1" style={{ minWidth: "35px" }}><del>S</del></CButton></CCol>
                                        <CCol className="p-0 m-0" title="Monospace"><CButton onClick={() => insertTextAtCursor("``````")} color="light" className="shadow-none px-0 mx-1" style={{ minWidth: "35px" }}><strong><BIcon icon="code-slash" /></strong></CButton></CCol>
                                        <CCol sm={4} className="p-0 m-0"> <CButton onClick={() => insertTextAtCursor("{{variable}}")} color="light" className="shadow-none p-1 px-2 text-start">
                                            <CIcon icon={cilPlus} /> Agregar
                                        </CButton></CCol>
                                    </CRow>

                                </CCol>


                            </CRow>

                            {bodyVariables.map((variable, index) => {
                                return (
                                    <CRow className="mb-2" key={index}>
                                        <CCol sm={1} className="text-end pe-0 fs-6 py-1">{variable}</CCol>
                                        <CCol sm={11}> <CFormInput
                                            value={bodyVariablesData[index]}
                                            onChange={(e) => { handleChangeValueVariableBody(index, e.target.value) }}
                                        /></CCol>
                                    </CRow>
                                )
                            })}

                            <CFormInput
                                placeholder='Responda la palabra "STOP" para dejar de recibir mensajes.'
                                className="mb-2"
                                label="Pie de página"
                                value={footerContent}
                                onChange={(e) => { setFooterContent(e.target.value) }}
                            />
                            <CDropdown className="mb-3">
                                <CDropdownToggle color="light">
                                    <CIcon icon={cilPlus} className="me-2" />
                                    Agregar botón
                                </CDropdownToggle>
                                <CDropdownMenu>
                                    <h6 className="dropdown-header">Botones de respuesta rápida</h6>
                                    <CDropdownItem className="cursor-pointer" onClick={() => { addButton(1, 1) }} disabled={countQuickReply > 2}>
                                        Personalizado
                                        <div className="text-muted small">3 botones como máximo</div>
                                    </CDropdownItem>
                                    <CDropdownDivider />
                                    <h6 className="dropdown-header">Botones de llamada a la acción</h6>
                                    <CDropdownItem className="cursor-pointer" onClick={() => { addButton(2, 2) }} disabled={countWebsite > 1}>
                                        Ir al sitio web
                                        <div className="text-muted small">2 botones como máximo</div>
                                    </CDropdownItem>
                                    <CDropdownItem className="cursor-pointer" onClick={() => { addButton(2, 3) }} disabled={countPhone > 0}>
                                        Llamar al número de teléfono
                                        <div className="text-muted small">1 botón como máximo</div>
                                    </CDropdownItem>
                                    <CDropdownItem className="cursor-pointer" onClick={() => { addButton(2, 4) }} disabled={countCopyCode > 0}>
                                        Copiar código de oferta
                                        <div className="text-muted small">1 botón como máximo</div>
                                    </CDropdownItem>
                                </CDropdownMenu>
                            </CDropdown>

                            {/* Aqui va el ejemplo de todos los botones */}
                            {buttons.map((button, index) => (
                                <div key={index}>
                                    {button.type == 1 &&
                                        <CRow className="bg-gray p-1 m-1 align-items-center">
                                            <CCol md={11}>
                                                <CFormInput
                                                    placeholder='Quick Reply'
                                                    maxLength={25}
                                                    className="mb-2"
                                                    label="Texto del botón"
                                                    value={button.text}
                                                    onChange={(e) => { updateTextButton(index, e.target.value) }}
                                                />
                                            </CCol>
                                            <CCol md={1} className="text-end">
                                                <BIcon icon="x-lg" className="align-middle cursor-pointer" onClick={() => { deleteButton(index) }} />
                                            </CCol>
                                        </CRow>
                                    }
                                    {button.type == 4 &&
                                        <CRow className="bg-gray p-1 m-1 align-items-center">
                                            <CCol md={11}>
                                                <CFormInput
                                                    placeholder='ACBFG'
                                                    maxLength={25}
                                                    className="mb-2"
                                                    label="Ejemplo código de oferta"
                                                    value={button.content}
                                                    onChange={(e) => { updateContentButton(index, e.target.value) }}
                                                />
                                            </CCol>
                                            <CCol md={1} className="text-end">
                                                <BIcon icon="x-lg" className="align-middle cursor-pointer" onClick={() => { deleteButton(index) }} />
                                            </CCol>
                                        </CRow>
                                    }
                                    {button.type == 2 &&
                                        <div>
                                            <CRow className="bg-gray p-1 m-1 align-items-center">
                                                <CCol md={11}>
                                                    <CRow>
                                                        <CCol sm={4}>
                                                            <CFormInput
                                                                placeholder='ACBFG'
                                                                maxLength={25}
                                                                className="mb-2"
                                                                label="Texto del botón"
                                                                value={button.text}
                                                                onChange={(e) => { updateTextButton(index, e.target.value) }}
                                                            />
                                                        </CCol>
                                                        <CCol sm={3}>

                                                            <CFormSelect
                                                                id="selectTypeUrl"
                                                                size="sm"
                                                                className="form-select mb-3"
                                                                aria-label="Small select example"
                                                                label="Tipo de URL"
                                                                onChange={(e) => { updateUrlTypeButton(index, parseInt(e.target.value)) }}
                                                                value={button.url_type}
                                                            // value={button.url_type == "1" ? button.text.replaceAll('{{1}}', '') + "{{1}}" : button.text.replaceAll('{{1}}', '')}
                                                            >
                                                                <option value="0">Estática</option>
                                                                <option value="1">Dinámica</option>
                                                            </CFormSelect>
                                                        </CCol>
                                                        <CCol>

                                                            <label htmlFor="" className="mb-2">Dirección del sitio web</label>
                                                            <CInputGroup className="mb-3">
                                                                {/* <CFormInput placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" /> */}

                                                                <CFormInput
                                                                    placeholder={`https://www.example.com/${button.url_type == 1 ? '{{1}}' : '123'}`}
                                                                    maxLength={2000}
                                                                    value={button.content.replaceAll('{{1}}', '')}
                                                                    onChange={(e) => { updateContentButton(index, e.target.value + (button.url_type == 1 ? '{{1}}' : '')) }}
                                                                />
                                                                {button.url_type == 1 &&
                                                                    <CInputGroupText id="basic-addon1" className="px-1">{'{{1}}'}</CInputGroupText>
                                                                }
                                                            </CInputGroup>


                                                            {/* <CFormInput
                                                                placeholder={`https://www.example.com/${button.url_type == 1 ? '{{1}}' : '123'}`}
                                                                className="mb-2"
                                                                label="Dirección del sitio web"
                                                                value={button.url_type == 1 && button.content != "" ? button.content.replaceAll('{{1}}', '') + "{{1}}" : button.content.replaceAll('{{1}}', '')}
                                                                onChange={(e) => { updateContentButton(index, e.target.value) }}
                                                            /> */}
                                                        </CCol>
                                                    </CRow>
                                                </CCol>
                                                <CCol md={1} className="text-end">
                                                    <BIcon icon="x-lg" className="align-middle cursor-pointer" onClick={() => { deleteButton(index) }} />
                                                </CCol>
                                            </CRow>
                                            {/* <CRow className="bg-gray p-1 m-1 align-items-center">
                                            </CRow> */}
                                            {button.url_type == 1 &&
                                                <CRow className="my-2" key={index}>
                                                    <CCol sm={1} className="text-center pe-0 fs-6 py-1">{"{{1}}"}</CCol>
                                                    <CCol sm={11}>
                                                        <CFormInput
                                                            value={button.example}
                                                            onChange={(e) => { updateExampleButton(index, e.target.value) }}
                                                        // text={((button.content ?? '') + (button.example ?? '')) ?? ''}
                                                        // {validateUrl(((button.content ?? '') + (button.example ?? '')) ?? '') ? feedbackValid=(((button.content ?? '') + (button.example ?? '')) ?? ''): feedbackInvalid=(((button.content ?? '') + (button.example ?? '')) ?? '')}
                                                        />

                                                        {validateUrl((button.content + button.example).replaceAll('{{1}}', '')) ?
                                                            <div className="text-success fs-7">{(button.content + button.example).replaceAll('{{1}}', '')}</div>
                                                            :
                                                            <div className="text-danger fs-7">{(button.content + button.example).replaceAll('{{1}}', '')}</div>
                                                        }
                                                    </CCol>
                                                </CRow>
                                            }
                                        </div>
                                    }
                                    {button.type == 3 &&
                                        <CRow className="bg-gray p-1 m-1 align-items-center">
                                            <CCol md={11}>
                                                <CRow>
                                                    <CCol>
                                                        <CFormInput
                                                            placeholder='Llamanos'
                                                            className="mb-2"
                                                            label="Texto del botón"
                                                            value={button.text}
                                                            onChange={(e) => { updateTextButton(index, e.target.value) }}
                                                        />
                                                    </CCol>
                                                    <CCol>
                                                        <CFormInput
                                                            placeholder='+573042575514'
                                                            className="mb-2"
                                                            label="Número de teléfono"
                                                            value={button.content}
                                                            onChange={(e) => { updateContentButton(index, e.target.value) }}
                                                        />
                                                    </CCol>
                                                </CRow>
                                            </CCol>
                                            <CCol md={1} className="text-end">
                                                <BIcon icon="x-lg" className="align-middle cursor-pointer" onClick={() => { deleteButton(index) }} />
                                            </CCol>
                                        </CRow>
                                    }
                                </div>
                            ))}
                        </CCol>

                        <CCol md={4} >
                            <CCard className="text-center">
                                <CCardHeader className="text-start">
                                    <strong>Vista previa de la plantilla</strong>
                                </CCardHeader>
                                <CCardBody className="pt-0" style={{
                                    backgroundImage: `linear-gradient(rgba(234, 218, 201, 0.8), rgba(234, 218, 201, 0.8)), url(${backgroundChat})`, // Aplica opacidad solo a la imagen de fondo
                                    backgroundSize: '100% auto', // Ajusta el tamaño para que el ancho de la imagen ocupe todo el contenedor y la altura se ajuste proporcionalmente
                                    backgroundPosition: 'center', // Centra la imagen dentro del contenedor
                                }}>
                                    <div className="d-flex flex-column mb-4">
                                        <div className="position-relative" style={{ width: 0, height: 0, borderTop: '8px solid transparent', borderBottom: '8px solid transparent', borderRight: '8px solid #fff', position: 'absolute', left: '-4px', top: '11.5px', transform: "rotate(135deg)" }}></div>
                                        <div className="bg-white rounded-end-3 rounded-bottom-3 pb-2 shadow text-start" style={{ maxWidth: "90%", minWidth: "25%", position: 'relative', minHeight: "42px" }}>
                                            <div className="m-1">
                                                {headerText && <p className="m-0 fs-7 fw-bolder">{headerTextPreview}</p>}
                                                {previewUrl && (
                                                    <>
                                                        {previewUrl.startsWith('data:image') ? (
                                                            <CImage src={previewUrl} className="rounded-2 w-100" />
                                                        ) : previewUrl.startsWith('data:video') ? (

                                                            <CustomVideo src={previewUrl} className="rounded-2 w-100" />
                                                        ) : (
                                                            <p>Preview no disponible para este tipo de archivo</p>
                                                        )}
                                                    </>
                                                )}
                                                {!previewUrl &&
                                                    <div className="bg-dark rounded-2 text-center">
                                                        {headerType == 2 && <CImage src={image} className="w-25 my-0 py-5" />}
                                                        {headerType == 3 && <CImage src={video} className="w-25 my-0 py-5" />}
                                                        {headerType == 4 && <CImage src={document} className="w-25 my-0 py-5" />}
                                                        {headerType == 5 && <CImage src={location} className="w-25 my-0 py-5" />}
                                                    </div>
                                                }
                                                <p className="m-0 fs-7 mt-2 text-black">{formatText(bodyTextPreview)}</p>
                                                <p className="m-0 fs-8 mt-2 text-gray">{footerContent}</p>

                                                <p className="p-0 fs-9 text-end m-0 text-gray" style={{ bottom: '2px', right: '10px' }}>{formatTime(new Date())}</p>
                                            </div>
                                            {buttons.length > 0 && <hr className="my-2" />}
                                            <div className={`px-${buttons.length > 0 ? 2 : 0}`}>
                                                {buttons.length < 4 &&
                                                    buttons.map((button, index) => (
                                                        <div key={index}>
                                                            {index > 0 && <hr className="my-2" />}

                                                            {button.type === 1 && (
                                                                <p className="text-center text-info my-0">
                                                                    <BIcon icon="reply-fill" size={5} className="me-2" />
                                                                    {button.text}
                                                                </p>
                                                            )}
                                                            {button.type === 4 && (
                                                                <p className="text-center text-info my-0">
                                                                    <BIcon icon="copy" size={7} className="me-2" />
                                                                    {button.text}
                                                                </p>
                                                            )}
                                                            {button.type === 2 && (
                                                                <p className="text-center text-info my-0">
                                                                    <BIcon icon="box-arrow-up-right" size={8} className="fw-bold me-2" />
                                                                    {button.text}
                                                                </p>
                                                            )}
                                                            {button.type === 3 && (
                                                                <p className="text-center text-info my-0">
                                                                    <BIcon icon="telephone-fill" size={7} className="me-2" />
                                                                    {button.text}
                                                                </p>
                                                            )}
                                                        </div>
                                                    ))
                                                }
                                                {buttons.length > 3 &&
                                                    buttons.slice(0, 2).map((button, index) => (
                                                        <div key={index}>

                                                            {index > 0 && <hr className="my-2" />}

                                                            {button.type === 1 && (
                                                                <p className="text-center text-info my-0">
                                                                    <BIcon icon="reply-fill" size={5} className="me-2" />
                                                                    {button.text}
                                                                </p>
                                                            )}
                                                            {button.type === 4 && (
                                                                <p className="text-center text-info my-0">
                                                                    <BIcon icon="copy" size={7} className="me-2" />
                                                                    {button.text}
                                                                </p>
                                                            )}
                                                            {button.type === 2 && (
                                                                <p className="text-center text-info my-0">
                                                                    <BIcon icon="box-arrow-up-right" size={8} className="fw-bold me-2" />
                                                                    {button.text}
                                                                </p>
                                                            )}
                                                            {button.type === 3 && (
                                                                <p className="text-center text-info my-0">
                                                                    <BIcon icon="telephone-fill" size={7} className="me-2" />
                                                                    {button.text}
                                                                </p>
                                                            )}

                                                            {index === 1 && (
                                                                <div>
                                                                    <hr className="my-2" />
                                                                    <p className="text-center text-info my-0">
                                                                        <BIcon icon="list-ul" size={5} className="me-2" />Ver todas las opciones
                                                                    </p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </CCardBody>
                <CCardFooter>
                    <CButton onClick={handleSubmit}>
                        Guardar
                    </CButton>
                </CCardFooter>
            </CCard>
        </div>
    );
};
// Hola  gente mala {{1}} Hola  gente mala {{2}}Hola  gente mala {{3}}Hola  gente mala {{4}}Hola  gente mala {{5}}Hola  gente mala {{6}}Hola  gente mala {{7}}Hola  gente mala {{8}}Hola  gente mala {{9}}Hola  gente mala {{10}} Hola  gente mala {{11}} Hola  gente mala {{12}}Hola  gente mala {{13}}Hola  gente mala {{14}}Hola  gente mala {{15}}Hola  gente mala {{16}}Hola  gente mala {{17}}Hola  gente mala {{18}}Hola  gente mala {{19}}Hola  gente mala {{20}} Hola  gente mala {{21}} Hola  gente mala {{22}}Hola  gente mala {{23}}Hola  gente mala {{24}}Hola  gente mala {{25}}Hola  gente mala {{26}}Hola  gente mala {{27}}Hola  gente mala {{28}}Hola  gente mala {{29}}Hola  gente mala {{30}}
// Hola  gente mala {{31}} Hola  gente mala {{32}}Hola  gente mala {{33}}Hola  gente mala {{34}}Hola  gente mala {{35}}Hola  gente mala {{36}}Hola  gente mala {{37}}Hola  gente mala {{38}}Hola  gente mala {{39}}Hola  gente mala {{40}} Hola  gente mala {{41}} Hola  gente mala {{42}}Hola  gente mala {{43}}Hola  gente mala {{44}}Hola  gente mala {{4}}Hola  gente mala {{46}}Hola  gente mala {{47}}Hola  gente mala {{48}}Hola  gente mala {{49}}Hola  gente mala {{50}} Hola  gente mala {{51}} Hola  gente mala {{52}}Hola  gente mala {{53}}Hola  gente mala {{54}}Hola  gente mala {{55}}Hola  gente mala {{56}}Hola  gente mala {{57}}Hola  gente mala {{58}}Hola  gente mala {{59}}Hola  gente mala {{60}}