import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import '../../scss/_swagger.scss'

function SwaggerDocs() {
    return (
        <div >
            <SwaggerUI url="/files/swagger/apis/marketsms.json" />
        </div>
    )
}

export default SwaggerDocs;
