import React, { useState } from "react";
import {
  CCard,
  CCardHeader,
  CCardBody,
  CTabContent,
  CTabPane,
  CForm,
  CFormInput,
  CFormTextarea,
  CCol,
  CButton,
  CRow
} from "@coreui/react-pro";

import * as MarketsmsService from "../../services/MarketsmsService";
import { customMessage, showToastTR } from "../../Utils/BaseApp";
import { getSegments } from "../../Utils/Messages";
import { useAppDispatch } from "../../hooks/hooks";
import { setSegments } from "../../redux/features/messageSlice";
import { selectMessage } from "../../redux/selectors";
import { useAppSelector } from '../../hooks/hooks';

interface SendProps { }

export const Send: React.FC<SendProps> = ({ }: SendProps) => {
  const [validated, setValidated] = useState(false);
  const dispatch = useAppDispatch();
  const { segments } = useAppSelector(selectMessage)

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const form = event.currentTarget as HTMLFormElement;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      sendMessage();
    }
    setValidated(true);
  };

  const sendMessage = async () => {
    const response = await MarketsmsService.sendMessage(formData);
    console.log(response);
    if (response) {
      if (response.status === 200) {
        showToastTR(customMessage(response.code_app), 1000, "success");
        setFormData({ body: '', to: '57' });
      } else {
        showToastTR(customMessage(response.code_app), 4000, "error");
      }
    }
  };

  const [formData, setFormData] = useState({
    body: "",
    to: "57",
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleChangeBody = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    var { name, value } = event.target;
    console.log(name);
    console.log(value);

    console.log(formData);
    const contseg = getSegments(value);
    console.log("contseg");
    console.log(contseg);
    console.log("---END---");
    dispatch(setSegments({ segments: contseg }))
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="col-12">
      <CCard className="mb-4">
        <CCardHeader>
          <strong>Enviar mensaje</strong>
        </CCardHeader>
        <CCardBody>
          <CTabContent className="rounded-bottom">
            <CTabPane
              className="p-3 active preview show"
              role="tabpanel"
              id="preview-578"
            >
              <CForm
                className="row g-3 needs-validation"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
                <CCol md={12}>
                  <CFormInput
                    pattern="[57]{2}[3]{1}[0-9]{2}[0-9]{3}[0-9]{4}"
                    value={formData.to}
                    type="text"
                    name="to"
                    minLength={12}
                    maxLength={12}
                    placeholder="Ejm. 312 345 5678"
                    feedbackValid="Luce bien!"
                    feedbackInvalid="Por favor escriba un número de teléfono valido."
                    id="validationCustom02"
                    label="Número de teléfono"
                    onChange={handleChange}
                    required
                  />
                </CCol>
                <CCol md={12}>
                  <CRow>
                    <CCol md={6}>Mensaje</CCol>
                    <CCol md={6} className={`text-end text-${segments < 2 ? 'success' : 'danger'}`}><strong>Segmentos:</strong> {segments}</CCol>
                  </CRow>
                  <CFormTextarea
                    className="mt-2"
                    name="body"
                    placeholder="Escriba aqui el mensaje a enviar"
                    feedbackValid="Luce bien!"
                    id="validationCustom02"
                    // label={"Mensaje " + segments}
                    onChange={handleChangeBody}
                    value={formData.body}
                    feedbackInvalid="Por favor escriba un mensaje valido."
                    rows={3}
                    required
                  />
                </CCol>
                <CCol xs={12}>
                  <CButton color="primary" type="submit">
                    Enviar
                  </CButton>
                </CCol>
              </CForm>
            </CTabPane>
          </CTabContent>
        </CCardBody>
      </CCard>
    </div>
  );
};
