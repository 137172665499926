import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardHeader,
  CCardBody,
  CSmartTable,
  CButton,
  CTabContent,
  CBadge,
  CRow,
  CCol,
} from "@coreui/react-pro";

import * as MarketsmsService from "../../services/MarketsmsService";
import {
  customMessage,
  getBadge,
  showToastTC,
  showToastTR
} from "../../Utils/BaseApp";
import {
  GroupsItems
} from "../../models/Groups";
import CIcon from "@coreui/icons-react";
import { Link, useParams } from "react-router-dom";
import {
  cilChevronCircleRightAlt,
  cilChevronCircleLeftAlt,
  cilSettings,
  cilTrash
} from "@coreui/icons";
import { BIcon } from "../../components/icons/BIcon";
import { ContactsItems } from "../../models/Contacts";
import { Item } from "@coreui/react-pro/dist/components/smart-table/types";

interface GroupsProps { }

export const GroupManage: React.FC<GroupsProps> = ({ }: GroupsProps) => {
  const [groups, setGroups] = useState<GroupsItems[]>([]);
  const [contacts, setContacts] = useState<ContactsItems[]>([]);
  const [contactsIds, setContactsIds] = useState<number[]>([]);
  const [selectedContact, setSelectedContact] = useState<Item | null>(null);
  const [groupContacts, setGroupContacts] = useState<ContactsItems[]>([]);
  const [groupContactsLoading, setGroupContactsLoading] = useState(true);
  const [contactsLoading, setContactsLoading] = useState(true);

  const { id } = useParams();
  const group_id = parseInt(id + "");

  const getContactsByGroupId = async () => {
    var response = await MarketsmsService.getContactsByGroupId(group_id);
    if (response) {
      if (response.status === 200) {
        setGroupContactsLoading(false);
        setGroupContacts(response.data.items);
      }
    }
  };

  const getContactsByAccount = async () => {

    var response = await MarketsmsService.getContactsByAccount();
    if (response) {
      if (response.status === 200) {
        setContactsLoading(false);

        const allContacts = response.data.items;
        // Filtrar los contactos que ya están en el grupo
        const filteredContacts = allContacts.filter(
          (contact) =>
            !groupContacts.some(
              (groupContact) => groupContact.id === contact.id
            )
        );
        // Actualizar el estado contacts con los contactos filtrados
        setContacts(filteredContacts);
      }
    }
  };

  const addContactsToGroup = async () => {
    console.log(contactsIds)

    if (contactsIds.length > 0) {
      const body = { contacts: contactsIds };
      const response = await MarketsmsService.addContactsToGroup(group_id, body);
      if (response) {
        if (response.status === 200) {
          getContactsByGroupId();

          showToastTR(customMessage(response.code_app), 200, "success");
        } else {
          showToastTR(customMessage(response.code_app), 2000, "error");
        }
      }
      setContactsIds([]);
      // setSelectedRowContact(null);
    } else {
      showToastTC(customMessage('ERROR_USER_EMPTY_LIST_CONTACT_FOR_ADD'), 1000, "error");

    }

  };

  const deleteContactsFromGroup = async () => {
    if (selectedContact?.id) {
      console.log(selectedContact)
      console.log(selectedContact?.id)

      const response = await MarketsmsService.deleteContactFromGroup(group_id, selectedContact.id);
      if (response) {
        if (response.status === 200) {

          getContactsByGroupId();
          showToastTR(customMessage(response.code_app), 200, "success");
        } else {
          showToastTR(customMessage(response.code_app), 2000, "error");
        }
      }
      setSelectedContact(null)
    } else {
      showToastTC(customMessage('ERROR_USER_EMPTY_CONTACT_FOR_DELETE'), 1000, "error");
    }
  };
  const columns = [
    {
      key: "id",
      _style: { width: "5%" },
    },
    {
      key: "number",
      label: "Número",
      _style: { width: "20%" },
    },
    {
      key: "first_name",
      label: "Nombre",
      _style: { width: "20%" },
    },
    {
      key: "last_name",
      label: "Apellido",
      _style: { width: "25%" },
    },
    {
      key: "birthday",
      label: "Cumpleaños",
      _style: { width: "30%" },
    },
  ];

  useEffect(() => {
    getGroupById(group_id);
    getContactsByGroupId();
  }, []);


  useEffect(() => {
    console.log(groupContacts.length)
    if (groupContacts.length > 0) {
      console.log("Va a traer los contactos de la cuenta")
      getContactsByAccount();
    } else {
      console.log("Todavia no púeda llamar todos los contactos")
    }
  }, [groupContacts]);

  const getGroupById = async (id: number) => {
    var response = await MarketsmsService.getGroupById(id);
    if (response) {
      if (response.status === 200) {
        setGroups(response.data.items);
        console.log("groups")
      }
    }
  };

  return (
    <div className="col-12">
      <CCard className="mb-4">
        <CCardHeader className="p-0 d-flex align-items-center ps-1">
          <Link to={'/groups'} >
            <BIcon icon="arrow-left-short" className="fs-3 me-1 pt-2 text-danger cursor-pointer" />
          </Link>
          <strong>Gestionar Grupo {groups[0] ? groups[0].name : ''}</strong>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol md={5}>
              <CTabContent className="rounded-bottom mt-4">
                <CSmartTable
                  // activePage={2}
                  // itemsPerPageLabel={"hola mundo"}
                  itemsNumber={34}

                  cleaner
                  clickableRows
                  columns={columns}
                  columnFilter
                  columnSorter
                  // footer
                  items={contacts}

                  // items={contacts.filter(
                  //   (contact) =>
                  //     !groupContacts.some(
                  //       (groupContact) => groupContact.id === contact.id
                  //     )
                  // )}

                  // onRowClick={(item) => {
                  //   setSelectedRowContact(item);
                  // }}
                  loading={contactsLoading}


                  // selected={selectedRowContact ? [selectedRowContact] : []}
                  itemsPerPageSelect
                  itemsPerPage={5}
                  pagination
                  // onFilteredItemsChange={(items) => {
                  //   // console.log(items)
                  // }}
                  onSelectedItemsChange={(items) => {
                    // console.log(items);
                    //
                    // const contactIds = items.map(item => item.id);
                    // console.log(contactIds);

                    setContactsIds(items.map((item) => item.id));
                    // console.log(first)

                    console.log(items.map((item) => item.id));
                    // setSelectedRowContact(items);

                    // setContactsIds(items);
                  }}
                  // scopedColumns={{}}
                  scopedColumns={{
                    status_name: (contact: ContactsItems) => (
                      <td>
                        <CBadge color={getBadge(contact.status_id)}>
                          {contact.status_name}
                        </CBadge>
                      </td>
                    ),
                    // birthday: (contact: ContactsItems) => (
                    //   <td>
                    //     {contact.birthday && (
                    //       <>
                    //         <CIcon
                    //           icon={cilBirthdayCake}
                    //           className="text-primary me-3"
                    //         />
                    //         {contact.birthday}
                    //       </>
                    //     )}
                    //   </td>
                    // ),
                  }}
                  selectable
                  sorterValue={{ column: "id", state: "asc" }}
                  tableFilter
                  tableProps={{
                    className: "add-this-class",
                    responsive: true,
                    striped: true,
                    hover: true,
                  }}
                  tableBodyProps={{
                    className: "align-middle",
                  }}
                />
              </CTabContent>
            </CCol>

            <CCol
              md={1}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="text-center ">
                <CIcon
                  onClick={addContactsToGroup}
                  icon={cilChevronCircleRightAlt}
                  className="text-primary me-3 fs-1 text-success cursor-pointer my-1"
                  size="3xl"
                  title="Agregar contacto(s) al grupo"
                />
                <CIcon
                  onClick={deleteContactsFromGroup}
                  icon={cilChevronCircleLeftAlt}
                  className="text-primary me-3 text-danger cursor-pointer my-1"
                  size="3xl"
                  title="Retirar contacto(s) del grupo"
                />
              </div>
            </CCol>
            <CCol md={6}>
              <CTabContent className="rounded-bottom mt-4">
                <CSmartTable
                  // activePage={2}
                  cleaner
                  clickableRows
                  columns={columns}
                  columnFilter
                  columnSorter
                  // footer
                  items={groupContacts}
                  itemsPerPageSelect
                  itemsPerPage={5}
                  pagination
                  loading={groupContactsLoading}

                  selectAll={false}


                  // onRowClick={(item: ContactsItems) => {
                  //   console.log(item)
                  //   console.log(item.id)
                  //   // setContactSelected(item);
                  //   setContactId(item)
                  // }}
                  onRowClick={(item) => {
                    console.log(item);
                    console.log(item.id);

                    // Actualizar el contacto seleccionado
                    setSelectedContact(item);
                  }}

                  selected={selectedContact ? [selectedContact] : []} // Pass the selected item as an array


                  // selected={contactSelected}

                  // onFilteredItemsChange={(items) => {
                  //   // console.log(items)
                  // }}
                  // onSelectedItemsChange={(items) => {
                  //   if (items && items.length > 0) {
                  //     setContactId(items[0].id);
                  //   } else {
                  //     // No se ha seleccionado nada, puedes manejarlo según tus necesidades
                  //     // setContactId(null);
                  //   }
                  // }}
                  // scopedColumns={{}}

                  scopedColumns={{
                    // selected: [67],
                    status_name: (contact: ContactsItems) => (
                      <td>
                        <CBadge color={getBadge(contact.status_id)}>
                          {contact.status_name}
                        </CBadge>
                      </td>
                    ),
                    // birthday: (contact: ContactsItems) => (
                    //   <td>
                    //     {contact.birthday && (
                    //       <>
                    //         <CIcon
                    //           icon={cilBirthdayCake}
                    //           className="text-primary me-3"
                    //         />
                    //         {contact.birthday}
                    //       </>
                    //     )}
                    //   </td>
                    // ),
                    operations: (groups: GroupsItems) => {
                      return (
                        <td className="py-2 text-center">
                          <CButton
                            color="primary"
                            variant="outline"
                            shape="square"
                            size="sm"
                          >
                            <CIcon icon={cilSettings} />
                          </CButton>
                          <Link to={"/group/" + groups.id}>
                            <CButton
                              color="warning"
                              variant="outline"
                              shape="square"
                              size="sm"
                              className="mx-1"
                            >
                              <BIcon icon={"eye"} />
                            </CButton>
                          </Link>

                          <CButton
                            color="danger"
                            variant="outline"
                            shape="square"
                            size="sm"
                          >
                            <CIcon icon={cilTrash} />
                          </CButton>
                        </td>
                      );
                    },
                  }}
                  selectable
                  sorterValue={{ column: "id", state: "asc" }}
                  tableFilter
                  tableProps={{
                    className: "add-this-class",
                    responsive: true,
                    striped: true,
                    hover: true,
                  }}
                  tableBodyProps={{
                    className: "align-middle",
                  }}
                />
              </CTabContent>
            </CCol>
          </CRow>

          {/* <div className="position-fixed end-0 bottom-0 p-3">
            <CIcon
              onClick={addContactsToGroup}
              icon={cilChevronCircleRightAlt}
              className="text-primary me-3 fs-1 text-success cursor-pointer my-1"
              size="3xl"
              title="Agregar contacto(s) al grupo"
            />
            <CIcon
              onClick={deleteContactsFromGroup}
              icon={cilChevronCircleLeftAlt}
              className="text-primary me-3 text-danger cursor-pointer my-1"
              size="3xl"
              title="Retirar contacto(s) del grupo"
            />
          </div> */}

          {/* <div className="position-fixed top-50 start-50 translate-middle">
    <CIcon
      onClick={addContactsToGroup}
      icon={cilChevronCircleRightAlt}
      className="text-primary me-3 fs-1 text-success cursor-pointer my-1"
      size="3xl"
      title="Agregar contacto(s) al grupo"
    />
    <CIcon
      onClick={deleteContactsFromGroup}
      icon={cilChevronCircleLeftAlt}
      className="text-primary me-3 text-danger cursor-pointer my-1"
      size="3xl"
      title="Retirar contacto(s) del grupo"
    />
  </div>  */}
        </CCardBody>
      </CCard>
    </div>
  );
};
