import axios from "axios"
import { PlanGetModel, PlansGetModel } from "../models/Plans"
import { env } from "../Utils/Environment"
import { AccountsGetModel, AccountsPostModel, UploadLogoPostModel } from "../models/Accounts"
import { ApiKeyPutModel } from "../models/ApiKey"
import { IpDeleteModel, IpPostModel } from "../models/Ip"
import { ExecuteTransactionPostModel, FinancialInstitutionsGetModel, PaymentSourcesGetModel, PaymentsResponseModel, TransactionGetModel, TransactionsHistoryGetModel } from "../models/Payments"
import { UserGetModel, UsersGetModel, UsersPostModel } from "../models/Users"
import { MessagesGetModel, MessagesPostModel } from "../models/Messages"
import { getCurrentAccount, getCurrentAccountId, showToastTC } from "../Utils/BaseApp"
import { CampaignsGetModel, CampaignsPostModel } from "../models/Campaigns"
import { GroupsGetModel, GroupsPostModel } from "../models/Groups"
import { ContactsGetModel, ContactsPostModel } from "../models/Contacts"
import { ErrorCodesGetModel } from "../models/ErrorCodes"
import { ProvidersGetModel } from "../models/Providers"
import { AuthPostModel, LogoutModel } from "../models/Auth"
import { getBaseToken, getToken, userLogout } from "../Utils/Auth"
import { TrafficGetModel } from "../models/Traffic"
import { ConversationsGetModel } from "../models/Conversations"
import { ConversationMessagesGetModel } from "../models/ConversationMessages"
import { WhatsappTemplateMediaPostModel, WhatsappTemplatesCreateModel, WhatsappTemplatesCreatePostModel, WhatsappTemplatesGetModel, WhatsappTemplatesModel } from "../models/WhatsappTemplates"
import { WhatsappPhoneNumbersAutomationsGetModel, WhatsappPhoneNumbersGetModel, WhatsappPhoneNumbersPostModel } from "../models/WhatsappNumbers"
import { WhatsappMessagesGetModel } from "../models/WhatsappMessages"

const API = env('MARKETSMS_ENDPOINT')
// const API_WEB = env('MARKETSMS_ENDPOINT_WEB')
const API_WEB = 'https://api.marketsms.co/web/';

const getHeadersAuth = (content_type?: string) => {
    const headers = {
        'Content-Type': content_type ?? 'application/json',
        'Device-Id': localStorage.getItem('device_id'),
        'Authorization': 'Bearer ' + getToken(),
    }
    return headers;
}


const getHeadersAdminAuth = () => {
    const headers = {
        'Content-Type': 'application/json',
        'Device-Id': localStorage.getItem('device_id'),
        'Authorization': 'Bearer ' + getBaseToken(),
    }
    return headers;
}

// const getHeadersApiKey = async (content_type?: string) => {
//     const response = await getCurrentAccount();
//     const headers = {
//         'Content-Type': content_type ?? 'application/json',
//         'Api-Key': response ? response.api_key : ''
//     };

//     if (!response) {
//         showToastTC('Para realizar este proceso debes seleccionar una cuenta por defecto', 1000, 'error');
//         setTimeout(() => {
//             window.location.href = '/user/administration/accounts';
//         }, 1000);
//     }
//     return headers;
// };

export const getPlansByStatus = async (): Promise<PlansGetModel> => {
    try {
        var response = await axios.get(API + 'plans/1', { headers: getHeadersAuth() })
        return response.data[0];

    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const getPlansByAccount = async (account_id: number): Promise<PlanGetModel> => {
    try {
        var response = await axios.get(API + 'plan/account/' + account_id, { headers: getHeadersAuth() })
        return response.data[0];

    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const getPaymentMethods = async (): Promise<PaymentsResponseModel> => {
    try {
        var response = await axios.get(API + 'payment/methods', { headers: getHeadersAuth() })
        return response.data[0];

    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const signUp = async (body: Object) => {
    try {
        const response = await axios.post(API + 'user', body, { headers: getHeadersAuth() });
        return response.data[0];
    } catch (error: any) {
        if (error.response && error.response.status === 400) {
            return error.response.data[0];
        } else {
            throw error;
        }
    }
}

export const changePassword = async (user_id: number, body: Object): Promise<UsersPostModel> => {
    try {
        const response = await axios.put(API + 'user/update/' + user_id, body, { headers: getHeadersAuth() });
        return response.data[0];
    } catch (error: any) {
        if (error.response && error.response.status === 400) {
            return error.response.data[0];
        } else {
            throw error;
        }
    }
}

export const sendCode = async (body: Object) => {
    try {
        const response = await axios.post(API + 'code/send', body, { headers: getHeadersAuth() });
        return response.data[0];
    } catch (error: any) {
        if (error.response && error.response.status === 400) {
            return error.response.data[0];
        } else {
            throw error;
        }
    }
}

export const verificationCode = async (body: Object) => {
    try {
        const response = await axios.post(API + 'code/verification', body, { headers: getHeadersAuth() });
        return response.data[0];
    } catch (error: any) {
        if (error.response && error.response.status === 400) {
            return error.response.data[0];
        } else {
            throw error;
        }
    }
}

export const login = async (body: Object): Promise<AuthPostModel> => {
    try {
        const response = await axios.post(API + 'user/login', body, { headers: getHeadersAuth() });
        return response.data[0];
    } catch (error: any) {
        // return processErrorResponse(error)
        if (error.response && (error.response.status === 400 || error.response.status === 401 || error.response.status === 500)) {
            return error.response.data[0];
        } else {
            throw error;
        }
    }
}


export const logout = async (): Promise<LogoutModel> => {
    try {
        const response = await axios.post(API + 'user/logout', {}, { headers: getHeadersAuth() });
        return response.data[0];
    } catch (error: any) {
        // return processErrorResponse(error)
        if (error.response && (error.response.status === 400 || error.response.status === 401 || error.response.status === 500)) {
            console.log("Entra a 148");
            return error.response.data[0];
        } else {
            console.log("Entra a 151");
            throw error;
        }
    }
}

export const uploadLogo = async (id: number, logo: File): Promise<UploadLogoPostModel> => {
    try {
        const formData = new FormData();
        formData.append('logo', logo);
        const response = await axios.post(API + 'account/' + id + '/logo', formData, { headers: getHeadersAuth('multipart/form-data') });
        return response.data[0];
    } catch (error: any) {
        // return processErrorResponse(error)
        if (error.response && (error.response.status === 400 || error.response.status === 401 || error.response.status === 500)) {
            return error.response.data[0];
        } else {
            throw error;
        }
    }
}


export const contactsImport = async (file: File): Promise<UploadLogoPostModel> => {
    try {
        const headers = await getHeadersAuth('multipart/form-data');
        const account_id = await getCurrentAccountId();

        const formData = new FormData();
        formData.append('contacts', file); // Asegúrate de que el nombre del campo sea 'contacts'

        const response = await axios.post(API_WEB + 'contacts/import?account_id=' + account_id, formData, { headers });

        return response.data[0];
    } catch (error: any) {
        if (error.response && (error.response.status === 400 || error.response.status === 401 || error.response.status === 500)) {
            return error.response.data[0];
        } else {
            throw error;
        }
    }
}




export const loginAsUser = async (id: number): Promise<AuthPostModel> => {
    try {
        const response = await axios.post(API + 'loginasuser/' + id, {}, { headers: getHeadersAdminAuth() });
        return response.data[0];
    } catch (error: any) {
        // return processErrorResponse(error)
        if (error.response && (error.response.status === 400 || error.response.status === 401 || error.response.status === 500)) {
            return error.response.data[0];
        } else {
            throw error;
        }
    }
}

export const refreshToken = async () => {
    try {
        const response = await axios.post(API + 'user/token/refresh', {}, { headers: getHeadersAuth() });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const refreshBaseToken = async () => {
    try {
        var headers = getHeadersAuth();
        headers['Authorization'] = getBaseToken()
        const response = await axios.post(API + 'user/token/refresh', {}, { headers: headers });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const getAccountsByUser = async (): Promise<AccountsGetModel> => {
    try {
        const response = await axios.get(API + 'accounts/user', { headers: getHeadersAuth() });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const createAccount = async (body: Object): Promise<AccountsPostModel> => {
    try {
        const response = await axios.post(API + 'account', body, { headers: getHeadersAuth() });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const refreshApiKey = async (id: number): Promise<ApiKeyPutModel> => {
    try {
        const response = await axios.put(API + 'account/apikey/' + id, null, { headers: getHeadersAuth() });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const removeIpFromAccount = async (account_id: number, ip: string): Promise<IpDeleteModel> => {
    try {
        const response = await axios.delete(API + 'account/' + account_id + '/deleteip?ip=' + ip, { headers: getHeadersAuth() });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const addIpToAccount = async (account_id: number, body: Object): Promise<IpPostModel> => {
    try {
        const response = await axios.post(API + 'account/' + account_id + '/addip', body, { headers: getHeadersAuth() });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const executeTransaction = async (method_id: number, account_id: number, body: Object): Promise<ExecuteTransactionPostModel> => {
    try {
        const response = await axios.post(API + 'payment/transaction/' + method_id + '/' + account_id, body, { headers: getHeadersAuth() });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const getTransactionById = async (method_id: number, transaction_id: string): Promise<TransactionGetModel> => {
    try {
        const response = await axios.get(API + 'payment/transaction/' + method_id + '/' + transaction_id, { headers: getHeadersAuth() });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const getCurrentUser = async (): Promise<UserGetModel> => {
    try {
        const response = await axios.get(API + 'user/current', { headers: getHeadersAuth() });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const getPaymentSourcesByPaymentMethod = async (method_id: number): Promise<PaymentSourcesGetModel> => {
    try {
        const response = await axios.get(API + 'payment/sources/' + method_id, { headers: getHeadersAuth() });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const getFinancialInstitutionsPse = async (method_id: number): Promise<FinancialInstitutionsGetModel> => {
    try {
        const response = await axios.get(API + 'payment/pse/financialinstitutions/' + method_id, { headers: getHeadersAuth() });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const getTransactions = async (): Promise<TransactionsHistoryGetModel> => {
    try {
        const response = await axios.get(API + 'transactions', { headers: getHeadersAuth() });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const getTransactionsByAccount = async (): Promise<TransactionsHistoryGetModel> => {
    try {
        const headers = await getHeadersAuth('multipart/form-data');
        const account_id = await getCurrentAccountId();

        const response = await axios.get(API_WEB + 'transactions/account?account_id=' + account_id, { headers });
        return response.data[0];
    } catch (error: any) {
        if (error.response && [400, 401, 500].includes(error.response.status)) {
            return error.response.data[0];
        } else {
            throw error;
        }
    }
}

export const getMessages = async (): Promise<MessagesGetModel> => {
    try {
        const response = await axios.get(API + 'messages', { headers: getHeadersAuth() });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const getMessagesByAccount = async (): Promise<MessagesGetModel> => {
    try {
        const headers = await getHeadersAuth('multipart/form-data');
        const account_id = await getCurrentAccountId();

        const response = await axios.get(API_WEB + 'messages/account?account_id=' + account_id, { headers });
        return response.data[0];
    } catch (error: any) {
        if (error.response && [400, 401, 500].includes(error.response.status)) {
            return error.response.data[0];
        } else {
            throw error;
        }
    }
};

export const sendMessage = async (body: Object): Promise<MessagesPostModel> => {
    try {
        const headers = await getHeadersAuth('multipart/form-data');
        const account_id = await getCurrentAccountId();

        const response = await axios.post(API_WEB + 'message/send?account_id=' + account_id, body, { headers });
        return response.data[0];
    } catch (error: any) {
        if (error.response && [400, 401, 500].includes(error.response.status)) {
            return error.response.data[0];
        } else {
            throw error;
        }
    }
};

export const getCampaigns = async (): Promise<CampaignsGetModel> => {
    try {
        const response = await axios.get(API + 'campaigns', { headers: getHeadersAuth() });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const getCampaignsByAccount = async (service: number): Promise<CampaignsGetModel> => {
    try {
        const headers = await getHeadersAuth('multipart/form-data');
        const account_id = await getCurrentAccountId();

        const response = await axios.get(API_WEB + 'campaigns/account/service/' + service + '?account_id=' + account_id, { headers });
        return response.data[0];
    } catch (error: any) {
        if (error.response && [400, 401, 500].includes(error.response.status)) {
            return error.response.data[0];
        } else {
            throw error;
        }
    }
};

export const getGroups = async (): Promise<GroupsGetModel> => {
    try {
        const response = await axios.get(API + 'groups', { headers: getHeadersAuth() });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const getGroupsByAccoount = async (): Promise<GroupsGetModel> => {
    try {
        const headers = await getHeadersAuth('multipart/form-data');
        const account_id = await getCurrentAccountId();

        const response = await axios.get(API_WEB + 'groups/account?account_id=' + account_id, { headers });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
};

export const getActiveGroupsByAccoount = async (): Promise<GroupsGetModel> => {
    try {
        const headers = await getHeadersAuth('multipart/form-data');
        const account_id = await getCurrentAccountId();

        const response = await axios.get(API_WEB + 'groups/active/account?account_id=' + account_id, { headers });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
};

export const getGroupById = async (id: number): Promise<GroupsGetModel> => {
    try {
        const headers = await getHeadersAuth('multipart/form-data');
        const account_id = await getCurrentAccountId();

        const response = await axios.get(API_WEB + 'group/' + id + '?account_id=' + account_id, { headers });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const getContactsByGroupId = async (id: number): Promise<ContactsGetModel> => {
    try {
        const headers = await getHeadersAuth('multipart/form-data');
        const account_id = await getCurrentAccountId();

        const response = await axios.get(API_WEB + 'group/' + id + '/contacts?account_id=' + account_id, { headers });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const getMessagesByCampaign = async (campaign_id: number): Promise<MessagesGetModel> => {
    try {
        const headers = await getHeadersAuth('multipart/form-data');
        const account_id = await getCurrentAccountId();

        const response = await axios.get(API_WEB + 'campaign/' + campaign_id + '/messages?account_id=' + account_id, { headers });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
};

export const sendCampaingToGroups = async (body: Object): Promise<CampaignsPostModel> => {
    try {
        const headers = await getHeadersAuth('multipart/form-data');
        const account_id = await getCurrentAccountId();

        const response = await axios.post(API_WEB + 'campaign/groups', body, { headers });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const getContactsByAccount = async (): Promise<ContactsGetModel> => {
    try {
        const headers = await getHeadersAuth();
        const account_id = await getCurrentAccountId();
        const response = await axios.get(API_WEB + 'contacts/account?account_id=' + account_id, { headers });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
};

export const getContactsFilter = async (params: string): Promise<ContactsGetModel> => {
    try {
        const headers = await getHeadersAuth('multipart/form-data');
        const account_id = await getCurrentAccountId();

        const response = await axios.get(API_WEB + 'contacts/filter?' + params + '&account_id=' + account_id, { headers });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
};

export const createGroupByFilter = async (body: Object): Promise<GroupsPostModel> => {
    try {
        const headers = await getHeadersAuth('multipart/form-data');
        const account_id = await getCurrentAccountId();

        const response = await axios.post(API_WEB + 'group/filter?account_id=' + account_id, body, { headers });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const createGroup = async (body: Object): Promise<GroupsPostModel> => {
    try {
        const headers = await getHeadersAuth('multipart/form-data');
        const account_id = await getCurrentAccountId();
        const response = await axios.post(API_WEB + 'group?account_id=' + account_id, body, { headers });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const addContacts = async (body: Object): Promise<ContactsPostModel> => {
    try {
        const headers = await getHeadersAuth('multipart/form-data');
        const account_id = await getCurrentAccountId();

        const response = await axios.post(API_WEB + 'contacts?account_id=' + account_id, body, { headers });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const deleteContact = async (contact_id: number): Promise<CampaignsPostModel> => {
    try {
        const headers = await getHeadersAuth('multipart/form-data');
        const account_id = await getCurrentAccountId();

        const response = await axios.delete(API_WEB + 'contact/' + contact_id + '?account_id=' + account_id, { headers });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const updateGroupStatus = async (group_id: number, status: string): Promise<CampaignsPostModel> => {
    try {
        const headers = await getHeadersAuth('multipart/form-data');
        const account_id = await getCurrentAccountId();

        const response = await axios.put(API + 'group/' + group_id + "/" + status, {}, { headers });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const deleteContactFromGroup = async (group_id: number, contact_id: number): Promise<GroupsPostModel> => {
    try {
        const headers = await getHeadersAuth('multipart/form-data');
        const account_id = await getCurrentAccountId();

        const response = await axios.delete(API_WEB + 'group/' + group_id + '/contact/' + contact_id + '?account_id=' + account_id, { headers });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const addContactsToGroup = async (group_id: number, body: Object): Promise<ContactsPostModel> => {
    try {
        const headers = await getHeadersAuth('multipart/form-data');
        const account_id = await getCurrentAccountId();

        const response = await axios.post(API_WEB + 'group/' + group_id + '?account_id=' + account_id, body, { headers });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const saveDevice = async (body: Object): Promise<ContactsPostModel> => {
    try {
        // const headers = await getHeadersApiKey();
        const headers = {
            'Content-Type': 'application/json'
        }

        const response = await axios.post(API + 'device', body, { headers });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const getErrorCodes = async (): Promise<ErrorCodesGetModel> => {
    try {
        var response = await axios.get(API + 'errorcodes?page=all', { headers: getHeadersAuth() })
        return response.data[0];

    } catch (error: any) {
        return processErrorResponse(error)
    }
}

// Dashboard
export const getTraficMessagesByUser = async (range: string): Promise<TrafficGetModel> => {
    try {
        var response = await axios.get(API + 'message/trafic/' + range + "/user", { headers: getHeadersAuth() })
        return response.data[0];

    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const getTraficMessagesByAccount = async (range: string): Promise<TrafficGetModel> => {
    try {
        const headers = await getHeadersAuth('multipart/form-data');
        const account_id = await getCurrentAccountId();
        var response = await axios.get(API_WEB + 'message/trafic/' + range + "/account?account_id=" + account_id, { headers: headers })
        return response.data[0];

    } catch (error: any) {
        return processErrorResponse(error)
    }
}


// Apis administrativas
export const getUsers = async (): Promise<UsersGetModel> => {
    try {
        var response = await axios.get(API + 'users', { headers: getHeadersAdminAuth() })
        return response.data[0];

    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const getAccounts = async (): Promise<AccountsGetModel> => {
    try {
        var response = await axios.get(API + 'accounts', { headers: getHeadersAdminAuth() })
        return response.data[0];

    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const getProviders = async (): Promise<ProvidersGetModel> => {
    try {
        var response = await axios.get(API + 'providers', { headers: getHeadersAdminAuth() })
        return response.data[0];

    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const getPlans = async (): Promise<PlansGetModel> => {
    try {
        var response = await axios.get(API + 'plans', { headers: getHeadersAdminAuth() })
        return response.data[0];

    } catch (error: any) {
        return processErrorResponse(error)
    }
}

// WhatsApp
export const getConversations = async (local_phone_number_id: number, account_id: number): Promise<ConversationsGetModel> => {
    try {
        var response = await axios.get(API_WEB + 'whatsapp/conversations/' + local_phone_number_id + '?account_id=' + account_id, { headers: getHeadersAuth() })
        return response.data[0];

    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const getConversationMessages = async (local_phone_number_id: number, local_conversation_id: number, account_id: number): Promise<ConversationMessagesGetModel> => {
    try {
        var response = await axios.get(API_WEB + 'whatsapp/conversations/' + local_phone_number_id + "/" + local_conversation_id + '?account_id=' + account_id, { headers: getHeadersAuth() })
        return response.data[0];

    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const sendIndividualMessage = async (local_conversation_id: number, account_id: number, message: string): Promise<ConversationMessagesGetModel> => {
    try {
        var response = await axios.post(API_WEB + 'whatsapp/conversation/' + local_conversation_id + "/message?account_id=" + account_id, { message: message }, { headers: getHeadersAuth() })
        return response.data[0];

    } catch (error: any) {
        return processErrorResponse(error)
    }
}

function processErrorResponse(error: any) {
    console.log(error);
    // console.log(error.response);
    console.log("Start Status ");
    console.log(error.response.status);
    console.log("End Status ");
    if (error.response && (error.response.status === 400 || error.response.status === 401 || error.response.status === 500)) {
        if (error.response.status === 401) {
            console.log(error);
            userLogout()
        } else {
            return error.response.data[0];
        }
    } else {
        throw error;
    }
}

// WhatsApp
export const uploadMediaTemplate = async (media: File, account_id: number, name?: string | null): Promise<WhatsappTemplateMediaPostModel> => {
    try {
        const formData = new FormData();
        formData.append('media', media);
        console.log(name);
        if (name) {
            formData.append('name', name);
        }
        const response = await axios.post(API_WEB + 'whatsapp/template/media?account_id=' + account_id, formData, { headers: getHeadersAuth('multipart/form-data') });
        return response.data[0];
    } catch (error: any) {
        // return processErrorResponse(error)
        if (error.response && (error.response.status === 400 || error.response.status === 401 || error.response.status === 500)) {
            return error.response.data[0];
        } else {
            throw error;
        }
    }
}

export const createTemplate = async (body: WhatsappTemplatesCreateModel, phone_number_id: number, account_id: number,): Promise<WhatsappTemplatesCreatePostModel> => {
    try {
        const httpClient = axios.create({
            timeout: 15000, // Tiempo de espera en milisegundos (15 segundos en este ejemplo)
        });
        var response = await httpClient.post(API_WEB + 'whatsapp/template/' + phone_number_id + '?account_id=' + account_id, body, { headers: getHeadersAuth() })
        return response.data[0];

    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const getTemplates = async (account_id: number, phone_number_id: number): Promise<WhatsappTemplatesGetModel> => {
    try {
        var response = await axios.get(API_WEB + 'whatsapp/templates/' + phone_number_id + '?account_id=' + account_id, { headers: getHeadersAuth() })
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}


export const getActiveTemplates = async (account_id: number, phone_number_id: number): Promise<WhatsappTemplatesGetModel> => {
    try {
        var response = await axios.get(API_WEB + 'whatsapp/templates/' + phone_number_id + '/active?account_id=' + account_id, { headers: getHeadersAuth() })
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const sendWhatsappCampaingToGroups = async (body: Object, phone_number_id: number): Promise<CampaignsPostModel> => {
    try {
        // const headers = await getHeadersAuth();
        const account_id = await getCurrentAccountId();

        const response = await axios.post(API_WEB + 'whatsapp/campaign/groups/phone/' + phone_number_id + '?account_id=' + account_id, body, { headers: getHeadersAuth() });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const getWhatsappMessagesByCampaign = async (phone_number_id: number, campaign_id: number): Promise<WhatsappMessagesGetModel> => {
    try {
        const headers = await getHeadersAuth('multipart/form-data');
        const account_id = await getCurrentAccountId();

        const response = await axios.get(API_WEB + 'whatsapp/phone/' + phone_number_id + '/campaign/' + campaign_id + '/messages?account_id=' + account_id, { headers });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
};



export const getWhatsappNumbers = async (): Promise<WhatsappPhoneNumbersGetModel> => {
    try {
        // const headers = await getHeadersAuth();
        const account_id = await getCurrentAccountId();
        const response = await axios.get(API_WEB + 'whatsapp/numbers?account_id=' + account_id, { headers: getHeadersAuth() });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}


export const setDefaultWhatsappNumber = async (phone_number_id: number): Promise<WhatsappPhoneNumbersPostModel> => {
    try {
        // const headers = await getHeadersAuth();
        const account_id = await getCurrentAccountId();

        const response = await axios.put(API_WEB + 'whatsapp/numbers/' + phone_number_id + '/default?account_id=' + account_id, {}, { headers: getHeadersAuth() });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}


export const integrateCommerce = async (body: Object, account_id: number): Promise<CampaignsPostModel> => {
    try {
        // const headers = await getHeadersAuth();
        const account_id = await getCurrentAccountId();

        const response = await axios.post(API_WEB + 'whatsapp/integrate?account_id='+account_id, body, { headers: getHeadersAuth() });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const getPhoneAutomations = async (phone_number_id: number): Promise<WhatsappPhoneNumbersAutomationsGetModel> => {
    try {
        const account_id = await getCurrentAccountId();

        const response = await axios.get(API_WEB + 'whatsapp/phone/'+phone_number_id+'/automations?account_id='+account_id, { headers: getHeadersAuth() });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}



export const saveConversationalComponents = async (body: Object, phone_number_id: number): Promise<CampaignsPostModel> => {
    try {
        const account_id = await getCurrentAccountId();

        const response = await axios.post(API_WEB + 'whatsapp/phone/'+phone_number_id+'/automations?account_id='+account_id, body, { headers: getHeadersAuth() });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}