import React, { useEffect, useState } from "react";
import {
    CCard,
    CCardHeader,
    CCardBody,
    CSmartTable,
    CButton,
    CTabContent,
    CBadge,
} from "@coreui/react-pro";
import { ContactsItems } from "../../models/Contacts";
import CIcon from "@coreui/icons-react";
import { cilPlus, cilTrash } from "@coreui/icons";
import { BIcon } from "../../components/icons/BIcon";

import {
    getBadgeWhatsappTemplateStatus,
    getCurrentAccount,
    getDefaultPhoneNumber,
    showToastTC,
} from "../../Utils/BaseApp";
import { WhatsappTemplatesItemsModel } from "../../models/WhatsappTemplates";
import { Link, useNavigate } from "react-router-dom";
import * as MarketsmsService from "../../services/MarketsmsService";

interface TemplatesProps { }

export const Templates: React.FC<TemplatesProps> = ({ }: TemplatesProps) => {
    const [templates, setTemplates] = useState<WhatsappTemplatesItemsModel[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        getCurrentAccount().then((account) => {
            if (account) {
                const phone_number_id = getDefaultPhoneNumber();
                if (phone_number_id != null) {
                    MarketsmsService.getTemplates(account.id, phone_number_id).then((response) => {
                        if (response.status == 200) {
                            setTemplates(response.data.items);
                        }
                    })
                } else {
                    showToastTC("No contiene un numero de telefono por defecto, seleccionalo por favor", 5000, "error");
                    setTimeout(() => {
                        navigate("/whatsapp/numbers");
                    }, 5000);
                }
            }
        })
    }, []);

    const columns = [
        {
            key: "id",
            _style: { width: "5%" },
        },
        {
            key: "name",
            label: "Nombre",
            _style: { width: "30%" },
        },
        {
            key: "category_name",
            label: "Categoria",
            _style: { width: "25%" },
        },
        {
            key: "language",
            label: "Idioma",
            _style: { width: "20%" },
        },
        {
            key: "status_id",
            label: "Estado",
            _style: { width: "20%" },
        },
        // {
        //     key: "operations",
        //     label: "Acción",
        //     _style: { width: "20%" },
        // },
    ];

    return (
        <div className="col-12">
            <CCard className="mb-4">
                <CCardHeader>
                    <strong>Plantillas</strong>
                </CCardHeader>
                <CCardBody>

                    <Link to={"/whatsapp/template/create"}>
                        <CButton>
                            <CIcon icon={cilPlus} /> Agregar
                        </CButton>
                    </Link>

                    <CTabContent className="rounded-bottom mt-4">
                        <CSmartTable
                            activePage={2}
                            cleaner
                            clickableRows
                            columns={columns}
                            columnFilter
                            columnSorter
                            loading={templates === null ? true : false}
                            items={templates ?? []}
                            itemsPerPageSelect
                            itemsPerPage={10}
                            pagination
                            itemsNumber={232}
                            onSelectedItemsChange={(items) => {
                                console.log(items);
                            }}
                            scopedColumns={{
                                status_id: (whatsapp_template: WhatsappTemplatesItemsModel) => (
                                    <td>
                                        <CBadge color={getBadgeWhatsappTemplateStatus(whatsapp_template.status_id)}>
                                            {whatsapp_template.status_name}
                                        </CBadge>
                                    </td>
                                ),
                                // operations: (contact: ContactsItems) => {
                                //     return (
                                //         <td className="py-2 text-center">
                                //             <CButton
                                //                 color="info"
                                //                 variant="outline"
                                //                 className="m-1"
                                //                 shape="square"
                                //                 title="Ver más"
                                //                 size="sm"
                                //                 onClick={() => {
                                //                     //   setEditContact(true);
                                //                     //   editDetails(contact);
                                //                     // setVisible(true);
                                //                 }}
                                //             >
                                //                 <BIcon icon="eye" />
                                //             </CButton>
                                //             <CButton
                                //                 color="danger"
                                //                 variant="outline"
                                //                 shape="square"
                                //                 size="sm"
                                //                 className="mx-1"
                                //                 onClick={() => {
                                //                     // setDetailContact(contact);
                                //                     //   deleteContact();
                                //                 }}
                                //             >
                                //                 <CIcon icon={cilTrash} />
                                //             </CButton>
                                //         </td>
                                //     );
                                // },
                            }}
                            selectable
                            // sorterValue={{ column: "id", state: "asc" }}
                            tableFilter
                            tableProps={{
                                className: "add-this-class",
                                responsive: true,
                                striped: true,
                                hover: true,
                            }}
                            tableBodyProps={{
                                className: "align-middle",
                            }}
                        />
                    </CTabContent>
                </CCardBody>
            </CCard>
        </div >
    );
};