import { cilCheckAlt } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardImage,
  CCardText,
  CCardTitle,
  CCol,
  CFormCheck,
  CFormInput,
  CImage,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react-pro";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ExecuteTransactionDataModel,
  FinancialInstitutions,
  PaymentMethods,
  PaymentSources,
  TransactionBody,
  TransactionGetModel,
} from "../../models/Payments";
import * as MarketsmsService from "../../services/MarketsmsService";
import { PlansItems } from "../../models/Plans";
import { getCurrentUser, showToastTC } from "../../Utils/BaseApp";

interface PaymentsProps { }

export const Payments: React.FC<PaymentsProps> = ({ }: PaymentsProps) => {
  const { id } = useParams();

  const [selectMethod, setSelectMethod] = useState(0);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethods[]>([]);
  const [paymentSources, setPaymentSources] = useState<PaymentSources[]>([]);
  const [financialInstitutions, setFinancialInstitutions] = useState<
    FinancialInstitutions[]
  >([]);
  const [postTransaction, setPostTransaction] =
    useState<ExecuteTransactionDataModel>();
  const [getTransaction, setGetTransaction] = useState<TransactionGetModel>();
  const [plan, setPlan] = useState<PlansItems | null>(null);
  const [valuePlan, setValuePlan] = useState(0);

  const [saveSource, setSaveSource] = useState(false);

  const [visibleNequi, setVisibleNequi] = useState(false);
  const [visiblePse, setVisiblePse] = useState(false);

  const [visibleQr, setVisibleQr] = useState("");
  const [visibleCollect, setVisibleCollect] = useState(false);

  const navigate = useNavigate();

  const getPaymentMethods = async () => {
    const response = await MarketsmsService.getPaymentMethods();
    if (response.status === 200) {
      setPaymentMethods(response.data.items);
    }
  };

  const getPlansByAccount = async () => {
    const account_id = parseInt(id + "");
    const response = await MarketsmsService.getPlansByAccount(account_id);
    if (response.status === 200) {
      setValuePlan(response.data.value);
      setPlan(response.data);
    }
  };

  const getPaymentSourcesByPaymentMethod = async (method_id: number) => {
    const response = await MarketsmsService.getPaymentSourcesByPaymentMethod(
      method_id
    );
    if (response.status === 200) {
      setPaymentSources(response.data.items);
    }
  };

  const getFinancialInstitutionsPse = async (method_id: number) => {
    const response = await MarketsmsService.getFinancialInstitutionsPse(
      method_id
    );
    if (response.status === 200) {
      setFinancialInstitutions(response.data.items);
    }
  };

  const proccessTransaction = async (method_id: number) => {
    setSelectMethod(method_id);

    switch (method_id) {
      case 1:
        executeTransaction(1);
        break;
      case 2:
        executeTransaction(2);
        break;
      case 3:
        executeTransaction(3);
        // Pasa directo por que es la misma info
        break;
      case 4:
        setVisibleNequi(true);
        getPaymentSourcesByPaymentMethod(4);
        break;
      case 5:
        setVisiblePse(true);
        getFinancialInstitutionsPse(5);
        break;
      case 6:
        break;
      case 7:
        break;
      case 8:
        executeTransaction(8);
        break;
      case 9:
        executeTransaction(9);
        break;
      default:
        break;
    }
  };

  //   const executeTransactionNequi = async (
  //     method_id: number,
  //     data_add?: Object
  //   ) => {};

  const executeTransaction = async (
    method_id: number,
    data_add?: Record<string, any>
  ) => {
    const account_id = parseInt(id + "");
    // setSelectMethod(method_id);

    let body: TransactionBody = {
      payment_amount: 0, // Asigna un valor inicial o deja como undefined/null según tu lógica
      currency: "",
      customer_email: "",
      customer_full_name: "",
      customer_phone_number: 0,
      payment_description: "",
    };

    const currentUser = await getCurrentUser();
    if (currentUser) {
      if (valuePlan > 0 && plan !== null) {
        body.currency = "COP";
        body.customer_email = currentUser.email;
        body.customer_full_name =
          currentUser.first_name + " " + currentUser.last_name;
        body.customer_phone_number = currentUser.phone_number;
        body.payment_description = "Realizar pago de plan [" + plan.name + "]";

        switch (method_id) {
          case 1:
            body.payment_amount =
              (Number(valuePlan * (19 / 100)) + Number(valuePlan)) * 100;
            body.payment_user_type = "PERSON";
            break;
          case 2:
            body.payment_amount =
              (Number(valuePlan * (19 / 100)) + Number(valuePlan)) * 100;
            break;
          case 3:
            body.payment_amount =
              (Number(valuePlan * (19 / 100)) + Number(valuePlan)) * 100;
            // Pasa directo por que es la misma info
            break;
          case 4:
            body.payment_amount =
              (Number(valuePlan * (19 / 100)) + Number(valuePlan)) * 100;

            if (data_add && data_add.payment_source_id) {
              body.payment_source_id = data_add.payment_source_id;
            } else if (data_add && data_add.payment_phone_number) {
              body.payment_phone_number = data_add.payment_phone_number;
              body.save = saveSource;
            }
            break;
          case 5:
            body.payment_amount =
              (Number(valuePlan * (19 / 100)) + Number(valuePlan)) * 100;
            if (data_add) {
              body.payment_user_type = data_add.payment_user_type;
              // body.payment_identification_type =
              //   data_add.payment_identification_type;
              // body.payment_identification_number =
              //   data_add.payment_identification_number;

              // Para el caso de tomar la información del mismo usuario guardado en DB (users)
              body.payment_identification_type =
                currentUser.identification_type ?? "";
              body.payment_identification_number =
                currentUser.identification_number ?? "";
              body.payment_institution_code = data_add.payment_institution_code;
            }

            break;
          case 6:
            break;
          case 7:
            break;
          case 8:
            body.payment_amount = (Number(valuePlan * (19 / 100)) + Number(valuePlan)) * 100;
            break;
          case 9:
            body.payment_amount = (Number(valuePlan * (19 / 100)) + Number(valuePlan)) * 100;
            showToastTC("¡Excelente noticia! 🎉 Estamos casi listos. Solo necesitas realizar el pago en efectivo al encargado para que podamos reflejarlo en nuestra plataforma. ¡Gracias!", 10000, 'info');

            break;
          default:
            break;
        }

        // payment_amount debe ser en centavos, por eso se multiplica por 100

        console.log(body);

        MarketsmsService.executeTransaction(
          method_id,
          account_id,
          body
        ).then((response) => {
          if (response.status === 200) {
            // setPaymentMethods(response.data.items);
            setPostTransaction(response.data);
          } else {
            // Toast con error
          }
        });


      }
    } else {
      console.log("Debe nuevamente loguearse");
    }
  };

  useEffect(() => {
    if (postTransaction) {
      // postTransaction.id
      // Llamar api de idtransaction constantemente
      // getTransactionById(selectMethod, postTransaction.id);

      if (postTransaction.link) {
        window.open(postTransaction.link, '_blank');
      }

      setTimeout(() => {
        navigate('transactions');
      }, 10);
    }
  }, [postTransaction]);

  const getTransactionById = async (
    method_id: number,
    transaction_id: string
  ) => {
    const response = await MarketsmsService.getTransactionById(
      method_id,
      transaction_id
    );
    console.log(response);
    console.log(response.data);
    if (response.status === 200) {
      setGetTransaction(response);
    }
  };

  useEffect(() => {
    console.log("object");
    if (getTransaction) {
      console.log(getTransaction);
      console.log(getTransaction.data);
      const transaction = getTransaction.data;

      if (
        transaction.payment_method_type === "BANCOLOMBIA_TRANSFER" ||
        transaction.payment_method_type === "PSE"
      ) {
        if (
          transaction.payment_method &&
          transaction.payment_method.extra &&
          transaction.payment_method.extra.async_payment_url
        ) {
          const url = transaction.payment_method.extra.async_payment_url;
          window.open(url, "_blank");
        } else {
          console.log("No esta");
          getTransactionById(selectMethod, transaction.id);
        }
      } else if (transaction.payment_method_type === "BANCOLOMBIA_QR") {
        if (
          transaction.payment_method &&
          transaction.payment_method.extra &&
          transaction.payment_method.extra.qr_image
        ) {
          const image_qr = transaction.payment_method.extra.qr_image;
          setVisibleQr(image_qr);
        } else {
          setTimeout(() => {
            getTransactionById(selectMethod, transaction.id);
          }, 2000);
        }
      } else if (transaction.payment_method_type === "BANCOLOMBIA_COLLECT") {
        if (
          transaction.payment_method &&
          transaction.payment_method.extra &&
          transaction.payment_method.extra.business_agreement_code
        ) {
          // const image_qr = transaction.payment_method.extra.qr_image;
          // setDataCollect(prevDataCollect => [...prevDataCollect, { 'hola': 'ddsd' }]);
          setVisibleCollect(true);
        } else {
          setTimeout(() => {
            getTransactionById(selectMethod, transaction.id);
          }, 2000);
        }
      }
    }
  }, [getTransaction]);

  useEffect(() => {
    getPlansByAccount();
    getPaymentMethods();
  }, []);

  const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    var { name, value } = event.target;
    console.log("START");
    value = value.replace(/\D/g, "");
    console.log(value);
    if (plan?.value) {
      console.log(Number(value));
      console.log(plan.value);
      if (Number(value) > plan?.value) {
        console.log("Si es mayor");
        // plan.value = Number(value);
        // console.log(plan);
        setValuePlan(Number(value));
      } else {
        console.log("Debe permanecer el precio del plan");
        console.log(plan.value);
        setValuePlan(plan.value);
      }
    }
    console.log("END");
  };

  const formatter = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  const formattedValue = (value: number) => {
    console.log("Hola mundo");
    var formatValue = formatter.format(Number(value));
    console.log("formatValue");
    console.log(formatValue);
    return formatValue;
  }


  const [phoneNumberNequi, setPhoneNumberNequi] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  // const handlePhoneNumberNequi = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   var { name, value } = event.target;

  //   console.log(value)
  //   value = value.replace(/\D/g, "");

  //   const phoneNumber = Number(value);
  //   setPhoneNumberNequi(phoneNumber);

  // };

  // const handlePhoneNumberNequi = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const { value } = event.target;
  //   const numericValue = value.replace(/\D/g, "");

  //   if (numericValue.length ==== 10) {
  //     const phoneNumber = Number(numericValue);
  //     setPhoneNumberNequi(phoneNumber);
  //   } else if (numericValue.length > 10) {
  //     // Si el número ingresado es mayor a 10 dígitos, podrías truncarlo a 10 dígitos
  //     const phoneNumber = Number(numericValue.slice(0, 10));
  //     setPhoneNumberNequi(phoneNumber);
  //   } else {
  //     // Si el número ingresado tiene menos de 10 dígitos, puedes no realizar ninguna acción o mostrar un mensaje de error.
  //     // Por ejemplo, puedes mostrar una alerta o mensaje indicando que se requieren 10 dígitos.
  //   }
  // };

  const handlePhoneNumberNequi = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let { value } = event.target;

    // Eliminar todos los caracteres que no sean dígitos
    value = value.replace(/\D/g, "");
    console.log(value);
    // Limitar el número de dígitos a 10
    value = value.slice(0, 10);

    if (/^\d{10}$/.test(value)) {
      const phoneNumber = Number(value);
      setPhoneNumberNequi(phoneNumber);
      setErrorMessage("");
    } else {
      // Puedes mostrar un mensaje de error o alerta aquí si el número no tiene 10 dígitos
      // Mantener error
      // setPhoneError(true);
      setErrorMessage("Por favor escriba un número de nequi valido.");
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const pattern = /[0-9]/;
    if (!pattern.test(event.key)) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    console.log(phoneNumberNequi);
  }, [phoneNumberNequi]);

  useEffect(() => {
    console.log(saveSource);
  }, [saveSource]);

  return (
    <div className="col-12">
      <CCard className="mb-4">
        <CCardHeader>
          <strong>Pagos</strong>
        </CCardHeader>
        <CCardBody>
          <div className="mb-5">
            {plan?.id === 7 && (
              <div>
                <h4 className="bg-light p-2 text-center mb-3">
                  Plan <strong>{plan?.name}</strong>
                </h4>
                <CFormInput
                  type="number"
                  name="custom_price"
                  minLength={10}
                  maxLength={10}
                  placeholder="Por favor escriba el valor de su recarga"
                  feedbackValid="Luce bien!"
                  feedbackInvalid="Por favor escriba una cantidad valida."
                  id="validationCustom02"
                  onChange={handleChangePrice}
                  label={`Puedes realizar la recarga de una valor personalizado, minimo aqui ${plan.value}`}
                  className="mb-5"
                />
                <div className="text-center">
                  <h5>
                    Valor Neto: <strong>{formattedValue(valuePlan)}</strong>
                  </h5>
                  <h5>
                    Impuesto: <strong>19%</strong>
                  </h5>
                  <h5>
                    Total a pagar: <strong>{formattedValue(Number(valuePlan * (19 / 100)) + Number(valuePlan))}</strong>
                  </h5>
                </div>
              </div>
            )}
            {plan?.id !== 7 && (
              <div className="text-center">
                <h4 className="bg-light p-2 text-center mb-3">
                  Plan <strong>{plan?.name}</strong>
                </h4>
                <h5>
                  Valor Neto: <strong>{formattedValue(valuePlan)}</strong>
                </h5>
                <h5>
                  Impuesto: <strong>19%</strong>
                </h5>
                <h5>
                  Total a pagar: <strong>{formattedValue(Number(valuePlan * (19 / 100)) + Number(valuePlan))}</strong>
                </h5>
              </div>
            )}
          </div>
          <h4 className="bg-light p-2 text-center mb-5">
            Escoge un método de pago
          </h4>
          <CRow className="d-flex justify-content-center">
            {paymentMethods.map((method, index) => (
              <CCard
                key={index}
                style={{ width: "9rem" }}
                className={`m-2 cursor-pointer hover-card ${selectMethod === method.id ? "bg-info" : ""
                  }`}
                onClick={() => proccessTransaction(method.id)}
              >
                <CCardImage
                  orientation="top"
                  src={method.image_url}
                  className="p-3"
                />
                <CCardBody className="p-0 d-flex align-items-center justify-content-center">
                  <CCardTitle className="text-center fs-6">
                    {method.name}
                  </CCardTitle>
                </CCardBody>
              </CCard>
            ))}
          </CRow>
        </CCardBody>
      </CCard>

      {/* Para pagar con nequi */}

      <CModal
        visible={visibleNequi}
        onClose={() => setVisibleNequi(false)}
        aria-labelledby="LiveDemoExampleLabel"
      >
        <CModalHeader closeButton>
          <CModalTitle id="LiveDemoExampleLabel">
            Realizar pago con nequi
          </CModalTitle>
        </CModalHeader>
        <CModalBody className="mb-2">
          <div className="bg-light p-2 text-center mt-1 mb-3">
            <h5>Escoge uno de los números guardados</h5>
          </div>
          <CRow className="d-flex justify-content-center p-2 gap-1">
            {paymentSources.map((source, index) => (
              <CCol
                xs={5}
                onClick={() =>
                  executeTransaction(4, { payment_source_id: source.id })
                }
              >
                <CRow className="border border-info rounded-2 p-2 cursor-pointer hover-card">
                  <CCol xs={2} className="p-0">
                    <CCardImage
                      orientation="top"
                      className="p-0"
                      src={paymentMethods[selectMethod - 1].image_url}
                    />
                  </CCol>

                  <CCol xs={10}>
                    <CCardBody className="p-0 ">
                      <CCardTitle className="fs-6">{source.name}</CCardTitle>
                      <CCardText className="fs-6">{source.number}</CCardText>
                    </CCardBody>
                  </CCol>
                </CRow>
              </CCol>
            ))}
          </CRow>

          <div className="bg-light p-2 text-center mt-4 mb-3">
            <h5>Realiza el pago con un número nuevo</h5>
          </div>

          <CCol className="mb-2">
            <CFormInput
              type="text"
              name="ip"
              minLength={10}
              maxLength={10}
              pattern="[3]{1}[0-9]{2}[0-9]{3}[0-9]{4}"
              onChange={handlePhoneNumberNequi}
              onKeyPress={handleKeyPress}
              placeholder="Por favor escriba su número de nequi"
              feedbackValid="Luce bien!"
              feedbackInvalid="Por favor escriba un número de nequi valido."
              id="validationCustom02"
              label="Número de teléfono"
              inputMode="numeric" // Establecer el inputMode en "numeric"
            />
            {errorMessage || !phoneNumberNequi ? (
              <p className="text-danger mt-2">{errorMessage}</p>
            ) : (
              <p className="text-success mt-2">Luce bien!</p>
            )}
          </CCol>

          <CFormCheck
            id="checkSaveSource"
            type="checkbox"
            checked={saveSource}
            onChange={() => setSaveSource(!saveSource)}
            label="Guardar para usar despues"
          />
        </CModalBody>
        <CModalFooter>
          <CButton
            color="success"
            variant="outline"
            className="mt-2"
            type="submit"
            disabled={errorMessage !== "" || phoneNumberNequi === 0}
            onClick={() =>
              executeTransaction(4, {
                payment_phone_number: phoneNumberNequi,
                saveSource: saveSource,
              })
            }
          >
            <CIcon icon={cilCheckAlt} /> Procesar transacción
          </CButton>
        </CModalFooter>
      </CModal>

      {/* Para pagar con PSE */}

      <CModal
        visible={visiblePse}
        onClose={() => setVisiblePse(false)}
        aria-labelledby="LiveDemoExampleLabel"
      >
        <CModalHeader closeButton>
          <CModalTitle id="LiveDemoExampleLabel">
            Escoge una institución financiera
          </CModalTitle>
        </CModalHeader>
        <CModalBody className="mb-2">
          <CRow className="d-flex justify-content-center p-2 gap-1">
            {financialInstitutions.map((institution, index) => (
              <CCol
                xs={12}
                onClick={() => {
                  executeTransaction(5, {
                    payment_institution_code: institution.code,
                    payment_user_type: 0,
                  });
                }}
              >
                {/* <CRow className="border border-info rounded-2 p-2 cursor-pointer hover-card"> */}
                <CRow className=" cursor-pointer hover-card border-bottom p-2">
                  <CCol className="p-0">
                    <CImage
                      //   orientation="top"
                      //   className="p-0"
                      width={32}
                      height={32}
                      src={institution.image_url_cirlce}
                    />
                  </CCol>

                  <CCol md={11} className="p-0 d-flex align-items-center">
                    <CCardBody className="p-0 ps-3">
                      <CCardTitle className="fs-6 ">
                        {institution.name}
                      </CCardTitle>
                      <CCardText className="fs-6">Con pse</CCardText>
                    </CCardBody>
                  </CCol>
                </CRow>
              </CCol>
            ))}
          </CRow>
        </CModalBody>
        {/* <CModalFooter>
          <CButton
            color="success"
            variant="outline"
            className="mt-2"
            type="submit"
          >
            <CIcon icon={cilCheckAlt} /> Procesar transacción
          </CButton>
        </CModalFooter> */}
      </CModal>

      {/* Para pagar con QR */}

      <CModal
        visible={visibleQr !== ""}
        onClose={() => setVisibleQr("")}
        aria-labelledby="LiveDemoExampleLabel"
      >
        <CModalHeader closeButton>
          <CModalTitle id="LiveDemoExampleLabel">
            Escanea el código QR desde tu App Bancolombia
          </CModalTitle>
        </CModalHeader>
        <CModalBody className="mb-2">
          <img src={`data:image/svg+xml;base64,${visibleQr}`} alt="Imagen QR" />
        </CModalBody>
      </CModal>

      <CModal
        visible={visibleCollect}
        onClose={() => setVisibleCollect(false)}
        aria-labelledby="LiveDemoExampleLabel"
      >
        <CModalHeader closeButton>
          <CModalTitle id="LiveDemoExampleLabel">
            Paga en tu punto mas cercano <strong>Bancolombia</strong>
          </CModalTitle>
        </CModalHeader>
        <CModalBody className="mb-2">
          {getTransaction?.data && (
            <div>
              <h5>Numero de Convenio:</h5>
              <p>
                {
                  getTransaction.data.payment_method.extra
                    .business_agreement_code
                }
              </p>
              <h5>Numero de referencia:</h5>
              <p>
                {
                  getTransaction.data.payment_method.extra
                    .payment_intention_identifier
                }
              </p>
            </div>
          )}
        </CModalBody>
      </CModal>
    </div>
  );
};
