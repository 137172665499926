import React, { useEffect, useState } from "react";
import {
  CButton,
  CSmartTable,
  CCard,
  CCardHeader,
  CCardBody,
  CTabContent,
  CTabPane,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CTable,
  CTableBody,
  CTableRow,
  CTableHeaderCell,
  CTableDataCell,
  CBadge,
  CImage,
} from "@coreui/react-pro";

import * as MarketsmsService from "../../services/MarketsmsService";

import { Link, useNavigate, useParams } from "react-router-dom";
import { MessagesItems } from "../../models/Messages";
import { BIcon } from "../../components/icons/BIcon";
import { getBadgeMessageStatus, getBadgeWhatsappMessageStatus, getBadgeWhatsappTemplateStatus, getDefaultPhoneNumber, showToastTC } from "../../Utils/BaseApp";
import { WhatsappMessagesItems } from "../../models/WhatsappMessages";
import CustomVideo from "../../components/video/CustomVideo";

interface WhatsappCampaignsMessagesProps { }

export const WhatsappCampaignsMessages: React.FC<
  WhatsappCampaignsMessagesProps
> = ({ }: WhatsappCampaignsMessagesProps) => {
  const { id } = useParams();
  const campaign_id = parseInt(id + "");
  const [messages, setMessages] = useState<WhatsappMessagesItems[]>([]);
  const [visibleDetails, setVisibleDetails] = useState(false);
  const [template, setTemplate] = useState<WhatsappMessagesItems>();
  const [visibleTemplate, setVisibleTemplate] = useState(false);
  const [details, setDetails] = useState<WhatsappMessagesItems>();
  const navigate = useNavigate();

  const getMessages = async () => {
    const phone_number_id = getDefaultPhoneNumber();

    if (phone_number_id != null) {
      var response = await MarketsmsService.getWhatsappMessagesByCampaign(phone_number_id, campaign_id);
      if (response) {
        if (response.status === 200) {
          setMessages(response.data.items);
        }
      }
    } else {
      showToastTC("No contiene un numero de telefono por defecto, seleccionalo por favor", 5000, "error");
      setTimeout(() => {
        navigate("/whatsapp/numbers");
      }, 5000);
    }
  };

  const showDetails = (message: WhatsappMessagesItems) => {
    if (message) {
      setDetails(message);
      setVisibleDetails(true);
    }
  };

  const showTemplate = (message: WhatsappMessagesItems) => {
    if (message) {
      setTemplate(message);
      setVisibleTemplate(true);
    }
  };

  const formatText = (text: string) => {
    let formattedText = text;
    formattedText = formattedText.replace(/\*([^\*]+)\*/g, '<strong>$1</strong>');
    formattedText = formattedText.replace(/_([^_]+)_/g, '<em>$1</em>');
    formattedText = formattedText.replace(/~([^~]+)~/g, '<del>$1</del>');
    formattedText = formattedText.replace(/```([^`]+)```/g, '<code style="color:inherit">$1</code>');
    formattedText = formattedText.replace(/\n/g, '<br/>'); // Convertir saltos de línea a <br/>
    return <p className="m-0 fs-8 mt-2 text-black" dangerouslySetInnerHTML={{ __html: formattedText }} />;
  };

  useEffect(() => {
    getMessages();
  }, []);

  const columns = [
    {
      key: "id",
      _style: { width: "4%" },
    },
    {
      key: "from",
      label: "Desde",
      _style: { width: "8%" },
    },
    {
      key: "to",
      label: "Hacia",
      _style: { width: "8%" },
    },
    {
      key: "body",
      label: "Cuerpo",
      _style: { width: "12%" },
    },
    // {
    //   key: "segments",
    //   label: "Seg.",
    //   _style: { width: "5%" },
    // },
    {
      key: "price",
      label: "Precio",
      _style: { width: "7%" },
    },
    {
      key: "account_name",
      label: "Cuenta",
      _style: { width: "10%" },
    },
    {
      key: "created_at",
      label: "Fecha",
      _style: { width: "12%" },
    },
    {
      key: "message_status_id",
      label: "Estado",
      _style: { width: "7%" },
    },
    {
      key: "operations",
      label: "Acción",
      _style: { width: "10%" },
    },
  ];

  return (
    <div className="col-12">
      <CCard className="mb-4">
        <CCardHeader className="p-0 d-flex align-items-center ps-1">
          <Link to={'/whatsapp/campaigns'} >
            <BIcon icon="arrow-left-short" className="fs-3 me-1 pt-2 text-danger cursor-pointer" />
          </Link>
          <strong>Mensajes para la campaña <span className="text-danger">{id}</span></strong>
        </CCardHeader>
        <CCardBody>
          <CTabContent className="rounded-bottom">
            <CTabPane
              className="p-3 active preview show"
              role="tabpanel"
              id="preview-578"
            >
              <CSmartTable
                activePage={2}
                cleaner
                clickableRows
                columns={columns}
                columnFilter
                columnSorter
                // footer
                loading={messages.length === 0 ? true : false}
                items={messages}
                itemsPerPageSelect
                itemsPerPage={10}
                pagination
                onFilteredItemsChange={(items) => {
                  // console.log(items)
                }}
                onSelectedItemsChange={(items) => {
                  // console.log(items)
                }}
                scopedColumns={{
                  // body: (message: MessagesItems) => (
                  //   <td>{message.body.substring(0, 15) + " ..."}</td>
                  // ),
                  message_status_id: (message: any) => (
                    <td>
                      <CBadge className={`bg-${getBadgeWhatsappMessageStatus(message.whatsapp_statuses_id)}`}>
                        {message.status_name}
                      </CBadge>
                    </td>
                  ),
                  body: (whatsapp: WhatsappMessagesItems) => {
                    return (
                      <td className="single-line">
                        {whatsapp.body.substring(0, 30)+"..."}
                      </td>
                    )
                  },
                  operations: (message: WhatsappMessagesItems) => {
                    return (
                      <td className="py-2 text-center">

                        <Link to={"#"}>
                          <CButton
                            color="success"
                            variant="outline"
                            shape="square"
                            className="m-1"
                            size="sm"
                            title="Ver más"
                            onClick={() => {
                              showTemplate(message);
                            }}
                          >
                            <BIcon icon={"eye"} />
                          </CButton>
                        </Link>
                        <Link to={"#"}>
                          <CButton
                            color="info"
                            variant="outline"
                            shape="square"
                            className="m-1"
                            size="sm"
                            title="Ver más"
                            onClick={() => {
                              showDetails(message);
                            }}
                          >
                            <BIcon icon={"info-circle"} />
                          </CButton>
                        </Link>
                      </td>
                    );
                  },
                }}
                // selectable
                sorterValue={{ column: "status", state: "asc" }}
                tableFilter
                tableProps={{
                  className: "add-this-class",
                  responsive: true,
                  striped: true,
                  hover: true,
                }}
                tableBodyProps={{
                  className: "align-middle",
                }}
              />
            </CTabPane>
          </CTabContent>
        </CCardBody>
      </CCard>


      <CModal
        visible={visibleTemplate}
        onClose={() => setVisibleTemplate(false)}
        aria-labelledby="LiveDemoExampleLabel"
        size="sm"
      >
        {template &&

          // <CCard className="text-center" style={{ width: "18rem" }}>
          <CCardBody className="py-0 px-0" style={{
          }}>
            <div className="d-flex flex-column">

              <div className="bg-white rounded-end-3 rounded-bottom-3 pb-2 shadow text-start" style={{ maxWidth: "100%", minWidth: "25%", position: 'relative', minHeight: "42px" }}>
                <div className="m-1">

                  {template.template.header_format == 'TEXT' && <p className="m-0 fs-7 fw-bolder">{template.template?.header_content}</p>}
                  {template.template?.header_format != 'TEXT' && (
                    <>
                      {template.template?.header_format == 'IMAGE' ? (
                        <CImage src={template.variables.header[0]} className="rounded-2 w-100 h-50" />
                      ) : template.template?.header_format == 'VIDEO' ? (

                        <CustomVideo src={template.variables.header[0]} className="rounded-2 w-100" controls={true} />
                      ) : (
                        <p>Preview no disponible para este tipo de archivo</p>
                      )}
                    </>
                  )}
                  <p className="m-0 fs-8 mt-2 text-black">{formatText(template.body ?? "")}</p>
                  <p className="m-0 fs-9 mt-2 text-gray">{template.template?.footer_content}</p>
                  {/* <p className="p-0 fs-9 text-end m-0 text-gray" style={{ bottom: '2px', right: '10px' }}>{formatTimeTemplate(new Date())}</p> */}
                </div>


                {(template.template?.buttons != undefined && template.template?.buttons.length > 0) && <hr className="my-2" />}
                <div className={`px-${(template.template?.buttons != undefined && template.template?.buttons.length > 0) ? 2 : 0}`}>
                  {(template.template?.buttons != undefined && template.template?.buttons.length < 4) &&
                    template.template?.buttons.map((button, index) => (
                      <div key={index}>
                        {index > 0 && <hr className="my-2" />}

                        {button.type === 1 && (
                          <p className="text-center text-info my-0">
                            <BIcon icon="reply-fill" size={5} className="me-2" />
                            {button.text}
                          </p>
                        )}
                        {button.type === 4 && (
                          <p className="text-center text-info my-0">
                            <BIcon icon="copy" size={7} className="me-2" />
                            {button.text}
                          </p>
                        )}
                        {button.type === 2 && (
                          <p className="text-center text-info my-0">
                            <BIcon icon="box-arrow-up-right" size={8} className="fw-bold me-2" />
                            {button.text}
                          </p>
                        )}
                        {button.type === 3 && (
                          <p className="text-center text-info my-0">
                            <BIcon icon="telephone-fill" size={7} className="me-2" />
                            {button.text}
                          </p>
                        )}
                      </div>
                    ))
                  }
                  {(template.template?.buttons != undefined && template.template?.buttons.length > 3) &&
                    template.template?.buttons.slice(0, 2).map((button, index) => (
                      <div key={index}>

                        {index > 0 && <hr className="my-2" />}

                        {button.type === 1 && (
                          <p className="text-center text-info my-0">
                            <BIcon icon="reply-fill" size={5} className="me-2" />
                            {button.text}
                          </p>
                        )}
                        {button.type === 4 && (
                          <p className="text-center text-info my-0">
                            <BIcon icon="copy" size={7} className="me-2" />
                            {button.text}
                          </p>
                        )}
                        {button.type === 2 && (
                          <p className="text-center text-info my-0">
                            <BIcon icon="box-arrow-up-right" size={8} className="fw-bold me-2" />
                            {button.text}
                          </p>
                        )}
                        {button.type === 3 && (
                          <p className="text-center text-info my-0">
                            <BIcon icon="telephone-fill" size={7} className="me-2" />
                            {button.text}
                          </p>
                        )}

                        {index === 1 && (
                          <div>
                            <hr className="my-2" />
                            <p className="text-center text-info my-0">
                              <BIcon icon="list-ul" size={5} className="me-2" />Ver todas las opciones
                            </p>
                          </div>
                        )}
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </CCardBody>
          // </CCard>
        }

      </CModal>


      <CModal
        visible={visibleDetails}
        onClose={() => setVisibleDetails(false)}
        aria-labelledby="LiveDemoExampleLabel"
      >
        <CModalHeader closeButton>
          <CModalTitle id="LiveDemoExampleLabel">Detalles</CModalTitle>
        </CModalHeader>
        {details &&
          <CModalBody>
            <CTable border={0}>
              <CTableBody>
                <CTableRow>
                  <CTableHeaderCell scope="row">Id</CTableHeaderCell>
                  <CTableDataCell className="fs-7">{details?.id}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableHeaderCell scope="row">
                    Id de Referencia
                  </CTableHeaderCell>
                  <CTableDataCell className="fs-7" style={{ wordBreak: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }}>{details?.reference_id}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableHeaderCell scope="row">Desde</CTableHeaderCell>
                  <CTableDataCell className="fs-7">{details?.from}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableHeaderCell scope="row">Estado</CTableHeaderCell>
                  <CTableDataCell className="fs-7">{details?.status_name}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableHeaderCell scope="row">Hacia</CTableHeaderCell>
                  <CTableDataCell className="fs-7">{details?.to}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableHeaderCell scope="row">Mensaje</CTableHeaderCell>
                  <CTableDataCell className="fs-7">{details?.body}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableHeaderCell scope="row">Precio</CTableHeaderCell>
                  <CTableDataCell className="fs-7">{details?.price}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableHeaderCell scope="row">Fecha</CTableHeaderCell>
                  <CTableDataCell className="fs-7">{details?.created_at}</CTableDataCell>
                </CTableRow>
              </CTableBody>
            </CTable>
          </CModalBody >
        }
      </CModal >
    </div >
  );
};
