import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardHeader,
  CCardBody,
  CSmartTable,
  CButton,
  CTabContent,
  CBadge,
} from "@coreui/react-pro";

import * as MarketsmsService from "../../services/MarketsmsService";
import {
  customMessage,
  getBadge,
  getCurrentAccount,
  showToastTC,
  showToastTR,
} from "../../Utils/BaseApp";
import {
  GroupsItems
} from "../../models/Groups";
import CIcon from "@coreui/icons-react";
import { Link, useNavigate } from "react-router-dom";
import {
  cilPlus,
  cilSettings,
  cilTrash,
} from "@coreui/icons";
import { BIcon } from "../../components/icons/BIcon";

interface GroupsProps { }

export const Groups: React.FC<GroupsProps> = ({ }: GroupsProps) => {

  const [groups, setGroups] = useState<GroupsItems[] | null>(null);
  const navigate = useNavigate();

  const getGroups = async () => {
    const current_account = await getCurrentAccount();

    var response = null;
    if (current_account) {
      response = await MarketsmsService.getGroupsByAccoount();
    } else {
      showToastTC('Para realizar este proceso debes seleccionar una cuenta por defecto', 3000, 'error');
      setTimeout(() => {
        navigate('/user/administration/accounts');
      }, 3000);
    }
    if (response) {
      if (response.status === 200) {
        setGroups(response.data.items);
      }
    }
  };

  const updateGroupStatus = async (group_id: number, status: string) => {
    var response = await MarketsmsService.updateGroupStatus(group_id, status);
    if (response) {
      if (response.status === 200) {
        getGroups();
        showToastTR(customMessage(response.code_app), 200, "success");
      } else {
        showToastTR(customMessage(response.code_app), 2000, "error");
      }
    }
  };

  const columns = [
    {
      key: "id",
      _style: { width: "5%" },
    },
    {
      key: "name",
      label: "Nombre",
      _style: { width: "25%" },
    },
    {
      key: "description",
      label: "Descripción",
      _style: { width: "30%" },
    },
    {
      key: "quantity_contacts",
      label: "Cant. contactos",
      _style: { width: "15%" },
    },
    {
      key: "status_name",
      label: "Estado",
      _style: { width: "10%" },
    },
    {
      key: "operations",
      label: "Acción",
      _style: { width: "15%" },
    },
  ];

  useEffect(() => {
    getGroups();
  }, []);

  return (
    <div className="col-12">
      <CCard className="mb-4">
        <CCardHeader>
          <strong>Grupos</strong>
        </CCardHeader>
        <CCardBody>
          <Link to={"/groups/add"}>
            <CButton>
              <CIcon icon={cilPlus} /> Agregar
            </CButton>
          </Link>
          <CTabContent className="rounded-bottom mt-4">
            <CSmartTable
              // activePage={2}
              cleaner
              clickableRows
              columns={columns}
              columnFilter
              columnSorter
              loading={groups === null ? true : false}
              items={groups ?? []}
              itemsPerPageSelect
              itemsPerPage={5}
              pagination
              onSelectedItemsChange={(items) => {
                console.log(items);
              }}
              // scopedColumns={{}}
              scopedColumns={{
                status_name: (group: GroupsItems) => (
                  <td>
                    <CBadge color={getBadge(group.status_id)}
                      title={
                        group.status_id === 1
                          ? "Deshabilitar grupo"
                          : "Habilitar grupo"
                      } onClick={() => {
                        updateGroupStatus(
                          group.id,
                          group.status_id === 1 ? "disable" : "enable"
                        );
                      }}>
                      {group.status_name}
                    </CBadge>
                  </td>
                ),
                operations: (groups: GroupsItems) => {
                  return (
                    <td className="py-2 text-center">
                      <Link to={"/group/" + groups.id + "/manage"}>
                        <CButton
                          color="primary"
                          variant="outline"
                          shape="square"
                          size="sm"
                        >
                          <CIcon icon={cilSettings} />
                        </CButton>
                      </Link>

                      <Link to={"/group/" + groups.id}>
                        <CButton
                          color="warning"
                          variant="outline"
                          shape="square"
                          size="sm"
                          className="mx-1"
                        >
                          <BIcon icon={"eye"} />
                        </CButton>
                      </Link>
                      <CButton
                        color="danger"
                        variant="outline"
                        shape="square"
                        size="sm"
                      >
                        <CIcon icon={cilTrash} />
                      </CButton>
                    </td>
                  );
                },
              }}
              selectable
              sorterValue={{ column: "id", state: "asc" }}
              tableFilter
              tableProps={{
                className: "add-this-class",
                responsive: true,
                striped: true,
                hover: true,
              }}
              tableBodyProps={{
                className: "align-middle",
              }}
            />
          </CTabContent>
        </CCardBody>
      </CCard>
    </div>
  );
};
