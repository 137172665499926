import React, { useEffect, useRef, useState } from "react";
import {
  CCard,
  CCardHeader,
  CCardBody,
  CSmartTable,
  CImage,
  CRow,
  CCol,
  CFormCheck,
  CPopover,
  CButton,
  CCardTitle,
  CFormInput,
  CDatePicker,
  CCardFooter,
  CTabContent,
  CTabPane,
  CListGroup,
} from "@coreui/react-pro";
import { GroupsItems } from "../../models/Groups";
import { customMessage, getCurrentAccount, getDefaultPhoneNumber, showToastTC, showToastTR } from "../../Utils/BaseApp";
import * as MarketsmsService from "../../services/MarketsmsService";
import { WhatsappTemplatesButtonsModel, WhatsappTemplatesItemsModel } from "../../models/WhatsappTemplates";
import { BIcon } from "../../components/icons/BIcon";
import CustomVideo from "../../components/video/CustomVideo";
import backgroundChat from "../../assets/whatsapp/background_chat.png";
import { useNavigate } from "react-router-dom";

interface WhatsappCampaignsSendProps { }

export const WhatsappCampaignsSend: React.FC<WhatsappCampaignsSendProps> = ({ }: WhatsappCampaignsSendProps) => {

  const [scheduleCampaign, setScheduleCampaign] = useState(false);
  const [groups, setGroups] = useState<GroupsItems[]>([]);
  const [videoKey, setVideoKey] = useState(0);

  const navigate = useNavigate();

  const handleDateChange = (date: any, format_date: any) => {
    formData.schedule_date = format_date;
  };


  const getGroups = async () => {
    const current_account = await getCurrentAccount();
    console.log(current_account);
    var response = null;
    if (current_account) {
      response = await MarketsmsService.getActiveGroupsByAccoount();
    } else {
      // response = await MarketsmsService.getGroups();
      console.log("No existe una cuenta escogida 3");
    }
    if (response) {
      if (response.status === 200) {
        setGroups(response.data.items);
      }
    }
  };


  // const [formData, setFormData] = useState<{
  //   name: string;
  //   description: string;
  //   schedule_date: null;
  //   body: string;
  //   groups: number[];
  // }>({
  //   name: "",
  //   description: "",
  //   schedule_date: null,
  //   body: "",
  //   groups: [],
  // });

  const columns = [
    {
      key: "id",
      _style: { width: "10%" },
    },
    {
      key: "name",
      label: "Nombre",
      _style: { width: "30%" },
    },
    {
      key: "description",
      label: "Descripción",
      _style: { width: "30%" },
    },
    {
      key: "quantity_contacts",
      label: "Cantidad de contactos",
      _style: { width: "30%" },
    },
  ];

  useEffect(() => {
    getGroups();

    const phone_number_id = getDefaultPhoneNumber();
    if (phone_number_id == null) {
      showToastTC("No contiene un numero de telefono por defecto, seleccionalo por favor", 5000, "error");
      setTimeout(() => {
        navigate("/whatsapp/numbers");
      }, 5000);
    }
  }, []);


  const [templates, setTemplates] = useState<WhatsappTemplatesItemsModel[]>([]);
  const [selectTemplate, setSelectTemplate] = useState(0);
  const [selectTemplateData, setSelectTemplateData] = useState<WhatsappTemplatesItemsModel>();

  useEffect(() => {
    getCurrentAccount().then((account) => {
      if (account) {

        const phone_number_id = getDefaultPhoneNumber();
        if (phone_number_id != null) {
          MarketsmsService.getActiveTemplates(account.id, phone_number_id).then((response) => {
            if (response.status == 200) {
              setTemplates(response.data.items);
            }
          })
        } else {
          showToastTC("No contiene un numero de telefono por defecto, seleccionalo por favor", 5000, "error");
          setTimeout(() => {
            navigate("/whatsapp/numbers");
          }, 5000);
        }
      }
    })
  }, []);



  const [bodyVariables, setBodyVariables] = useState<string[]>([]);
  const [bodyVariablesData, setBodyVariablesData] = useState<string[]>([]);
  const [bodyTextPreview, setBodyTextPreview] = useState("");


  const [headerVariables, setHeaderVariables] = useState<string[]>([]);
  const [headerVariablesData, setHeaderVariablesData] = useState<string[]>([]);

  const handleChangeValueVariableHeader = (index: number, data: string) => {
    // Crear una copia del array de estado actual
    const updatedHeaderVariables = [...headerVariablesData];
    // Actualizar el elemento específico en la copia del array
    updatedHeaderVariables[index] = data;
    // Establecer el nuevo estado
    setHeaderVariablesData(updatedHeaderVariables);
  }

  const handleChangeValueVariableBody = (index: number, data: string) => {
    // Crear una copia del array de estado actual
    const updatedBodyVariables = [...bodyVariablesData];
    // Actualizar el elemento específico en la copia del array
    updatedBodyVariables[index] = data;
    // Establecer el nuevo estado
    setBodyVariablesData(updatedBodyVariables);
  }

  useEffect(() => {
    if (selectTemplateData?.body_content) {
      let updatedBodyText = selectTemplateData?.body_content; // Inicializar con el texto original

      bodyVariablesData.forEach((element, index) => {
        updatedBodyText = updatedBodyText.replaceAll(`{{${index + 1}}}`, element);
      });

      setBodyTextPreview(updatedBodyText);
    }
  }, [bodyVariablesData]);


  const [buttons, setButtons] = useState<WhatsappTemplatesButtonsModel[]>([]);
  // const [buttonsVariablesData, setButtonsVariablesData] = useState<string[]>([]);

  // var bodyVariables
  useEffect(() => {
    if (selectTemplateData) {
      console.log("Entra aqui");
      console.log(selectTemplateData);

      if (selectTemplateData.header_format != "TEXT") {
        handleChangeValueVariableHeader(0, selectTemplateData.header_content);
      }

      console.log(selectTemplateData);
      setBodyVariablesData([]);

      console.log("--------------------------------------------------------------setBodyVariables--------------------------------------------------------------");
      // const template = templates.find(template => template.id === selectTemplate);
      const template = selectTemplateData;
      // setSelectTemplateData(template);
      console.log(template);

      console.log(template?.body_content);
      console.log(template?.body_content.match(/{{\d+}}/g) || []);

      // Body
      setBodyVariables(template?.body_content.match(/{{\d+}}/g) || []);
      setBodyTextPreview(template?.body_content);
      // Header
      if (template?.header_format == 'TEXT') {
        console.log("----------------------HEADER CONTENT START----------------------");
        console.log(template?.header_content.match(/{{\d+}}/g) || []);
        console.log("----------------------HEADER CONTENT END----------------------");
        setHeaderVariables(template?.header_content.match(/{{\d+}}/g) || []);
        setHeaderContent(template?.header_content);
      }

      console.log("bodyVariablesData");
      console.log(bodyVariablesData);
      console.log("bodyVariablesData");

      // const bodyVariables = ;

      bodyVariables.forEach((element, index) => {
        const updatedBodyVariables = [...bodyVariablesData];
        updatedBodyVariables[index] = "";
        setBodyVariablesData(updatedBodyVariables);
      });


      // Trabajo con buttons
      setButtons(template.buttons);


      setFormData({ ...formData, ["template_id"]: template.id });

    }
  }, [selectTemplateData])



  const updateExampleButton = (index: number, example: string) => {
    console.log("updateUrlTypeButton");
    const copyButtons = [...buttons];
    copyButtons[index].example = example.replaceAll(' ', '_');
    setButtons(copyButtons);
  }


  const formatText = (text: string) => {
    let formattedText = text;
    formattedText = formattedText.replace(/\*([^\*]+)\*/g, '<strong>$1</strong>');
    formattedText = formattedText.replace(/_([^_]+)_/g, '<em>$1</em>');
    formattedText = formattedText.replace(/~([^~]+)~/g, '<del>$1</del>');
    formattedText = formattedText.replace(/```([^`]+)```/g, '<code style="color:inherit">$1</code>');
    formattedText = formattedText.replace(/\n/g, '<br/>'); // Convertir saltos de línea a <br/>
    return <p className="m-0 fs-8 mt-2 text-black" dangerouslySetInnerHTML={{ __html: formattedText }} />;
  };

  function formatTime(date: any) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amOrPm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12; // Convertir a formato de 12 horas
    return `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${amOrPm}`;
  }



  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [nameMedia, setNameMedia] = useState<string | null>(null);
  const fileInputMediaRef = useRef<HTMLInputElement>(null);
  const [headerContent, setHeaderContent] = useState("");



  const handleFileChange = (files: FileList | null) => {
    if (files && files.length > 0) {
      const file = files[0];

      // Verificar el tipo de archivo y tamaño máximo permitido
      let maxSize = 0;
      let validTypes = '';

      if (file.type.startsWith('image/')) {
        maxSize = 2 * 1024 * 1024; // 2MB para imágenes
        validTypes = 'image/*';
      } else if (file.type.startsWith('video/')) {
        maxSize = 16 * 1024 * 1024; // 16MB para videos
        validTypes = 'video/*';
      } else {
        alert('Tipo de archivo no permitido.');
        resetFileInputMedia(); // Reiniciar el input de tipo file
        return;
      }

      // Verificar el tamaño del archivo
      if (file.size > maxSize) {
        alert(`El archivo excede el tamaño máximo permitido de ${maxSize / (1024 * 1024)}MB.`);
        resetFileInputMedia(); // Reiniciar el input de tipo file    
        return;
      }

      getCurrentAccount().then((account) => {
        if (account) {
          MarketsmsService.uploadMediaTemplate(file, account.id, nameMedia).then((response) => {
            if (response.data) {
              setPreviewUrl(response.data.url);

              console.log("Cambio de previe URL");
              console.log(response.data.url);
              setNameMedia(response.data.name.split('.')[0]);

              setHeaderContent(response.data.url.replaceAll(response.data.name, '{{1}}'));
              console.log(response.data);
              console.log(response.data.url);
              setHeaderVariablesData([response.data.url]);

              const reader = new FileReader();
              reader.onload = () => {
                setPreviewUrl(reader.result as string);
                setVideoKey(prevKey => prevKey + 1);
              };
              reader.readAsDataURL(file);
            }
          });
        } else {
          showToastTR('Parece que no has seleccionado la cuenta para crear el template', 4000, 'error');
        }
      });
    }
  };


  const resetFileInputMedia = () => {
    if (fileInputMediaRef.current) {
      fileInputMediaRef.current.value = ''; // Limpiar el valor del input de tipo file
    }
  };


  const [formData, setFormData] = useState<{
    template_id: number;
    name: string;
    description: string;
    schedule_date: null;
    // body: string;
    groups: number[];
    components: { header: string[], body: string[], button: string[] }
  }>({
    template_id: 0,
    name: "",
    description: "",
    schedule_date: null,
    // body: "",
    groups: [],
    components: { header: [], body: [], button: [] }
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("Handle");
    var { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const onHandleSubmit = () => {
    const buttonsVariablesData: string[] = buttons.map(item => item.example ?? "").filter(example => example !== "");
    formData.components = { header: headerVariablesData, body: bodyVariablesData, button: buttonsVariablesData };
    console.log("INICIO - formData");
    console.log(formData);
    console.log("FINAL - formData");
    sendWhatsappCampaignToGroups(formData);
  }


  const sendWhatsappCampaignToGroups = (formData: Object) => {
    console.log("START-formData");
    console.log(formData);
    console.log("END-formData");

    const phone_number_id = getDefaultPhoneNumber();
    if (phone_number_id != null) {

      MarketsmsService.sendWhatsappCampaingToGroups(formData, phone_number_id).then(response => {
        if (response.status == 200 || response.status == 201) {
          showToastTR(customMessage(response.code_app), 1000, 'success');
          setTimeout(() => {
            navigate('/whatsapp/campaign/' + response.data.id + '/messages');
          }, 1500);
        } else {
          showToastTR(customMessage(response.code_app), 5000, 'error');
        }
        console.log(response);
      });
    } else {
      showToastTC("No contiene un numero de telefono por defecto, seleccionalo por favor", 5000, "error");
      setTimeout(() => {
        navigate("/whatsapp/numbers");
      }, 5000);
    }
  }

  let titleButtonsShow = true;

  const [focusedFieldId, setFocusedFieldId] = useState<string>("");

  const handleFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id } = event.currentTarget;
    setFocusedFieldId(id);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { name, value } = event.currentTarget;

    const fieldElement = document.getElementById(focusedFieldId) as HTMLInputElement | HTMLTextAreaElement;

    if (focusedFieldId.includes('body')) {
      var id = focusedFieldId.replaceAll("body_", "");
      var data = (bodyVariablesData[parseInt(id)] ?? "") + name;
      handleChangeValueVariableBody(parseInt(id), data);
    } else if (focusedFieldId.includes('header')) {
      var id = focusedFieldId.replaceAll("header_", "");
      var data = (headerVariablesData[parseInt(id)] ?? "") + name;
      handleChangeValueVariableHeader(parseInt(id), name);
    }

    fieldElement.focus();
  };

  return (
    <div className="col-12">
      <CCard className="mb-4">
        <CCardHeader>
          <strong>Enviar campaña de WhatsApp</strong>
        </CCardHeader>
        <CCardBody>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Grupos</strong>
            </CCardHeader>
            <CCardBody>
              <CSmartTable
                // activePage={2}
                // cleaner
                clickableRows
                columns={columns}
                // columnFilter
                columnSorter
                // footer
                items={groups}
                // itemsPerPageSelect
                itemsPerPage={5}
                pagination
                // onFilteredItemsChange={(items) => {
                //   // console.log(items)
                // }}
                onSelectedItemsChange={(items) => {
                  const selectedGroups: number[] = []; // Array para almacenar los IDs seleccionados

                  items.forEach((element) => {
                    selectedGroups.push(element.id); // Agregar cada ID al array
                  });

                  setFormData({ ...formData, ["groups"]: selectedGroups });

                  // setSelectedGroups(selectedGroups);
                }}
                // scopedColumns={{}}
                selectable
                sorterValue={{ column: "id", state: "asc" }}
                tableFilter
                tableProps={{
                  className: "add-this-class",
                  responsive: true,
                  striped: true,
                  hover: true,
                }}
                tableBodyProps={{
                  className: "align-middle",
                }}
              />
            </CCardBody>
          </CCard>


          <CRow>

            <CCol>

              <h6 className="mt-4 mb-4">Escoge un template para enviar la campaña</h6>

              <CRow>
                {templates.map((template, index) => (
                  // <CCol sm={3} key={index} className="m-0">
                  <CCol key={index} xs={12} sm={6} md={6} lg={4} xl={3} xxl={3} className="px-1">
                    <div key={template.id} className="w-100 cursor-pointer p-0">
                      <CPopover
                        // placement="right"
                        placement="top"
                        trigger={['hover', 'focus']}
                        className="w-100 p-0"
                        content={
                          <CCard className="text-center">
                            <CCardHeader className="text-start bg-black text-light my-0 py-1">
                              <strong>
                                {template.name}
                              </strong>
                            </CCardHeader>
                            <CCardBody className="py-1 px-2" style={{
                              backgroundImage: `linear-gradient(rgba(234, 218, 201, 0.8), rgba(234, 218, 201, 0.8)), url(${backgroundChat})`, // Aplica opacidad solo a la imagen de fondo
                              backgroundSize: '100% auto', // Ajusta el tamaño para que el ancho de la imagen ocupe todo el contenedor y la altura se ajuste proporcionalmente
                              backgroundPosition: 'center', // Centra la imagen dentro del contenedor
                            }}>
                              <div className="d-flex flex-column" style={{ marginTop: "-10px" }}>
                                <div className="position-relative" style={{ width: 0, height: 0, borderTop: '8px solid transparent', borderBottom: '8px solid transparent', borderRight: '8px solid #fff', position: 'absolute', left: '-4px', top: '11.5px', transform: "rotate(135deg)" }}></div>
                                <div className="bg-white rounded-end-3 rounded-bottom-3 pb-2 shadow text-start" style={{ maxWidth: "100%", minWidth: "25%", position: 'relative', minHeight: "42px" }}>
                                  <div className="m-1">

                                    {template.header_format == 'TEXT' && <p className="m-0 fs-7 fw-bolder">{template.header_content}</p>}
                                    {template.header_format != 'TEXT' && (
                                      <>
                                        {template.header_format == 'IMAGE' ? (
                                          <CImage src={template.header_content} className="rounded-2 w-100 h-50" />
                                        ) : template.header_format == 'VIDEO' ? (

                                          <CustomVideo src={template.header_content} className="rounded-2 w-100" />
                                        ) : (
                                          // <p>Preview no disponible para este tipo de archivo</p>
                                          <p></p>
                                        )}
                                      </>
                                    )}
                                    <p className="m-0 fs-8 mt-2 text-black">{formatText(template.body_content ? template.body_content.substring(0, 200) : "")}</p>
                                    <p className="m-0 fs-9 mt-2 text-gray">{template.footer_content}</p>
                                    <p className="p-0 fs-9 text-end m-0 text-gray" style={{ bottom: '2px', right: '10px' }}>{formatTime(new Date())}</p>
                                  </div>


                                  {template.buttons.length > 0 && <hr className="my-2" />}
                                  <div className={`px-${template.buttons.length > 0 ? 2 : 0}`}>
                                    {template.buttons.length < 4 &&
                                      template.buttons.map((button, index) => (
                                        <div key={index}>
                                          {index > 0 && <hr className="my-2" />}

                                          {button.type === 1 && (
                                            <p className="text-center text-info my-0">
                                              <BIcon icon="reply-fill" size={5} className="me-2" />
                                              {button.text}
                                            </p>
                                          )}
                                          {button.type === 4 && (
                                            <p className="text-center text-info my-0">
                                              <BIcon icon="copy" size={7} className="me-2" />
                                              {button.text}
                                            </p>
                                          )}
                                          {button.type === 2 && (
                                            <p className="text-center text-info my-0">
                                              <BIcon icon="box-arrow-up-right" size={8} className="fw-bold me-2" />
                                              {button.text}
                                            </p>
                                          )}
                                          {button.type === 3 && (
                                            <p className="text-center text-info my-0">
                                              <BIcon icon="telephone-fill" size={7} className="me-2" />
                                              {button.text}
                                            </p>
                                          )}
                                        </div>
                                      ))
                                    }
                                    {template.buttons.length > 3 &&
                                      template.buttons.slice(0, 2).map((button, index) => (
                                        <div key={index}>

                                          {index > 0 && <hr className="my-2" />}

                                          {button.type === 1 && (
                                            <p className="text-center text-info my-0">
                                              <BIcon icon="reply-fill" size={5} className="me-2" />
                                              {button.text}
                                            </p>
                                          )}
                                          {button.type === 4 && (
                                            <p className="text-center text-info my-0">
                                              <BIcon icon="copy" size={7} className="me-2" />
                                              {button.text}
                                            </p>
                                          )}
                                          {button.type === 2 && (
                                            <p className="text-center text-info my-0">
                                              <BIcon icon="box-arrow-up-right" size={8} className="fw-bold me-2" />
                                              {button.text}
                                            </p>
                                          )}
                                          {button.type === 3 && (
                                            <p className="text-center text-info my-0">
                                              <BIcon icon="telephone-fill" size={7} className="me-2" />
                                              {button.text}
                                            </p>
                                          )}

                                          {index === 1 && (
                                            <div>
                                              <hr className="my-2" />
                                              <p className="text-center text-info my-0">
                                                <BIcon icon="list-ul" size={5} className="me-2" />Ver todas las opciones
                                              </p>
                                            </div>
                                          )}
                                        </div>
                                      ))
                                    }
                                  </div>
                                </div>
                              </div>
                            </CCardBody>
                          </CCard>
                        }
                      >
                        <div
                          tabIndex={0}
                          className="p-2 bg-success m-0 my-1 cursor-pointer rounded bg-light"
                          onClick={() => { setSelectTemplateData(template) }}
                        >
                          <CFormCheck
                            id={`checkSaveSource_${template.id}`}
                            type="radio"
                            name="template"
                            className="fs-7 w-100 single-line"
                            label={template.name}
                            // label={template.name.substring(10)}
                            checked={template.id === selectTemplateData?.id}
                            onChange={() => { setSelectTemplateData(template) }}
                          />
                        </div>
                      </CPopover>
                    </div>
                  </CCol>
                ))}
              </CRow>


              {selectTemplateData?.id &&
                <>
                  <hr />


                  <CRow className="mt-4">
                    <CCol sm={12}>

                      <CRow className="mb-4">
                        <CCol md={6}>
                          <CFormInput
                            value={formData.name}
                            type="text"
                            name="name"
                            placeholder="Bienvenida a usuarios"
                            feedbackValid="Luce bien!"
                            feedbackInvalid="Por favor escriba un nombre valido."
                            id="validationCustom02"
                            label="Nombre"
                            onChange={handleChange}
                            required
                          />
                        </CCol>
                        <CCol md={6}>
                          <CFormInput
                            value={formData.description}
                            type="text"
                            name="description"
                            placeholder="Realizar bienvenida a usuarios nuevos registrados"
                            feedbackValid="Luce bien!"
                            feedbackInvalid="Por favor escriba una descripción valido."
                            id="validationCustom02"
                            label="Descripción (Opcional)"
                            onChange={handleChange}
                            required
                          />
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol md={12}>
                          {/* Header */}
                          <div className="p-3 bg-gray mt-3 rounded-3">
                            {(selectTemplateData?.header_content != null && selectTemplateData?.header_content != "") &&
                              <>
                                <h6 className="mb-3">Variables para el encabezado</h6>
                                {selectTemplateData?.header_format == 'IMAGE' &&
                                  <div className="mb-2">
                                    <CFormInput
                                      id="imageFile"
                                      type="file"
                                      accept=".png, .jpeg"
                                      className="form-control"
                                      label="Subir imagen:"
                                      onChange={(e) => handleFileChange(e.target.files)}
                                      text="Tamaño máximo permitido 2MB"
                                      ref={fileInputMediaRef}
                                    />
                                  </div>
                                }

                                {selectTemplateData?.header_format == 'VIDEO' &&
                                  <div className="mb-2">
                                    <CFormInput
                                      id="videoFile"
                                      type="file"
                                      accept=".3gp, .mp4"
                                      className="form-control"
                                      label="Subir video:"
                                      onChange={(e) => handleFileChange(e.target.files)}
                                      text="Tamaño máximo permitido 16MB"
                                      ref={fileInputMediaRef}
                                    />
                                  </div>
                                }
                              </>
                            }
                            {headerVariables.length > 0 &&
                              <CRow>
                                <CCol xs={12} sm={12} md={6} lg={8} xl={9} xxl={9}>

                                  {headerVariables.map((variable, index) => {
                                    return (
                                      <CRow className="mb-2" key={index}>
                                        <CCol xs={2} sm={2} md={2} lg={2} xl={1} xxl={1} className="text-end pe-0 fs-6 py-1">{variable}</CCol>
                                        <CCol xs={10} sm={10} md={10} lg={10} xl={11} xxl={11}>
                                          <CFormInput
                                            id={`header_${index}`}
                                            onFocus={handleFocus}
                                            value={headerVariablesData[index]}
                                            onChange={(e) => { handleChangeValueVariableHeader(index, e.target.value) }}
                                          /></CCol>
                                      </CRow>
                                    )
                                  })}
                                </CCol>

                                <CCol xs={12} sm={12} md={6} lg={4} xl={3} xxl={3}>
                                  <CCard className="h-100 text-center p-3 justify-content-center">

                                    <CButton
                                      color="info"
                                      variant="outline"
                                      className="mb-2"
                                      value={`{first_name}`}
                                      name="{first_name}"
                                      onClick={handleClick}
                                    >{`{first_name}`}</CButton>

                                    <CButton
                                      color="info"
                                      variant="outline"
                                      className="mb-0"
                                      value={`{last_name}`}
                                      name={`{last_name}`}
                                      onClick={handleClick}
                                    >{`{last_name}`}</CButton>

                                  </CCard>
                                </CCol>
                              </CRow>
                            }
                          </div>
                          {/* Body */}
                          {bodyVariables.length > 0 &&

                            <div className="p-3 bg-gray mt-3 rounded-3">
                              <CRow>
                                <CCol xs={12} sm={12} md={6} lg={8} xl={9} xxl={9}>
                                  {bodyVariables.length > 0 &&
                                    <h6 className="mb-3 ">Variables para el mensaje</h6>
                                  }
                                  {bodyVariables.map((variable, index) => {
                                    return (
                                      <CRow className="mb-2" key={index}>
                                        <CCol xs={2} sm={2} md={2} lg={2} xl={1} xxl={1} className="text-end pe-0 fs-6 py-1">{variable}</CCol>
                                        <CCol xs={10} sm={10} md={10} lg={10} xl={11} xxl={11}>
                                          <CFormInput
                                            id={`body_${index}`}
                                            onFocus={handleFocus}
                                            value={bodyVariablesData[index]}
                                            onChange={(e) => { handleChangeValueVariableBody(index, e.target.value) }}
                                          />
                                        </CCol>
                                      </CRow>
                                    )
                                  })}
                                </CCol>

                                <CCol xs={12} sm={12} md={6} lg={4} xl={3} xxl={3}>
                                  <CCard className="h-100 text-center p-3 justify-content-center">

                                    <CButton
                                      color="info"
                                      variant="outline"
                                      className="mb-2"
                                      value={`{first_name}`}
                                      name={`{first_name}`}
                                      onClick={handleClick}
                                    >{`{first_name}`}</CButton>

                                    <CButton
                                      color="info"
                                      variant="outline"
                                      className="mb-0"
                                      value={`{last_name}`}
                                      name={`{last_name}`}
                                      onClick={handleClick}
                                    >{`{last_name}`}</CButton>

                                  </CCard>
                                </CCol>
                              </CRow>
                            </div>
                          }
                        </CCol>





                      </CRow>


                      {/* Buttons */}
                      {/* {buttons.length > 0 &&
          <h6 className="mb-3 mt-4">Variables para botones</h6>
        } */}
                      {/* 
        {buttons.map((button, index) => {
          button.content.includes('{{1}}') ?
        
          return (
            <CRow className="mb-2" key={index}>
              <CCol sm={12}>
                <CFormInput
                  placeholder={button.example}
                  minLength={1}
                  label={button.content}
                  value={button.example}
                  onChange={(e) => { updateExampleButton(index, e.target.value) }}
                  text={(button.content ? button.content.replaceAll('{{1}}', button.example ?? "") : '')}
                />
              </CCol>
            </CRow>)
          :""
        })} */}

                      {buttons.map((button, index) => (
                        button.content.includes('{{1}}') ? (
                          <>
                            {titleButtonsShow &&
                              <h6 className="mb-3 mt-4">Variables para botones</h6>
                            }
                            {titleButtonsShow = false}

                            <CRow className="mb-3 bg-light rounded p-1 mx-1" key={index}>
                              <CCol sm={12}>
                                <CFormInput
                                  placeholder={`Enter value for ${button.content}`}
                                  minLength={1}
                                  label={button.content}
                                  value={button.example}
                                  onChange={(e) => updateExampleButton(index, e.target.value)}
                                  text={button.content ? button.content.replaceAll('{{1}}', button.example ?? '') : ''}
                                />
                              </CCol>
                            </CRow>

                          </>
                        ) : null
                      ))}

                    </CCol>

                  </CRow>




                  <CRow>

                    <CCol md={3}>
                      <CFormCheck
                        id="checkSaveSource"
                        className="mb-2 mt-5"
                        type="checkbox"
                        checked={scheduleCampaign}
                        onChange={() => setScheduleCampaign(!scheduleCampaign)}
                        label="Programar campaña"
                      />
                    </CCol>
                    <CCol md={9}></CCol>
                  </CRow>


                  {scheduleCampaign && (
                    <CDatePicker
                      className="mb-2"
                      id="scheduled_date"
                      locale="en-US"
                      timepicker
                      format="yyyy-MM-dd HH:mm"
                      onDateChange={handleDateChange}
                    />
                  )}
                </>
              }
            </CCol>
            <CCol sm={4}>
              <CCard className="text-center">
                <CCardHeader className="text-start">
                  <strong>Vista previa de la plantilla</strong>
                  <p className="text-info fs-7 m-0">{selectTemplateData?.name}</p>
                </CCardHeader>
                <CCardBody className="pt-0" style={{
                  backgroundImage: `linear-gradient(rgba(234, 218, 201, 0.8), rgba(234, 218, 201, 0.8)), url(${backgroundChat})`, // Aplica opacidad solo a la imagen de fondo
                  backgroundSize: '100% auto', // Ajusta el tamaño para que el ancho de la imagen ocupe todo el contenedor y la altura se ajuste proporcionalmente
                  backgroundPosition: 'center', // Centra la imagen dentro del contenedor
                }}>
                  <div className="d-flex flex-column mb-4">
                    <div className="position-relative" style={{ width: 0, height: 0, borderTop: '8px solid transparent', borderBottom: '8px solid transparent', borderRight: '8px solid #fff', position: 'absolute', left: '-4px', top: '11.5px', transform: "rotate(135deg)" }}></div>
                    <div className="bg-white rounded-end-3 rounded-bottom-3 pb-2 shadow text-start" style={{ maxWidth: "90%", minWidth: "25%", position: 'relative', minHeight: "42px" }}>
                      <div className="m-1">
                        {selectTemplateData?.header_format == 'TEXT' && <p className="m-0 fs-7 fw-bolder">{selectTemplateData.header_content}</p>}
                        {selectTemplateData?.header_format != 'TEXT' && (
                          <>
                            {selectTemplateData?.header_format == 'IMAGE' ? (
                              <CImage src={previewUrl ?? selectTemplateData?.header_content} className="rounded-2 w-100" />
                            ) : selectTemplateData?.header_format == 'VIDEO' ? (
                              <CustomVideo key={videoKey} src={previewUrl ?? selectTemplateData?.header_content} className="rounded-2 w-100" />
                            ) : (
                              // <p>Preview no disponible para este tipo de archivo</p>
                              <p></p>
                            )}
                          </>
                        )}
                        {/* {!previewUrl &&
                          <div className="bg-dark rounded-2 text-center">
                            {headerType == 2 && <CImage src={image} className="w-25 my-0 py-5" />}
                            {headerType == 3 && <CImage src={video} className="w-25 my-0 py-5" />}
                            {headerType == 4 && <CImage src={document} className="w-25 my-0 py-5" />}
                            {headerType == 5 && <CImage src={location} className="w-25 my-0 py-5" />}
                          </div>
                        } */}
                        <p className="m-0 fs-7 mt-2 text-black">{formatText(bodyTextPreview != "" ? bodyTextPreview : (selectTemplateData?.body_content ?? ""))}</p>
                        <p className="m-0 fs-8 mt-2 text-gray">{selectTemplateData?.footer_content}</p>

                        <p className="p-0 fs-9 text-end m-0 text-gray" style={{ bottom: '2px', right: '10px' }}>{formatTime(new Date())}</p>
                      </div>
                      {(selectTemplateData?.buttons != undefined && selectTemplateData?.buttons.length > 0) && <hr className="my-2" />}
                      <div className={`px-${(selectTemplateData?.buttons != undefined && selectTemplateData?.buttons.length > 0) ? 2 : 0}`}>
                        {(selectTemplateData?.buttons != undefined && selectTemplateData?.buttons.length < 4) &&
                          selectTemplateData.buttons.map((button, index) => (
                            <div key={index}>
                              {index > 0 && <hr className="my-2" />}

                              {button.type === 1 && (
                                <p className="text-center text-info my-0">
                                  <BIcon icon="reply-fill" size={5} className="me-2" />
                                  {button.text}
                                </p>
                              )}
                              {button.type === 4 && (
                                <p className="text-center text-info my-0">
                                  <BIcon icon="copy" size={7} className="me-2" />
                                  {button.text}
                                </p>
                              )}
                              {button.type === 2 && (
                                <p className="text-center text-info my-0">
                                  <BIcon icon="box-arrow-up-right" size={8} className="fw-bold me-2" />
                                  {button.text}
                                </p>
                              )}
                              {button.type === 3 && (
                                <p className="text-center text-info my-0">
                                  <BIcon icon="telephone-fill" size={7} className="me-2" />
                                  {button.text}
                                </p>
                              )}
                            </div>
                          ))
                        }
                        {(selectTemplateData?.buttons != undefined && selectTemplateData?.buttons.length > 3) &&
                          selectTemplateData?.buttons.slice(0, 2).map((button, index) => (
                            <div key={index}>

                              {index > 0 && <hr className="my-2" />}

                              {button.type === 1 && (
                                <p className="text-center text-info my-0">
                                  <BIcon icon="reply-fill" size={5} className="me-2" />
                                  {button.text}
                                </p>
                              )}
                              {button.type === 4 && (
                                <p className="text-center text-info my-0">
                                  <BIcon icon="copy" size={7} className="me-2" />
                                  {button.text}
                                </p>
                              )}
                              {button.type === 2 && (
                                <p className="text-center text-info my-0">
                                  <BIcon icon="box-arrow-up-right" size={8} className="fw-bold me-2" />
                                  {button.text}
                                </p>
                              )}
                              {button.type === 3 && (
                                <p className="text-center text-info my-0">
                                  <BIcon icon="telephone-fill" size={7} className="me-2" />
                                  {button.text}
                                </p>
                              )}

                              {index === 1 && (
                                <div>
                                  <hr className="my-2" />
                                  <p className="text-center text-info my-0">
                                    <BIcon icon="list-ul" size={5} className="me-2" />Ver todas las opciones
                                  </p>
                                </div>
                              )}
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>




        </CCardBody>
        <CCardFooter>
          <CButton color="primary" type="submit" disabled={selectTemplateData ? false : true} onClick={onHandleSubmit}>
            Enviar
          </CButton>
        </CCardFooter>
      </CCard>
    </div>
  );
};
