import React from "react";
import {
    CCard,
    CCardHeader,
    CCardBody
} from "@coreui/react-pro";

import SwaggerDocs from "../../components/swagger/SwaggerDocs";

interface ApisProps { }

export const Apis: React.FC<ApisProps> = ({ }: ApisProps) => {

    return (
        <div className="col-12">
            <CCard className="mb-4">
                <CCardHeader>
                    <strong>Documentación de Apis</strong>
                </CCardHeader>
                <CCardBody>
                    <SwaggerDocs />
                </CCardBody>
            </CCard>
        </div>
    );
};
