import React from 'react';

interface VideoProps {
    src: string;
    className?: string;
    onClick?: any;
    style?: any;
    controls?: any
}

const CustomVideo: React.FC<VideoProps> = ({ src, className, onClick, style, controls }) => {
    style = { ...style }
    return (
        <video autoPlay muted loop controls={controls} className={className} onClick={onClick} style={style}>
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    )
};

export default CustomVideo;
