export const getSegments = (body: string) => {
    // Detectar la codificación del mensaje
    const isUnicode = /[^\x00-\x7F]/.test(body);

    // Contar los caracteres según la codificación
    let segmentCount = 0;
    if (isUnicode) {
        segmentCount = Math.ceil(body.length / 70);
    } else {
        segmentCount = Math.ceil(body.length / 160);
    }

    return segmentCount;
}