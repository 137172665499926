import React, { useEffect, useState } from "react";
import {
  CBadge,
  CButton,
  CSmartTable,
  CCard,
  CCardHeader,
  CCardBody,
  CTabContent,
  CTabPane,
} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import * as MarketsmsService from "../../services/MarketsmsService";
import { AccountsItems } from "../../models/Accounts";
import { cilInfo } from "@coreui/icons";
import { Link } from "react-router-dom";
import { TransactionsHistory } from "../../models/Payments";
import { getBadgeTransactionStatus, getCurrentAccount } from "../../Utils/BaseApp";
import { BIcon } from "../../components/icons/BIcon";

interface TransactionsProps { }

export const Transactions: React.FC<
  TransactionsProps
> = ({ }: TransactionsProps) => {
  const [transactions, setTransactions] = useState<TransactionsHistory[] | null>(null);

  const getTransactionsHistory = async () => {
    const current_account = await getCurrentAccount();

    var response = null;
    if (current_account) {
      response = await MarketsmsService.getTransactionsByAccount();
    } else {
      response = await MarketsmsService.getTransactions();
    }
    if (response) {
      if (response.status === 200) {
        setTransactions(response.data.items);
      }
    }
  };

  useEffect(() => {
    getTransactionsHistory();
    console.log("Imprimir");
  }, []);

  const columns = [
    {
      key: "id",
      _style: { width: "5%" },
    },
    {
      key: "amount",
      label: "Monto",
      _style: { width: "10%" },
    },
    {
      key: "tax_amount",
      label: "Impuetos",
      _style: { width: "10%" },
    },
    {
      key: "total_amount",
      label: "Total",
      _style: { width: "10%" },
    },
    {
      key: "payment_method_name",
      label: "Método de pago",
      _style: { width: "15%" },
    },
    {
      key: "description",
      label: "Descripción",
      _style: { width: "15%" },
    },
    {
      key: "created_at",
      label: "Fecha",
      _style: { width: "15%" },
    },
    {
      key: "payment_status_name",
      label: "Estado",
      _style: { width: "10%" },
    },
    {
      key: "actions",
      label: "Acción",
      _style: { width: "15%" },
    },
  ];


  return (
    <div className="col-12">
      <CCard className="mb-4">
        <CCardHeader>
          <strong>Transacciones</strong>
        </CCardHeader>
        <CCardBody>
          <CTabContent className="rounded-bottom">
            <CTabPane
              className="p-3 active preview show"
              role="tabpanel"
              id="preview-578"
            >
              <CSmartTable
                activePage={2}
                cleaner
                clickableRows
                columns={columns}
                // columnFilter
                columnSorter
                // footer
                loading={transactions === null ? true : false}
                items={transactions ?? []}
                itemsPerPageSelect
                itemsPerPage={10}
                pagination
                onFilteredItemsChange={(items) => {
                  // console.log(items)
                }}
                onSelectedItemsChange={(items) => {
                  // console.log(items)
                }}
                scopedColumns={{
                  payment_status_name: (transaction: TransactionsHistory) => (
                    <td>
                      <CBadge color={getBadgeTransactionStatus(transaction.payment_statuses_id)}>
                        {transaction.payment_status_name}
                      </CBadge>
                    </td>
                  ),
                  actions: (account: AccountsItems) => {
                    return (
                      <td className="py-2 text-center">
                        <Link to={"#"}>
                          <CButton
                            color="primary"
                            variant="outline"
                            shape="square"
                            className="m-1"
                            size="sm"
                            title="Ver más"
                          // onClick={() => {showDetails(message); }}
                          >
                            <BIcon icon={"eye"} />
                          </CButton>
                        </Link>
                      </td>
                    );
                  },
                }}
                // selectable
                sorterValue={{ column: "status", state: "asc" }}
                tableFilter
                tableProps={{
                  className: "add-this-class",
                  responsive: true,
                  striped: true,
                  hover: true,
                }}
                tableBodyProps={{
                  className: "align-middle",
                }}
              />
            </CTabPane>
          </CTabContent>
        </CCardBody>
      </CCard>
    </div>
  );
};
