import React, { useEffect, useRef, useState } from "react";
import {
  CCard,
  CCardHeader,
  CCardBody,
  CRow,
  CCol,
  CBadge,
  CHeaderDivider,
  CFormTextarea,
  CButton,
  CFormInput,
  CCardFooter,
  CImage,
  CAvatar,
  CPopover,
  CModal,
} from "@coreui/react-pro";
import * as MarketsmsService from "../../services/MarketsmsService";
import { ConversationsDataModel, ConversationsItems } from "../../models/Conversations";
// import io from 'socket.io-client';
// import { Server } from "socket.io";
// import { Server } from "socket.io";
// const io = new Server(Server);
// const { io } = require("socket.io");
// import { io } from "socket.io-client";
import '../../scss/_whatsapp_messages.scss'


// const io = require('socket.io')();

// const { io } = require("socket.io-client");
// import { io } from "socket.io-client";
import { ConversationMessagesItems } from "../../models/ConversationMessages";
import { url } from "inspector";
// const socket = io('https://api.marketsms.co', {
//   transports: ['websocket']
// });
import background from "../../assets/whatsapp/background.png";
// import background from "../../assets/whatsapp/background_whatsapp.png";
import { db, messaging } from "../../firebase/FirebaseConfig";
import { moveMessagePortToContext } from "worker_threads";
import { env } from "../../Utils/Environment";
import mqtt from 'mqtt';
import { getCurrentAccountId, getDefaultPhoneNumber, getDeviceId, showToastTC } from "../../Utils/BaseApp";
import CIcon from "@coreui/icons-react";
import { BIcon } from "../../components/icons/BIcon";
import { format, parseISO, isToday, isYesterday } from 'date-fns';
import { cilSend, cilTrash } from "@coreui/icons";
import CustomAudioPlayer from "../../components/audio/CustomAudioPlayer";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { toggler } from "../../redux/selectors";
import CustomVideo from "../../components/video/CustomVideo";
import { useNavigate } from "react-router-dom";
import { collection, onSnapshot, query, where } from "firebase/firestore";

interface WhatsappConversationsProps { }


export const WhatsappConversations: React.FC<WhatsappConversationsProps> = ({ }: WhatsappConversationsProps) => {
  const [conversations, setConversations] = useState<ConversationsItems[] | null>(null);
  const [conversationsMessages, setConversationsMessages] = useState<ConversationMessagesItems[] | null>(null);
  const [selectConversations, setSelectConversations] = useState<ConversationsItems | null>(null);
  const [usedLabels, setUsedLabels] = useState(new Set());
  const [usedDate, setUsedDate] = useState<string[]>([]);
  const datesArray: string[] = [];
  const [dataRecord, setDataRecord] = useState({ icon: "mic-fill", color: 'dark' });
  const [reloadConversations, setReloadConversations] = useState(true);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { unfoldable } = useAppSelector(toggler)

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  // Define el número máximo de filas
  const maxRows = 5;


  // const { data, loading, error } = useFirestoreCollection('chats');

  // console.log(data);
  // console.log(loading);
  // console.log(error);



  // const [data, setData] = useState<DocumentData[]>([]);
  // const [loading, setLoading] = useState<boolean>(true);
  // const [error, setError] = useState<string | null>(null);



  useEffect(() => {
    const local_phone_number_id = getDefaultPhoneNumber();
    if (local_phone_number_id != null) {
      // getConversations(local_phone_number_id);
      // Obtener referencia a la colección de Firestore
      const colRef = collection(db, 'chats');
      // Crear la consulta con la condición `where`
      const q = query(
        colRef,
        where('phone_number_id', '==', local_phone_number_id)  // Cambia 'status' y 'active' según tu esquema
      );

      // Configurar el listener en la consulta
      const unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          console.log("Ingresa aqui");
          setTimeout(() => {
            getConversations(local_phone_number_id);
          }, selectConversations?.id ? 700 : 0);
        },
        (err) => {
          // setError(err.message);
          // setLoading(false);
        }
      );
      // Limpieza del listener cuando el componente se desmonte
      return () => unsubscribe();
    }
  }, []); // El array de dependencias vacío asegura que el efecto se ejecute solo una vez



  useEffect(() => {
    if (selectConversations) {
      selectConversations.unread = 0;
      // conversation.unread = 0;
      const local_phone_number_id = getDefaultPhoneNumber();
      if (local_phone_number_id != null) {
        // getConversationMessages(selectConversations.id);
        const colRef = collection(db, 'conversations');
        // Crear la consulta con la condición `where`
        const q = query(
          colRef,
          where('conversation_id', '==', selectConversations.id)  // Cambia 'status' y 'active' según tu esquema
        );

        // Configurar el listener en la consulta
        const unsubscribe = onSnapshot(
          q,
          (snapshot) => {
            getConversationMessages(selectConversations.id);
          },
          (err) => {
            // setError(err.message);
            // setLoading(false);
          }
        );
        // Limpieza del listener cuando el componente se desmonte
        return () => unsubscribe();
      }
    }
  }, [selectConversations]);

  const getConversations = async (local_phone_number_id: number) => {
    const account_id = await getCurrentAccountId();
    MarketsmsService.getConversations(local_phone_number_id, account_id).then((response) => {
      if (response.status === 200) {
        setConversations(response.data.items);
      }
    });
  }

  const getConversationMessages = async (local_conversation_id: number) => {
    const account_id = await getCurrentAccountId();
    const phone_number_id = getDefaultPhoneNumber();

    if (phone_number_id != null) {
      MarketsmsService.getConversationMessages(phone_number_id, local_conversation_id, account_id).then((response) => {
        if (response.status === 200) {
          setConversationsMessages(response.data.items);
        }
      });
    } else {
      showToastTC("No contiene un numero de telefono por defecto, seleccionalo por favor", 5000, "error");
      setTimeout(() => {
        navigate("/whatsapp/numbers");
      }, 5000);
    }
  }


  // conversation
  const unread = 1;
  const [message, setMessage] = useState<string>("");


  // const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
  //   if (e.key === 'Enter') {
  //     e.preventDefault(); // Para prevenir el comportamiento por defecto (como enviar un formulario)

  //     sendIndividualMessage();
  //   }
  // };

  // const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
  //   const textarea = e.target as HTMLTextAreaElement;
  //   console.log(e);
  //   console.log(e.key);
  //   console.log(e.ctrlKey);
  //   if (e.key === 'Enter') {
  //     if (e.ctrlKey) {
  //       // Ctrl+Enter: Insertar un salto de línea
  //       const cursorPosition = textarea.selectionStart ?? 0;

  //       const newMessage = message.slice(0, cursorPosition) + '\n' + message.slice(cursorPosition);
  //       setMessage(newMessage);
  //       e.preventDefault(); // Evita el comportamiento por defecto de Enter
  //     } else {
  //       // Enter (sin Ctrl): Enviar el mensaje
  //       e.preventDefault(); // Evita el comportamiento por defecto de Enter (salto de línea)
  //       // sendMessage();
  //       sendIndividualMessage();
  //     }
  //   }
  // };



  // useEffect(() => {
  //   // Ajustar la altura del textarea cuando cambia el contenido
  //   if (textareaRef.current) {
  //     textareaRef.current.style.height = 'auto'; // Restablece la altura
  //     textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Ajusta al contenido
  //   }
  // }, [message]);

  // const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
  //   const textarea = e.target as HTMLTextAreaElement;

  //   if (e.key === 'Enter') {
  //     if (e.ctrlKey) {
  //       // Ctrl+Enter: Insertar un salto de línea
  //       e.preventDefault(); // Evita el salto de línea por defecto
  //       const cursorPosition = textarea.selectionStart ?? 0;
  //       const newMessage = message.slice(0, cursorPosition) + '\n' + message.slice(cursorPosition);
  //       setMessage(newMessage);
  //       // Ajustar el tamaño del textarea
  //       if (textareaRef.current) {
  //         textareaRef.current.style.height = 'auto'; // Restablecer altura
  //         textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Ajustar altura
  //       }
  //     } else {
  //       // Enter (sin Ctrl): Enviar el mensaje
  //       e.preventDefault(); // Evita el salto de línea por defecto
  //       // sendMessage();
  //     }
  //   }
  // };




  useEffect(() => {
    // Ajustar la altura del textarea cuando cambia el contenido
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'; // Restablece la altura
      const scrollHeight = textareaRef.current.scrollHeight;
      const lineHeight = parseInt(window.getComputedStyle(textareaRef.current).lineHeight, 10);
      const maxHeight = lineHeight * maxRows; // Altura máxima permitida

      // Aplica la altura máxima
      textareaRef.current.style.height = `${Math.min(scrollHeight, maxHeight)}px`;
    }
  }, [message]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const textarea = e.target as HTMLTextAreaElement;

    if (e.key === 'Enter') {
      if (e.ctrlKey) {
        // Ctrl+Enter: Insertar un salto de línea
        e.preventDefault(); // Evita el salto de línea por defecto
        const cursorPosition = textarea.selectionStart ?? 0;
        const newMessage = message.slice(0, cursorPosition) + '\n' + message.slice(cursorPosition);
        setMessage(newMessage);
        // Ajustar el tamaño del textarea
        if (textareaRef.current) {
          textareaRef.current.style.height = 'auto'; // Restablecer altura
          const scrollHeight = textareaRef.current.scrollHeight;
          const lineHeight = parseInt(window.getComputedStyle(textareaRef.current).lineHeight, 10);
          const maxHeight = lineHeight * maxRows; // Altura máxima permitida
          textareaRef.current.style.height = `${Math.min(scrollHeight, maxHeight)}px`; // Ajustar altura
        }
      } else {
        // Enter (sin Ctrl): Enviar el mensaje
        e.preventDefault(); // Evita el salto de línea por defecto
        sendIndividualMessage();
      }
    }
  };

  const sendIndividualMessage = async () => {
    const sms = message;
    if (sms.length > 0) {
      setMessage("");
      if (selectConversations?.id) {
        // setConversationsMessages
        if (conversationsMessages) {
          setConversationsMessages([...conversationsMessages, {
            id: 123,
            type: "outbound",
            body: sms,
            variables: "string",
            media_url: "",
            whatsapp_templates_id: 0,
            service_types_id: 222,
            whatsapp_statuses_id: 1,
            campaigns_id: null,
            template: null,
            created_at: getCurrentDateTime(),
            updated_at: getCurrentDateTime()
          }]);
        };

        const account_id = await getCurrentAccountId();

        MarketsmsService.sendIndividualMessage(selectConversations.id, account_id, sms).then((response) => {
          console.log("Recupera mensajes");
          var local_phone_number_id = getDefaultPhoneNumber();
          if (local_phone_number_id) {
            getConversations(local_phone_number_id);
          }
          getConversationMessages(selectConversations.id);
        });

      }
    }
  }

  const getCurrentDateTime = (): string => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    console.log("getCurrentDateTime");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };


  const cardBodyRef = useRef<HTMLDivElement>(null); // Definir explícitamente el tipo de referencia

  useEffect(() => {
    // Función para hacer scroll al final del CCardBody
    const scrollToBottom = () => {
      if (cardBodyRef.current) {
        cardBodyRef.current.scrollTop = cardBodyRef.current.scrollHeight;
      }
    };

    // Llamar a la función cada vez que conversationsMessages se actualice
    scrollToBottom();
  }, [conversationsMessages]); // Dependencia que activa el efecto cuando conversationsMessages cambia

  const [recordingState, setRecordingState] = useState<string>("stop");
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [time, setTime] = useState<number>(0);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  var mediaRecorderRefPause = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);


  const [audioBase64, setAudioBase64] = useState<string | null>(null);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);

  const startRecording = async () => {
    if (recordingState === 'pause') {
      console.log("Entra aqui por que esta pausado");
      // Reanudar la grabación
      mediaRecorderRef.current?.resume();
      setRecordingState('start');
      startTimer();
    } else {
      // Iniciar una nueva grabación
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: 'audio/webm' });
        mediaRecorderRef.current.ondataavailable = (event) => {
          console.log("Data available");
          console.log(event);
          audioChunksRef.current.push(event.data);
        };
        mediaRecorderRef.current.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
          const audioUrl = URL.createObjectURL(audioBlob);
          setAudioUrl(audioUrl);
          audioChunksRef.current = [];
        };

        mediaRecorderRef.current.onpause = () => {
          const availableData = mediaRecorderRef?.current?.requestData();
          console.log(availableData);
          if (availableData !== undefined) {
            audioChunksRef.current.push(availableData);
          }

          setTimeout(() => {

            console.log("Pausado");
            console.log(mediaRecorderRef.current?.requestData);
            const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
            console.log(audioBlob);
            const audioUrl = URL.createObjectURL(audioBlob);
            setAudioUrl(audioUrl);
            // audioChunksRef.current = []; // Reset the chunks array for the next recording
          }, 2000);

        };
        // TODO::STOP
        mediaRecorderRef.current.start();
        setRecordingState('start');
        setTime(0);
        audioChunksRef.current = [];  // Limpiar los chunks anteriores
        startTimer();
      } catch (err) {
        console.error('Error accessing media devices.', err);
      }
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
    }
    setRecordingState('stop');
    stopTimer();
  };

  const pauseRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.pause();
    }
    setRecordingState('pause');
    stopTimer();
  };

  // Limpiar el objeto URL cuando el componente se desmonte o cuando cambie audioUrl
  useEffect(() => {
    return () => {
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }
    };
  }, [audioUrl]);

  const startTimer = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);
  };

  const stopTimer = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }
    };
  }, [audioUrl]);

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const recordingHandle = () => {
    if (recordingState === 'start') {
      pauseRecording();
    } else if (recordingState === 'pause') {
      startRecording();
    } else {
      startRecording();
    }
  };


  const formatText = (text: string) => {
    let formattedText = text;
    formattedText = formattedText.replace(/\*([^\*]+)\*/g, '<strong>$1</strong>');
    formattedText = formattedText.replace(/_([^_]+)_/g, '<em>$1</em>');
    formattedText = formattedText.replace(/~([^~]+)~/g, '<del>$1</del>');
    formattedText = formattedText.replace(/```([^`]+)```/g, '<code style="color:inherit">$1</code>');
    formattedText = formattedText.replace(/\n/g, '<br/>'); // Convertir saltos de línea a <br/>
    return <p className="m-0 fs-8 mt-2 text-black" dangerouslySetInnerHTML={{ __html: formattedText }} />;
  };

  function formatTimeTemplate(date: any) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amOrPm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12; // Convertir a formato de 12 horas
    return `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${amOrPm}`;
  }


  const lightColors = [
    '#FFB6C1', // LightPink
    '#FFDEAD', // NavajoWhite
    '#FFE4B5', // Moccasin
    '#F5DEB3', // Wheat
    '#F5F5DC', // Beige
    '#F0E68C', // Khaki
    '#E6E6FA', // Lavender
    '#FFFACD', // LemonChiffon
    '#FAFAD2', // LightGoldenrodYellow
    '#FFEFD5', // PapayaWhip
    '#F0FFF0', // HoneyDew
    '#F0F8FF', // AliceBlue
    '#E0FFFF', // LightCyan
    '#F5F5F5', // WhiteSmoke
    '#F8F8FF', // GhostWhite
    '#FFFAF0', // FloralWhite
    '#F5F5F0', // Ivory
    '#E8E8E8', // LightGray
    '#D3D3D3', // LightGray
    '#DCDCDC', // Gainsboro
    '#DFF0D8', // LightGreen
  ];

  // const randomColor = () => {
  //   return lightColors[Math.floor(Math.random() * lightColors.length)];
  // };

  // const getColorForIndex = (index: number) => lightColors[index % lightColors.length];
  const getColorForIndex = (index: number) => lightColors[index % lightColors.length];

  const [visibleMedia, setVisibleMedia] = useState(false);
  const [srcMedia, setSrcMedia] = useState("");

  const showMedia = (media: string) => {
    setSrcMedia(media);
    setVisibleMedia(true);
  }

  return (
    <>
      <CCard className="vh-84 mb-1" >
        <CCardHeader>
          <strong>Conversaciones de WhatsApp</strong>
        </CCardHeader>
        <CCardBody className="d-flex p-0 h-75">

          <CCol md={3} className="custom-overflow-y">

            <CRow className="m-0 h-100">
              {/* <CCol md={2} className="bg-gray p-0">
                <CPopover
                  placement="right"
                  trigger={['hover', 'focus']}
                  className="px-4 py-1"
                  style={{ background: "black" }}
                  content={
                    <span className="text-white ">Chats</span>
                  }
                >
                  <div className="d-flex py-3 cursor-pointer">
                    <CAvatar color="info" textColor="white" className="mx-auto" size="md">
                      <BIcon icon="chat-quote" className="fs-6 m-0 p-0" />
                    </CAvatar>
                  </div>

                </CPopover>

                <CPopover
                  placement="right"
                  trigger={['hover', 'focus']}
                  className="px-4 py-1 rounded-pill"
                  style={{ background: "black" }}
                  content={
                    <span className="text-white rounded-pill">Números</span>
                  }
                >
                  <div className="d-flex py-3 cursor-pointer">
                    <CAvatar color="light" textColor="black" className="mx-auto" size="md" >
                      <BIcon icon="telephone" className="fs-6 m-0 p-0" />
                    </CAvatar>
                  </div>
                </CPopover>
              </CCol> */}

              <CCol md={12} className="p-0">
                {conversations?.map((conversation, index) => (
                  <div key={index} onClick={() => { setSelectConversations(conversation) }} className="mx-2">
                    <CRow className="cursor-pointer pb-2 pt-2">
                      <CCol xs={2}>
                        <CAvatar textColor="black" className="mx-auto" style={{ background: getColorForIndex(index) }} size="md" >
                          <p className="fs-6 m-0 p-0">{(conversation.first_name ?? conversation.number).toString().substring(0, 2).toUpperCase()}</p>
                        </CAvatar>
                      </CCol>

                      <CCol xs={10}>
                        <CRow>
                          <CCol xs={10} className="ps-1 pe-0"><p className="p-0 m-0">{conversation.first_name ?? conversation.number}</p> </CCol>
                          <CCol xs={2} className="ps-0 pe-1"> <p className="text-muted m-0 p-0 fs-8 text-end px-0"> {conversation.message_date ? conversation.message_date.substring(10, 16) : ""}</p></CCol>
                        </CRow>
                        <CRow>
                          <CCol xs={10} className="ps-1 pe-0 d-flex align-items-center">
                            {conversation.type === 'outbound' &&
                              <BIcon icon={`check2${conversation.message_status === 4 || conversation.message_status === 6 ? '-all' : ''}`} className={`m-0 me-1 text-${conversation.message_status === 6 ? 'info' : 'dark'}`} />
                            }

                            {conversation.media_url && conversation.media_url != '' &&
                              <BIcon icon={`camera-fill`} className={`m-0 ms-1 me-2 text-danger`} />
                            }
                            <p className="text-muted fs-7 m-0 single-line">{conversation.last_message && conversation.last_message != "" ? conversation.last_message : "Archivo adjunto"}</p>
                          </CCol>

                          <CCol xs={2}>
                            {conversation.unread > 0 && conversation.type == 'inbound' &&
                              <p className="text-end m-0">
                                <CBadge className="border border-light" color="success" shape="rounded-circle">
                                  <span className="visually fs-9">{conversation.unread}</span>
                                </CBadge>
                              </p>
                            }
                          </CCol>
                        </CRow>
                      </CCol>

                      {/* <CCol xs={8} className="p-0">
                      <span className="fw-semibolder">{conversation.first_name ?? conversation.number}</span>
                      <p className="text-muted fs-7 m-0 single-line">{conversation.last_message ?? "New Message"}</p>
                    </CCol> */}


                      {/* <CCol xs={2} className="text-right p-0 pe-1">
                      <p className="text-muted m-0 p-0 fs-8 text-end">{conversation.message_date ? conversation.message_date.substring(10, 16) : ""}</p>
                      <CBadge
                        className="border border-light p-2"
                        color="danger"
                        position="top-end"
                        shape="rounded-circle"
                      >
                        <span className="visually-hidden">1</span>
                      </CBadge>
                    </CCol> */}
                    </CRow>
                    <hr className="m-0" />
                  </div>
                ))}

              </CCol>
            </CRow>
          </CCol>
          {selectConversations &&
            <CCard className="vh-70 vw-100 mx-2 my-2 shadow-none">
              <CCardHeader className="py-1 bg-gray">
                <p className="text-dark m-0 fs-6">{selectConversations?.first_name ?? selectConversations?.number}</p>
                <p className="m-0 fs-8">{selectConversations?.number}</p>
              </CCardHeader>
              <CCardBody ref={cardBodyRef} className="d-flex flex-column overflow-auto" style={{ backgroundImage: `url(${background})` }}>
                {conversationsMessages?.map((message, index) => {
                  const createdAtDate = parseISO(message.created_at);
                  let fechaRelacionada = '';

                  if (isToday(createdAtDate)) {
                    fechaRelacionada = 'Hoy';
                  } else if (isYesterday(createdAtDate)) {
                    fechaRelacionada = 'Ayer';
                  } else {
                    fechaRelacionada = format(createdAtDate, 'dd/MM/yyyy');
                  }

                  if (datesArray.includes(fechaRelacionada)) {
                    fechaRelacionada = '';
                  } else {
                    datesArray.push(fechaRelacionada);
                  }

                  return (
                    <div key={index}>
                      <div className="text-center">
                        {!usedLabels.has(fechaRelacionada) && (
                          <div className="text-center">
                            <CBadge className="bg-light text-dark my-3">{fechaRelacionada}</CBadge>
                          </div>
                        )}
                      </div>
                      <div key={index} className={`m-1 chat-message ${message.type === 'outbound' ? 'outbound' : 'inbound'}`}>
                        {message.whatsapp_templates_id ?
                          <CCard className="text-center" style={{ width: "18rem" }}>
                            <CCardBody className="py-0 px-0" style={{
                            }}>
                              <div className="d-flex flex-column">
                                {/* <div className="position-relative" style={{ width: 0, height: 0, borderTop: '8px solid transparent', borderBottom: '8px solid transparent', borderRight: '8px solid #fff', position: 'absolute', left: '-4px', top: '11.5px', transform: "rotate(135deg)" }}></div> */}
                                <div className="bg-white rounded-end-3 rounded-bottom-3 pb-2 shadow text-start" style={{ maxWidth: "100%", minWidth: "25%", position: 'relative', minHeight: "42px" }}>
                                  <div className="m-1">

                                    {message.template?.header_format == 'TEXT' && <p className="m-0 fs-7 fw-bolder">{message.template?.header_content}</p>}
                                    {message.template?.header_format != 'TEXT' && (
                                      <>
                                        {message.template?.header_format == 'IMAGE' ? (
                                          <CImage src={message.template?.header_content} className="rounded-2 w-100 h-50" />
                                        ) : message.template?.header_format == 'VIDEO' ? (

                                          <CustomVideo src={message.template?.header_content} className="rounded-2 w-100" onClick={() => { showMedia(message.template?.header_content ?? "") }} />
                                        ) : (
                                          <p>Preview no disponible para este tipo de archivo</p>
                                        )}
                                      </>
                                    )}
                                    <p className="m-0 fs-8 mt-2 text-black">{formatText(message.body ?? "")}</p>
                                    <p className="m-0 fs-9 mt-2 text-gray">{message.template?.footer_content}</p>
                                    <p className="p-0 fs-9 text-end m-0 text-gray" style={{ bottom: '2px', right: '10px' }}>{formatTimeTemplate(new Date())}</p>
                                  </div>


                                  {(message.template?.buttons != undefined && message.template?.buttons.length > 0) && <hr className="my-2" />}
                                  <div className={`px-${(message.template?.buttons != undefined && message.template?.buttons.length > 0) ? 2 : 0}`}>
                                    {(message.template?.buttons != undefined && message.template?.buttons.length < 4) &&
                                      message.template?.buttons.map((button, index) => (
                                        <div key={index}>
                                          {index > 0 && <hr className="my-2" />}

                                          {button.type === 1 && (
                                            <p className="text-center text-info my-0">
                                              <BIcon icon="reply-fill" size={5} className="me-2" />
                                              {button.text}
                                            </p>
                                          )}
                                          {button.type === 4 && (
                                            <p className="text-center text-info my-0">
                                              <BIcon icon="copy" size={7} className="me-2" />
                                              {button.text}
                                            </p>
                                          )}
                                          {button.type === 2 && (
                                            <p className="text-center text-info my-0">
                                              <BIcon icon="box-arrow-up-right" size={8} className="fw-bold me-2" />
                                              {button.text}
                                            </p>
                                          )}
                                          {button.type === 3 && (
                                            <p className="text-center text-info my-0">
                                              <BIcon icon="telephone-fill" size={7} className="me-2" />
                                              {button.text}
                                            </p>
                                          )}
                                        </div>
                                      ))
                                    }
                                    {(message.template?.buttons != undefined && message.template?.buttons.length > 3) &&
                                      message.template?.buttons.slice(0, 2).map((button, index) => (
                                        <div key={index}>

                                          {index > 0 && <hr className="my-2" />}

                                          {button.type === 1 && (
                                            <p className="text-center text-info my-0">
                                              <BIcon icon="reply-fill" size={5} className="me-2" />
                                              {button.text}
                                            </p>
                                          )}
                                          {button.type === 4 && (
                                            <p className="text-center text-info my-0">
                                              <BIcon icon="copy" size={7} className="me-2" />
                                              {button.text}
                                            </p>
                                          )}
                                          {button.type === 2 && (
                                            <p className="text-center text-info my-0">
                                              <BIcon icon="box-arrow-up-right" size={8} className="fw-bold me-2" />
                                              {button.text}
                                            </p>
                                          )}
                                          {button.type === 3 && (
                                            <p className="text-center text-info my-0">
                                              <BIcon icon="telephone-fill" size={7} className="me-2" />
                                              {button.text}
                                            </p>
                                          )}

                                          {index === 1 && (
                                            <div>
                                              <hr className="my-2" />
                                              <p className="text-center text-info my-0">
                                                <BIcon icon="list-ul" size={5} className="me-2" />Ver todas las opciones
                                              </p>
                                            </div>
                                          )}
                                        </div>
                                      ))
                                    }
                                  </div>
                                </div>
                              </div>
                            </CCardBody>
                          </CCard>
                          :
                          <>
                            {message.media_url != "" && message.media_url != null && message.media_url.includes('.opus') ?
                              <div className="p-1 bg-light rounded">
                                <CustomAudioPlayer src={'https://api.marketsms.co/' + message.media_url} />
                                <p className=" fs-9 text-gray mb-0 text-end" style={{ minWidth: "2rem", marginTop: "-0.7rem !important" }}>{message.created_at.substring(10, 16)}</p>
                              </div>
                              :
                              <>
                                {(message.media_url && message.body == '') ?
                                  <div className="p-1 bg-light rounded">
                                    <CImage width={256} src={'https://api.marketsms.co/' + message.media_url} className="cursor-pointer rounded" title="Previsualizar imagen" onClick={() => { showMedia(message.media_url) }} />
                                    <p className=" fs-9 text-gray mb-0 text-end mt-1" style={{ minWidth: "2rem" }}>{message.created_at.substring(10, 16)}</p>
                                  </div>
                                  : (message.media_url && message.body != '') ?
                                    <div className="p-1 bg-light rounded" style={{ maxWidth: "264px" }}>
                                      <CImage width={256} src={'https://api.marketsms.co/' + message.media_url} className="rounded cursor-pointer" title="Previsualizar imagen" onClick={() => { showMedia(message.media_url) }} />
                                      <p className="m-0 fs-7 mt-2" style={{ maxWidth: "90% !important" }}>{formatText(message.body ?? "")}</p>
                                      <p className=" fs-9 text-gray mb-0 text-end" style={{ minWidth: "2rem", marginTop: "-10px" }}>{message.created_at.substring(10, 16)}</p>
                                    </div>
                                    :
                                    <div className="message-text m-0 p-1 d-flex pb-0 mt-2" style={{ minWidth: '10%' }}>
                                      <p className="m-0 p-0">{formatText(message.body ?? "")}</p>
                                      <div className="text-end d-flex mt-3 ms-2">
                                        <p className=" fs-9 text-gray mb-0" style={{ minWidth: "2rem" }}>{message.created_at.substring(10, 16)}</p>
                                        {message.type === 'outbound' &&
                                          <BIcon icon={`check2${message.whatsapp_statuses_id === 4 || message.whatsapp_statuses_id === 6 ? '-all' : ''}`} className={`m-0 ms-1 text-${message.whatsapp_statuses_id === 6 ? 'info' : 'dark'}`} />
                                        }
                                      </div>
                                    </div>
                                }
                              </>
                            }



                            {/* {(message.body && message.media_url == '') &&
                            <div className="message-text m-0 p-1 d-flex pb-0 mt-2" style={{ minWidth: '10%' }}>
                              <p className="m-0 p-0">{message.body}</p>
                              <div className="text-end d-flex mt-3 ms-2">
                                <p className=" fs-9 text-gray mb-0" style={{ minWidth: "2rem" }}>{message.created_at.substring(10, 16)}</p>
                                {message.type === 'outbound' &&
                                  <BIcon icon={`check2${message.whatsapp_statuses_id === 4 || message.whatsapp_statuses_id === 6 ? '-all' : ''}`} className={`m-0 ms-1 text-${message.whatsapp_statuses_id === 6 ? 'info' : 'dark'}`} />
                                }
                              </div>
                            </div>
                          } */}

                          </>
                        }
                      </div>
                    </div>
                  )
                })}
              </CCardBody>
              <CRow className="p-2">
                <CCol md={11}>
                  {recordingState === 'stop' ?

                    <CFormTextarea
                      ref={textareaRef}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      onKeyDown={handleKeyDown}
                      rows={1} // Tamaño inicial pequeño
                      style={{ resize: 'none' }} // Evita el redimensionamiento manual
                    ></CFormTextarea>
                    :
                    <CRow className="text-end p-0 pe-2">
                      <CCol sm={5} className="py-1">
                        <BIcon onClick={stopRecording} icon='trash-fill' size={4} className={`cursor-pointer text-black`} />
                      </CCol>
                      <CCol sm={1} className="text-start p-0 pt-2 fs-6">{formatTime(time)}</CCol>
                      <CCol sm={5} className="py-0">
                        {audioUrl && (
                          <audio style={{ height: "1.5rem" }} controls src={audioUrl}></audio>
                        )}
                      </CCol>
                      <CCol sm={1} className="p-0 m-0">
                        {recordingState === 'start' ?
                          <BIcon onClick={pauseRecording} icon='stop-circle' size={4} className={`cursor-pointer text-danger`} />
                          :
                          <BIcon onClick={startRecording} icon='mic-fill' size={4} className={`cursor-pointer text-danger`} />
                        }
                      </CCol>
                    </CRow>
                  }
                </CCol>
                <CCol md={1} className="p-0 text-center">
                  {
                    message || recordingState !== 'stop'
                      ?
                      <CIcon onClick={sendIndividualMessage} icon={cilSend} style={{ rotate: "45deg" }} size={"xl"} className="mt-1 text-success cursor-pointer" />
                      :
                      <BIcon onClick={recordingHandle} icon='mic-fill' size={4} className={`cursor-pointer text-dark`} />
                  }
                </CCol>
              </CRow>
            </CCard>
          }
          {!selectConversations &&
            <CCard className="vh-70 vw-100 d-flex align-items-center justify-content-center text-center bg-blue-light mx-2 my-2 shadow-none">
              <p>Selecciona un chat para empezar</p>
            </CCard>
          }

        </CCardBody>
      </CCard>


      <CModal
        visible={visibleMedia}
        size="sm"
        // fullscreen="sm"
        onClose={() => setVisibleMedia(false)}
        aria-labelledby="LiveDemoExampleLabel"

      // style={{ maxWidth: "45rem" }}
      >
        {srcMedia.includes('jpg') || srcMedia.includes('png') || srcMedia.includes('jpeg') ?
          <CImage
            src={'https://api.marketsms.co/' + srcMedia}
            className="cursor-pointer m-0"
            title="Descargar"
            alt="Archivo CSV"
          />
          :
          <CustomVideo
            src={srcMedia}
            controls={true}
          />
        }
      </CModal>
    </>
  );


};

