import React, { useEffect, useState } from "react";
import {
    CSmartTable,
    CCard,
    CCardHeader,
    CCardBody,
    CTabContent,
    CTabPane,
    CBadge,
    CButton,
} from "@coreui/react-pro";

import * as MarketsmsService from "../../services/MarketsmsService";

import { UsersItems } from "../../models/Users";
import { BIcon } from "../../components/icons/BIcon";
import { Link, useNavigate } from "react-router-dom";
import { saveLoginAsUser, saveToken } from "../../Utils/Auth";
import { getBadge, getBadgeRol, getCurrentUser, setCurrentAccountDefault } from "../../Utils/BaseApp";


interface LoginAsUsersProps { }

export const LoginAsUser: React.FC<LoginAsUsersProps> = ({ }: LoginAsUsersProps) => {
    const [users, setUsers] = useState<UsersItems[] | null>(null);

    const getUsers = async () => {

        var response = await MarketsmsService.getUsers();

        if (response) {
            if (response.status === 200) {
                console.log(response)
                setUsers(response.data.items);
                console.log(response.data.items)
            }
        }
    };

    useEffect(() => {
        getUsers();
    }, []);

    // Llamar api login as user
    // Hacer llamado de api para obtener la cuenta

    const navigate = useNavigate();

    const loginAsUser = async (id: number) => {

        // var response = await MarketsmsService.loginAsUser();
        MarketsmsService.loginAsUser(id).then((response) => {
            if (response) {
                if (response.status === 200) {
                    console.log(response)
                    // setUsers(response.data.items);
                    // saveLoginAsUser
                    console.log(response.data.access_token)

                    saveToken(response.data.access_token);

                    getCurrentUser().then((currentUser) => {
                        // Este código se ejecutará después de que la función `getUser` se haya resuelto
                        // Imprime la respuesta de la API dentro del then
                        if (currentUser !== null) {
                            //   getAccounts();
                            saveLoginAsUser(currentUser);

                            MarketsmsService.getAccountsByUser().then((response) => {
                                if (response.status === 200) {
                                    console.log("Entra a 73");
                                    if (response.data.items.length === 1) {
                                        setCurrentAccountDefault(response.data.items[0]);
                                    }
                                    const path = '/dashboard';
                                    // navigate(path);
                                    window.location.href = path;
                                } else {
                                    localStorage.removeItem("default_account")
                                    const path = '/dashboard';
                                    // navigate(path);
                                    window.location.href = path;
                                }
                            })
                        }
                    });
                }
            }
        })


    };

    const columns = [
        {
            key: "id",
            label: "Id",
            _style: { width: "5%" },
        },
        {
            key: "email",
            label: "Email",
            _style: { width: "20%" },
        },
        {
            key: "first_name",
            label: "Nombre",
            _style: { width: "20%" },
        },
        {
            key: "last_name",
            label: "Apellido",
            _style: { width: "25%" },
        },
        {
            key: "status_name",
            label: "Estado",
            _style: { width: "10%" },
        },
        {
            key: "role_name",
            label: "Rol",
            _style: { width: "10%" },
        },
        {
            key: "operations",
            label: "Acción",
            _style: { width: "10%" },
        }
    ];

    return (
        <div className="col-12">
            <CCard className="mb-4">
                <CCardHeader>
                    <strong>Ingresar como Usuario</strong>
                </CCardHeader>
                <CCardBody>
                    <CTabContent className="rounded-bottom">
                        <CTabPane
                            className="p-3 active preview show"
                            role="tabpanel"
                            id="preview-578"
                        >
                            <CSmartTable
                                activePage={2}
                                cleaner
                                clickableRows
                                columns={columns}
                                columnFilter
                                columnSorter
                                loading={users === null ? true : false}
                                items={users ?? []}
                                itemsPerPageSelect
                                itemsPerPage={10}
                                pagination
                                scopedColumns={{
                                    status_name: (user: UsersItems) => (
                                        <td>
                                            <CBadge color={getBadge(user.status_id)}>
                                                {user.status_name}
                                            </CBadge>
                                        </td>
                                    ),
                                    role_name: (user: UsersItems) => (
                                        <td>
                                            <CBadge color={getBadgeRol(user.roles_id)}>
                                                {user.role_name}
                                            </CBadge>
                                        </td>
                                    ),
                                    operations: (user: UsersItems) => (
                                        <td>
                                            <CButton
                                                color="success"
                                                variant="outline"
                                                shape="square"
                                                className="m-1"
                                                size="sm"
                                                title="Ver más"
                                                onClick={() => { loginAsUser(user.id) }}
                                            >Iniciar
                                                <BIcon icon="box-arrow-in-right" className="ms-1" />
                                            </CButton>

                                        </td>
                                    )
                                }}
                                sorterValue={{ column: "status", state: "asc" }}
                                tableFilter
                                tableProps={{
                                    className: "add-this-class",
                                    responsive: true,
                                    striped: true,
                                    hover: true,
                                }}
                                tableBodyProps={{
                                    className: "align-middle",
                                }}
                            />
                        </CTabPane>
                    </CTabContent>
                </CCardBody>
            </CCard>
        </div>
    );
};