import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardHeader,
  CCardBody,
  CSmartTable,
  CButton
} from "@coreui/react-pro";

import * as MarketsmsService from "../../services/MarketsmsService";
import { GroupsItems } from "../../models/Groups";
import { Link, useParams } from "react-router-dom";
import { ContactsItems } from "../../models/Contacts";
import CIcon from "@coreui/icons-react";
import { cilBirthdayCake, cilTrash } from "@coreui/icons";
import confirmDialog from "../../components/confirm/ConfirmDialog";
import { customMessage, showToastTR } from "../../Utils/BaseApp";
import { BIcon } from "../../components/icons/BIcon";

interface GroupDetailsProps { }

export const GroupDetails: React.FC<
  GroupDetailsProps
> = ({ }: GroupDetailsProps) => {
  const { id } = useParams();
  const group_id = parseInt(id + "");

  const [contacts, setContacts] = useState<ContactsItems[]>([]);

  const getContactsByGroupId = async () => {
    var response = await MarketsmsService.getContactsByGroupId(group_id);
    if (response) {
      if (response.status === 200) {
        setContacts(response.data.items);
      }
    }
  };

  const columns = [
    {
      key: "id",
      _style: { width: "5%" },
    },
    {
      key: "number",
      label: "Número",
      _style: { width: "20%" },
    },
    {
      key: "first_name",
      label: "Nombre",
      _style: { width: "20%" },
    },
    {
      key: "last_name",
      label: "Apellido",
      _style: { width: "20%" },
    },
    {
      key: "birthday",
      label: "Cumpleaños",
      _style: { width: "20%" },
    },
    {
      key: "operations",
      label: "Acción",
      _style: { width: "15%" },
    },
  ];

  useEffect(() => {
    getContactsByGroupId();
  }, []);

  const [groups, setGroups] = useState<GroupsItems[]>([]);

  const getGroups = async () => {
    var response = await MarketsmsService.getGroupById(group_id);
    if (response) {
      if (response.status === 200) {
        setGroups(response.data.items);
      }
    }
  };

  useEffect(() => {
    getGroups();
    getContactsByGroupId();
  }, []);

  const deleteContactFromGroup = async (contact: ContactsItems) => {

    try {
      await confirmDialog({
        header: "Confirmar",
        message: "Por favor confirma si deseas eliminar el contacto " + contact.number + " del grupo " + groups[0].name,
        onConfirm: async () => {
          // Lógica de confirmación aquí
          var response = await MarketsmsService.deleteContactFromGroup(
            group_id,
            contact.id
          );
          if (response) {
            if (response.status === 200) {
              getContactsByGroupId();
              showToastTR(customMessage(response.code_app), 200, "success");
            } else {
              showToastTR(customMessage(response.code_app), 2000, "error");
            }
          }
        },
        onReject: () => {
          // Lógica de rechazo aquí
          console.log("Rejected");
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="col-12">
      <CCard className="mb-4">
        <CCardHeader className="p-0 d-flex align-items-center ps-1">
          <Link to={'/groups'} >
            <BIcon icon="arrow-left-short" className="fs-3 me-1 pt-2 text-danger cursor-pointer" />
          </Link>
          <strong>Detalles para {groups[0] && (<span className="text-primary">{groups[0].name}</span>)}</strong>
        </CCardHeader>
        <CCardBody>
          <CSmartTable
            // activePage={2}
            cleaner
            clickableRows
            columns={columns}
            columnFilter
            columnSorter
            // footer
            loading={contacts.length === 0 ? true : false}
            items={contacts}
            itemsPerPageSelect
            itemsPerPage={10}
            pagination
            // onFilteredItemsChange={(items) => {
            //   // console.log(items)
            // }}
            onSelectedItemsChange={(items) => {
              console.log(items);
            }}
            // scopedColumns={{}}
            scopedColumns={{
              birthday: (contact: ContactsItems) => (
                <td>
                  {contact.birthday && (
                    <>
                      <CIcon
                        icon={cilBirthdayCake}
                        className="text-primary me-3"
                      />
                      {contact.birthday}
                    </>
                  )}
                </td>
              ),
              operations: (contact: ContactsItems) => {
                return (
                  <td className="py-2 text-center">
                    <CButton
                      color="danger"
                      variant="outline"
                      shape="square"
                      size="sm"
                      className="mx-1"
                      onClick={() => deleteContactFromGroup(contact)}
                    >
                      <CIcon icon={cilTrash} />
                    </CButton>
                  </td>
                );
              },
            }}
            selectable
            sorterValue={{ column: "id", state: "asc" }}
            tableFilter
            tableProps={{
              className: "add-this-class",
              responsive: true,
              striped: true,
              hover: true,
            }}
            tableBodyProps={{
              className: "align-middle",
            }}
          />
        </CCardBody>
      </CCard>
    </div>
  );
};
