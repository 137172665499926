import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardHeader,
  CCardBody,
  CButton,
} from "@coreui/react-pro";
import * as MarketsmsService from "../../services/MarketsmsService";
import { getCurrentAccountId } from "../../Utils/BaseApp";
import CIcon from "@coreui/icons-react";
import { cibFacebook } from "@coreui/icons";
import { useNavigate } from "react-router-dom";

interface WhatsappIntegrationProps { }

export const WhatsappIntegration: React.FC<WhatsappIntegrationProps> = () => {

  const navigate = useNavigate();


  useEffect(() => {
    (window as any).fbAsyncInit = function () {
      (window as any).FB.init({
        appId: '307734595235971',
        cookie: true,
        xfbml: true,
        version: 'v20.0'
      });
    };

    (function (d, s, id) {
      let js: HTMLScriptElement,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s) as HTMLScriptElement;
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs?.parentNode?.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');






    window.addEventListener('message', sessionInfoListener);




  }, []);

  const launchWhatsAppSignup = () => {
    if ((window as any).FB) {
      const state = generateUniqueState();
      (window as any).FB.login(function (response: any) {
        console.log(response);
        if (response.authResponse) {
          const accessToken = response.authResponse.accessToken;
          console.log(accessToken);
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      }, {
        config_id: '496637072717970', // configuration ID goes here
        response_type: 'code',    // must be set to 'code' for System User access token
        override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
        "scope": "business_management, whatsapp_business_management, whatsapp_business_messaging",
        extras: {
          "feature": "whatsapp_embedded_signup",
          "version": 1,
          "sessionInfoVersion": 3,
          setup: {
            //... // Prefilled data can go here
          }
        }
      });
    } else {
      console.log('Error: FB object not available.');
    }
  };


  // const [formData, setFormData] = useState({
  //   waba_id: 0,
  //   phone_number_id: 0,
  // });

  // Para obtener información la finalizar el registro
  const sessionInfoListener = async (event: any) => {
    console.log("START Capture event");
    console.log(event);
    console.log("END Capture event");
    if (event.origin == null) {
      return;
    }

    // Make sure the data is coming from facebook.com
    if (!event.origin.endsWith("facebook.com")) {
      return;
    }

    try {
      console.log("start event");
      console.log(event);
      console.log("end event");
      const data = JSON.parse(event.data);
      console.log("start data");
      console.log(data);
      console.log("end data");
      if (data.type === 'WA_EMBEDDED_SIGNUP') {
        // if user finishes the Embedded Signup flow
        if (data.event === 'FINISH') {
          const { phone_number_id, waba_id } = data.data;
          console.log("Phone number ID ", phone_number_id, " WhatsApp business account ID ", waba_id);

          const account_id = await getCurrentAccountId();


          // setFormData({ ...formData, ['waba_id']: waba_id });
          // setFormData({ ...formData, ['phone_number_id']: phone_number_id });

          // console.log(formData);


          // setFormData(prevFormData => ({
          //   ...prevFormData,
          //   waba_id: waba_id,
          //   phone_number_id: phone_number_id
          // }));
          const body = { waba_id: waba_id, phone_number_id: phone_number_id };
          console.log("START formData");
          console.log(body);
          console.log("END formData");
          MarketsmsService.integrateCommerce({ waba_id: waba_id, phone_number_id: phone_number_id }, account_id).then((response) => {
            console.log(response);

            if (response.status == 200) {
              navigate('/whatsapp/numbers');
            }
          })
          console.log("END formData 120");


        }
        // if user reports an error during the Embedded Signup flow
        else if (data.event === 'ERROR') {
          const { error_message } = data.data;
          console.error("error ", error_message);
        }
        // if user cancels the Embedded Signup flow
        else {
          const { current_step } = data.data;
          console.warn("Cancel at ", current_step);
        }
      }
    } catch {
      // Don’t parse info that’s not a JSON
      console.log('Non JSON Response', event.data);
    }
  };


  function generateUniqueState() {
    return Math.random().toString(36).substring(2) + Date.now().toString(36);
  }

  return (
    <div className="col-12">
      <CCard className="mb-4">
        <CCardHeader>
          <strong>Integración de WhatsApp</strong>
        </CCardHeader>
        <CCardBody className="text-center">
          <h3 className="mb-5">¡Bienvenido a <strong>MarketSms</strong>!</h3>

          <p className="text-start">Conéctate con nosotros a través de WhatsApp Business y recibe un servicio personalizado y rápido.</p>


          <h6 className="text-start text-danger mt-4">¿Por qué elegir WhatsApp Business?</h6>
          <ul className="text-start">

            <li> <strong>Atención Personalizada:</strong> Resuelve tus dudas y recibe asistencia en tiempo real.</li>
            <li> <strong>Respuestas Rápidas:</strong> Disfruta de respuestas ágiles y eficientes.</li>
            <li> <strong>Actualizaciones y Ofertas:</strong> Mantente informado sobre nuestras últimas noticias y promociones.</li>
          </ul>



          <h6 className="text-start text-danger mt-4">¿Cómo funciona?</h6>
          <ul className="text-start">
            <li> <strong>Inicia sesión:</strong> Haz click en "Iniciar sesión con facebook" y agrega tu número empresarial a utilizar siguiendo cada uno de los pasos que se especifican. Recuerda que este número no se debe estar usando en whatsapp buisness y ni en whatsapp messenger.</li>
            <li> <strong>Disfruta del Servicio:</strong> Comienza a interactuar con tus clientes y aprovecha todo lo que tenemos para ofrecer.</li>
            <li> <strong>Respuestas Rápidas:</strong> Disfruta de respuestas ágiles y eficientes.</li>
          </ul>


          <p>¡Estamos aquí para ayudarte! Si tienes alguna pregunta o necesitas asistencia, no dudes en enviarnos un mensaje a través de WhatsApp.</p>



          <CButton onClick={launchWhatsAppSignup} className="mt-3 bg-primary mb-3"
            style={{
              backgroundColor: '#1877f2',
              border: '0',
              borderRadius: '4px',
              color: '#fff',
              cursor: 'pointer',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontSize: '16px',
              fontWeight: 'bold',
              height: '36px',
              padding: '0 24px'
            }}
          >
            <CIcon icon={cibFacebook} className="me-2" />
            Ingresar con Facebook
          </CButton>
        </CCardBody>
      </CCard>
    </div>
  );
};