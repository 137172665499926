import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardHeader,
  CCardBody,
  CSmartTable,
  CButton,
  CTabContent,
  CModal,
  CModalHeader,
  CModalTitle,
  CCol,
  CFormInput,
  CModalBody,
  CForm,
  CModalFooter,
  CImage,
  CBadge,
  CDatePicker,
} from "@coreui/react-pro";

import * as MarketsmsService from "../../services/MarketsmsService";
import { ContactsItems } from "../../models/Contacts";
import CIcon from "@coreui/icons-react";
import { cilBirthdayCake, cilPencil, cilPlus, cilTrash } from "@coreui/icons";
import { BIcon } from "../../components/icons/BIcon";
import csv from "../../assets/general/csv.png";
import {
  customMessage,
  getBadge,
  getCurrentAccount,
  showToastTC,
  showToastTR,
} from "../../Utils/BaseApp";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { startLoading, stopLoading } from "../../redux/features/loadSlice";
import { showConfirm } from "../../redux/features/confirmSlice";
import { selectConfirm } from "../../redux/selectors";

interface ContactsProps { }

export const Contacts: React.FC<ContactsProps> = ({ }: ContactsProps) => {
  const [contacts, setContacts] = useState<ContactsItems[] | null>(null);
  const [detailContact, setDetailContact] = useState<ContactsItems>();
  const [visible, setVisible] = useState(false);
  const [validated, setValidated] = useState(false);
  const [editContact, setEditContact] = useState(false);
  const [visibleCsv, setVisibleCsv] = useState(false);
  const [selectedFile, setSelectedFile] = useState<FileList | null>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { confirm, action, show } = useAppSelector(selectConfirm);

  const currentDate = new Date();
  const maxDate = new Date(
    currentDate.getFullYear() - 10,
    currentDate.getMonth(),
    currentDate.getDate()
  );

  const getContactsByAccount = async () => {
    var response = await MarketsmsService.getContactsByAccount();
    if (response) {
      if (response.status === 200) {
        setContacts(response.data.items);
      }
    }
  };

  const [formData, setFormData] = useState({
    number: 57,
    first_name: "",
    last_name: "",
    birthday: "",
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date: any) => {
    if (date) {
      formData.birthday = date.toISOString().split("T")[0];
    }
  };

  const editDetails = (contact: ContactsItems) => {
    console.log(contact);
    setFormData({
      number: contact.number,
      first_name: contact.first_name,
      last_name: contact.last_name,
      birthday: contact.birthday,
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const form = event.currentTarget as HTMLFormElement;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setVisible(false);

      if (editContact) {
        setEditContact(false);
        console.log("TODO::Aqui se debe llamar la api de editar");
        var response = await MarketsmsService.addContacts({
          contacts: [formData],
        });

        if (response) {
          if (response?.status === 200) {
            showToastTR(customMessage(response.code_app), 200, "success");
          } else {
            showToastTR(customMessage(response.code_app), 2000, "error");
          }
        }
      } else {
        console.log("TODO::Aqui se debe llamar la api de crear");
        var response = await MarketsmsService.addContacts({
          contacts: [formData],
        });

        if (response) {
          if (response?.status === 200) {
            showToastTR(customMessage(response.code_app), 200, "success");
          } else {
            showToastTR(customMessage(response.code_app), 2000, "error");
          }
        }
      }

      getContactsByAccount();

      // createAccount();
      setFormData({
        number: 57,
        first_name: "",
        last_name: "",
        birthday: "",
      });
    }
    setValidated(true);
  };

  const columns = [
    {
      key: "id",
      _style: { width: "5%" },
    },
    {
      key: "number",
      label: "Número",
      _style: { width: "15%" },
    },
    {
      key: "first_name",
      label: "Nombre",
      _style: { width: "20%" },
    },
    {
      key: "last_name",
      label: "Apellido",
      _style: { width: "20%" },
    },
    {
      key: "birthday",
      label: "Cumpleaños",
      _style: { width: "15%" },
    },
    {
      key: "status_name",
      label: "Estado",
      _style: { width: "10%" },
    },
    {
      key: "operations",
      label: "Acción",
      _style: { width: "15%" },
    },
  ];

  useEffect(() => {
    getCurrentAccount().then((current_account) => {
      if (current_account !== null) {
        getContactsByAccount();
      } else {
        showToastTC('Para realizar este proceso debes seleccionar una cuenta por defecto', 3000, 'error');
        setTimeout(() => {
          navigate('/user/administration/accounts');
        }, 3000);
      }
    })
  }, []);


  const handleDownload = () => {
    const csv = process.env.PUBLIC_URL + "/files/examples/csv/contacts.csv";

    // Crear un enlace temporal para la descarga del archivo
    const link = document.createElement("a");
    if (link.download !== undefined) {
      link.setAttribute("href", csv);
      link.setAttribute("download", "ejemplo.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleImport = () => {
    dispatch(startLoading())
    if (selectedFile && selectedFile.length > 0) {
      const file = selectedFile[0];
      MarketsmsService.contactsImport(file).then((response) => {
        if (response.status === 200) {
          console.log("Entra a 200");
          showToastTR(customMessage(response.code_app), 1000, "success");
        } else {
          console.log("Entra a 400");
          showToastTR(customMessage(response.code_app), 4000, "error");
        }
        dispatch(stopLoading())
        setVisibleCsv(false);
      })
    } else {
      showToastTR('Por favor seleccione un archivo para importar', 1000, 'error');
      dispatch(stopLoading())

    }
  };


  useEffect(() => {
    if (!show) {
      if (action == 'deleteContact') {
        if (confirm) {
          if (detailContact?.id) {
            MarketsmsService.deleteContact(detailContact?.id).then((response) => {
              console.log(response);
              if (response?.status === 200) {
                showToastTR(customMessage(response.code_app), 200, "success");
              }
              getContactsByAccount();
            })
          }
        } else {
          showToastTR("La operación fue cancelada", 500, "info");
        }
      }
    }
  }, [show])

  const deleteContact = async (detailContact: any) => {
    setDetailContact(detailContact);
    dispatch(showConfirm({
      "message": "¿Esta seguro que desea eliminar el contacto " +
        detailContact?.number +
        "?", "action": "deleteContact"
    }));
  };

  useEffect(() => {
    if (!visible) {
      setFormData({
        number: 57,
        first_name: "",
        last_name: "",
        birthday: "",
      });
    }
  }, [visible]);

  return (
    <div className="col-12">
      <CCard className="mb-4">
        <CCardHeader>
          <strong>Contactos</strong>
        </CCardHeader>
        <CCardBody>
          <CButton onClick={() => setVisible(!visible)}>
            <CIcon icon={cilPlus} /> Agregar
          </CButton>

          <CButton
            color="success"
            className="ms-2"
            onClick={() => setVisibleCsv(true)}
          // onClick={handleImportClick}
          >
            <BIcon icon={"filetype-csv"} /> Importar CSV
          </CButton>

          <CTabContent className="rounded-bottom mt-4">
            <CSmartTable
              activePage={2}
              cleaner
              clickableRows
              columns={columns}
              columnFilter
              columnSorter
              // footer
              loading={contacts === null ? true : false}
              items={contacts ?? []}
              itemsPerPageSelect
              itemsPerPage={10}
              pagination
              itemsNumber={232}
              // onFilteredItemsChange={(items) => {
              //   // console.log(items)
              // }}
              onSelectedItemsChange={(items) => {
                console.log(items);
              }}
              // scopedColumns={{}}
              scopedColumns={{
                birthday: (contact: ContactsItems) => (
                  <td>
                    {contact.birthday && (
                      <>
                        <CIcon
                          icon={cilBirthdayCake}
                          className="text-primary me-3"
                        />
                        {contact.birthday}
                      </>
                    )}
                  </td>
                ),
                status_name: (contact: ContactsItems) => (
                  <td>
                    <CBadge color={getBadge(contact.status_id)}>
                      {contact.status_name}
                    </CBadge>
                  </td>
                ),
                operations: (contact: ContactsItems) => {
                  return (
                    <td className="py-2 text-center">
                      <CButton
                        color="success"
                        variant="outline"
                        className="m-1"
                        shape="square"
                        size="sm"
                        onClick={() => {
                          setEditContact(true);
                          editDetails(contact);
                          setVisible(true);
                        }}
                      >
                        <CIcon icon={cilPencil} />
                      </CButton>
                      <CButton
                        color="danger"
                        variant="outline"
                        shape="square"
                        size="sm"
                        className="mx-1"
                        onClick={() => {
                          setDetailContact(contact);
                          deleteContact(contact);
                        }}
                      >
                        <CIcon icon={cilTrash} />
                      </CButton>
                    </td>
                  );
                },
              }}
              selectable
              // sorterValue={{ column: "id", state: "asc" }}
              tableFilter
              tableProps={{
                className: "add-this-class",
                responsive: true,
                striped: true,
                hover: true,
              }}
              tableBodyProps={{
                className: "align-middle",
              }}
            />
          </CTabContent>
        </CCardBody>
      </CCard>

      <CModal
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="LiveDemoExampleLabel"
      >
        <CModalHeader closeButton>
          <CModalTitle id="LiveDemoExampleLabel">Agregar Contacto</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CForm
            className="row g-3 needs-validation"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <CCol md={12}>
              <CFormInput
                pattern="[57]{2}[3]{1}[0-9]{2}[0-9]{3}[0-9]{4}"
                value={formData.number}
                type="number"
                name="number"
                minLength={12}
                maxLength={12}
                placeholder="Ejm. 312 345 5678"
                feedbackValid="Luce bien!"
                feedbackInvalid="Por favor escriba un número de teléfono valido."
                id="validationCustom02"
                label="Número de teléfono *"
                onChange={handleChange}
                required
              />
            </CCol>
            <CCol md={12}>
              <CFormInput
                value={formData.first_name}
                type="text"
                name="first_name"
                minLength={2}
                placeholder="Nombre del contacto"
                feedbackValid="Luce bien!"
                feedbackInvalid="Por favor escriba un nombre valido para la cuenta."
                id="validationCustom01"
                label="Nombre"
                onChange={handleChange}
              />
            </CCol>
            <CCol md={12}>
              <CFormInput
                value={formData.last_name}
                type="text"
                name="last_name"
                minLength={2}
                placeholder="Apellido del contacto"
                feedbackValid="Luce bien!"
                feedbackInvalid="Por favor escriba un apellido valido para la cuenta."
                id="validationCustom01"
                label="Nombre"
                onChange={handleChange}
              />
            </CCol>
            <CCol md={12}>
              <CDatePicker
                label="Fecha de nacimiento"
                locale="es-CO"
                format="yyyy-MM-dd"
                maxDate={maxDate}
                date={
                  formData.birthday
                    ? new Date(
                      new Date(formData.birthday).setDate(
                        new Date(formData.birthday).getDate() + 1
                      )
                    )
                    : maxDate
                }
                onDateChange={handleDateChange}
              />
            </CCol>
            <CModalFooter className="pb-0">
              <CButton color="light" onClick={() => setVisible(false)}>
                Cerrar
              </CButton>
              <CButton color="primary" type="submit">
                {editContact ? "Actualizar Contacto" : "Guardar Contacto"}
              </CButton>
            </CModalFooter>
          </CForm>
        </CModalBody>
      </CModal>

      <CModal
        visible={visibleCsv}
        onClose={() => setVisibleCsv(false)}
        aria-labelledby="LiveDemoExampleLabel"
      >
        <CModalHeader closeButton>
          <CModalTitle id="LiveDemoExampleLabel">
            Importar Contactos
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div className="text-center mb-4">
            <h6>Descarga el siguiente ejemplo</h6>
            <CImage
              src={csv}
              width={128}
              className="cursor-pointer my-4"
              title="Descargar"
              alt="Archivo CSV"
              onClick={handleDownload}
            />
          </div>
          <CFormInput
            type="file"
            accept=".csv"
            max={300}
            onChange={(e) => setSelectedFile(e.target.files)}
            id="file"
          />

          <label htmlFor="file">
            Recomendado máximo 50 mil contactos, peso máximo permitido del
            archivo{" "}
            <span className="text-danger">
              <strong>3MB</strong>
            </span>
          </label>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisibleCsv(false)}>
            Cerrar
          </CButton>
          <CButton color="primary" onClick={handleImport}>
            Importar
          </CButton>
        </CModalFooter>
      </CModal>
      {/* {deleteConfirmation && (
        <Confirm
          onConfirm={() => deleteContact(1)}
          onCancel={() => deleteContact(2)}
          confirmationMessage={confirmationMessage}
        />
      )} */}
    </div>
  );
};
