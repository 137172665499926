import React, { useState } from "react";
import {
    CCard,
    CCardHeader,
    CCardBody,
    CRow,
    CCol,
    CCardTitle,
    CCardText,
    CButton,
    CFormInput,
    CNav,
    CNavItem,
    CNavLink,
    CTabContent,
    CTabPane,
    CInputGroup,
    CInputGroupText
} from "@coreui/react-pro";
import { CodeBlock, dracula, CopyBlock, github } from 'react-code-blocks';
import SwaggerDocs from "../../components/swagger/SwaggerDocs";
import { NavLink } from "react-router-dom";
// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';


interface DocumentationProps { }

export const Documentation: React.FC<DocumentationProps> = ({ }: DocumentationProps) => {
    const [activeTab, setActiveTab] = useState(0);
    const code_webhook_status = `{
    "id": 245,
    "from": "sender",
    "to": "573135855289",
    "body": "Mensaje de ejemplo",
    "segments": 1,
    "price": "100.00",
    "response": "successfully",
    "message_status_id": 1,
    "provider_codes_id": 1,
    "providers_id": 2,
    "message_types_id": 1,
    "service_types_id": 2,
    "campaigns_id": 110,
    "accounts_id": 37,
    "created_at": "2024-03-31 11:17:41",
    "updated_at": "2024-04-27 17:48:19"
}`

    const code_webhook_incomming = `{
    "id": 245,
    "from": "573012345678",
    "to": "573042575514",
    "body": "Mensaje de entrada",
    "segments": 1,
    "message_status_id": 1,
    "message_types_id": 1,
    "service_types_id": 2,
    "accounts_id": 37,
    "created_at": "2024-03-31 11:17:41",
    "updated_at": "2024-04-27 17:48:19"
}`;
    return (
        <div className="col-12">
            <CCard className="mb-4">
                <CCardHeader>
                    <strong>Documentación</strong>
                </CCardHeader>
                <CCardBody>

                    <CRow>
                        <CCol sm={4}>
                            <CCard className="shadow-lg">
                                <CCardBody>
                                    <CCardTitle>Webhook</CCardTitle>

                                    <CNav variant="tabs" className="my-3">
                                        <CNavItem>
                                            <CNavLink
                                                active={activeTab === 0}
                                                onClick={() => setActiveTab(0)}
                                                className="cursor-pointer"
                                            >
                                                Cambio de estado
                                            </CNavLink>
                                        </CNavItem>
                                        <CNavItem>
                                            <CNavLink
                                                active={activeTab === 1}
                                                onClick={() => setActiveTab(1)}
                                                className="cursor-pointer"
                                            >
                                                Mensajes entrantes
                                            </CNavLink>
                                        </CNavItem>
                                    </CNav>


                                    {/* Contenido de los tabs */}
                                    <CTabContent className="mb-3">
                                        <CTabPane visible={activeTab === 0}>
                                            <CopyBlock
                                                text={code_webhook_status}
                                                language='json'
                                                showLineNumbers={true}
                                                theme={dracula}
                                            // codeBlock
                                            />
                                        </CTabPane>
                                        <CTabPane visible={activeTab === 1}>

                                            <CopyBlock
                                                text={code_webhook_incomming}
                                                language='json'
                                                showLineNumbers={true}
                                                theme={dracula}
                                            // codeBlock
                                            />

                                        </CTabPane>
                                    </CTabContent>
                                    <strong >Configurar Webhook</strong>
                                    <CCardText>
                                        A este link que configures seran enviados los cambios de estado y mensajes entrantes.
                                    </CCardText>
                                    <CInputGroup className="mb-3">
                                        <CInputGroupText id="basic-addon1" className="bg-orange text-light fw-bold">POST</CInputGroupText>
                                        <CFormInput placeholder="https://www.marketsms.co/webhooks/event" aria-label="webhook" aria-describedby="basic-addon1" />
                                    </CInputGroup>

                                    <CButton color="primary" href="#">Guardar</CButton>
                                </CCardBody>
                            </CCard>
                        </CCol>
                        <CCol sm={4}>
                            <CCard className="mb-3 shadow-lg">
                                <CCardBody>
                                    <CCardTitle>Api Key</CCardTitle>
                                    <CCardText>
                                        La Api-Key la puedes obtener y actualizar en el espacio de cuentas oprimiendo en la opción de ver el detalle de la cuenta, cada cuenta tiene una Api-Key unica e intransferible.
                                    </CCardText>
                                    <NavLink to={'/user/administration/accounts'}>
                                        <CButton color="primary">Ir a cuentas</CButton>
                                    </NavLink>
                                </CCardBody>
                            </CCard>
                            <CCard className="shadow-lg">
                                <CCardBody>
                                    <CCardTitle>Documentación de APIs</CCardTitle>
                                    <CCardText>
                                        Te puedes redirigir a la interfaz Apis donde encuentras la documentación para que puedas usar y probar el funcionamiento de cada una de ellas
                                    </CCardText>
                                    <NavLink to={'/developer/apis'}>
                                        <CButton color="primary">Ir a APIs</CButton>
                                    </NavLink>
                                </CCardBody>
                            </CCard>
                        </CCol>
                        <CCol sm={4}>
                            <CCard className="shadow-lg">
                                <CCardBody>
                                    <CCardTitle>Ips permitidas</CCardTitle>
                                    <CCardText>
                                        En el menú de cuentas puedes ver que ips son permitidas para hacer peticiones a la API, esta ips se pueden eliminar al igual que agregar nuevas. Cada cuenta tiene su listado de IPs permitidas para realizar peticiones.
                                    </CCardText>
                                    <NavLink to={'/user/administration/accounts'}>
                                        <CButton color="primary">Ir a cuentas</CButton>
                                    </NavLink>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
        </div>
    );
};
