import React, { useEffect, useState } from "react";
import {
  CButton,
  CSmartTable,
  CCard,
  CCardHeader,
  CCardBody,
  CTabContent,
  CTabPane,
  CBadge
} from "@coreui/react-pro";

import * as MarketsmsService from "../../services/MarketsmsService";
import { CampaignsItems } from "../../models/Campaigns";
import { BIcon } from "../../components/icons/BIcon";
import { getBadgeCampaignStatus, getCurrentAccount } from "../../Utils/BaseApp";
import { Link } from "react-router-dom";
import { MessagesItems } from "../../models/Messages";

interface WhatsappCampaignsProps { }

export const WhatsappCampaigns: React.FC<WhatsappCampaignsProps> = ({ }: WhatsappCampaignsProps) => {
  const [campaigns, setCampaigns] = useState<CampaignsItems[] | null>(null);

  const getCampaigns = async () => {
    const current_account = await getCurrentAccount();

    var response = null;
    if (current_account) {
      response = await MarketsmsService.getCampaignsByAccount(3);
    } else {
      response = await MarketsmsService.getCampaigns();
    }
    if (response) {
      if (response.status === 200) {
        setCampaigns(response.data.items);
      }
    }
  };

  useEffect(() => {
    getCampaigns();
  }, []);

  const columns = [
    {
      key: "id",
      _style: { width: "4%" },
    },
    {
      key: "name",
      label: "Nombre",
      _style: { width: "8%" },
    },
    {
      key: "description",
      label: "Descripción",
      _style: { width: "16%" },
    },
    {
      key: "schedule_date",
      label: "Programada",
      _style: { width: "15%" },
    },
    {
      key: "number_messages",
      label: "# Msjs",
      _style: { width: "8%" },
    },
    {
      key: "price",
      label: "Precio",
      _style: { width: "8%" },
    },
    {
      key: "account_name",
      label: "Cuenta",
      _style: { width: "10%" },
    },
    {
      key: "created_at",
      label: "Fecha",
      _style: { width: "15%" },
    },
    {
      key: "campaigns_status_id",
      label: "Estado",
      _style: { width: "8%" },
    },
    {
      key: "operations",
      label: "Acción",
      _style: { width: "8%" },
    }
  ];

  return (
    <div className="col-12">
      <CCard className="mb-4">
        <CCardHeader >
          <strong>Campañas WhatsApp</strong>
        </CCardHeader>
        <CCardBody>
          <CTabContent className="rounded-bottom">
            <CTabPane
              className="p-3 active preview show"
              role="tabpanel"
              id="preview-578"
            >
              <CSmartTable
                activePage={2}
                cleaner
                clickableRows
                columns={columns}
                // itemsNumber={campaigns.length}
                // noItemsLabel
                columnFilter
                columnSorter
                loading={campaigns === null ? true : false}
                items={campaigns ?? []}
                itemsPerPageSelect
                itemsPerPage={10}
                pagination
                onFilteredItemsChange={(items) => {
                }}
                onSelectedItemsChange={(items) => {
                }}
                scopedColumns={{
                  campaigns_status_id: (campaign: CampaignsItems) => (
                    <td>
                      <CBadge color={getBadgeCampaignStatus(campaign.campaigns_status_id)}>
                        {campaign.status_name}
                      </CBadge>
                    </td>
                  ),
                  operations: (campaign: CampaignsItems) => {
                    return (
                      <td className="py-2 text-center">
                        {/* <Link to={"#"}>
                          <CButton
                            color="info"
                            variant="outline"
                            shape="square"
                            className="m-1"
                            size="sm"
                            title="Ver más"
                            onClick={() => {
                              // showTemplate(message);
                            }}
                          >
                            <BIcon icon={"eye"} />
                          </CButton>
                        </Link> */}
                        <Link to={"/whatsapp/campaign/" + campaign.id + "/messages"}>
                          <CButton
                            color="success"
                            variant="outline"
                            shape="square"
                            className="m-1"
                            size="sm"
                            title="Ver más"
                          >
                            <BIcon icon={"whatsapp"} />
                          </CButton>
                        </Link>
                      </td>
                    );
                  },
                }}
                // selectable
                sorterValue={{ column: "status", state: "asc" }}
                tableFilter
                tableProps={{
                  className: "add-this-class",
                  responsive: true,
                  striped: true,
                  hover: true,
                }}
                tableBodyProps={{
                  className: "align-middle",
                }}
              />
            </CTabPane>
          </CTabContent>
        </CCardBody>
      </CCard>
    </div>
  );
};
