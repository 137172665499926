import React, { useEffect, useState } from "react";
import {
  CSmartTable,
  CCard,
  CCardHeader,
  CCardBody,
  CTabContent,
  CTabPane,
  CBadge,
  CButton,
} from "@coreui/react-pro";

import * as MarketsmsService from "../../services/MarketsmsService";

import { getBadge } from "../../Utils/BaseApp";
import { ErrorCodesItems } from "../../models/ErrorCodes";
import CIcon from "@coreui/icons-react";
import { cilPencil, cilPlus } from "@coreui/icons";
import { PlansItems } from "../../models/Plans";

interface ErrorCodesProps { }

export const ErrorCodesAdminsitrator: React.FC<ErrorCodesProps> = ({ }: ErrorCodesProps) => {
  const [errorCodes, setErrorCodes] = useState<ErrorCodesItems[] | null>(null);

  const getErrorCodes = async () => {

    var response = await MarketsmsService.getErrorCodes();

    if (response) {
      if (response.status === 200) {
        setErrorCodes(response.data.items);
      }
    }
  };

  useEffect(() => {
    getErrorCodes();
  }, []);

  const columns = [
    {
      key: "code",
      label: "Código",
      _style: { width: "5%" },
    },
    {
      key: "message",
      label: "Mensaje",
      _style: { width: "20%" },
    },
    {
      key: "description",
      label: "descripción",
      _style: { width: "20%" },
    },
    {
      key: "cause",
      label: "Causa",
      _style: { width: "25%" },
    },
    {
      key: "code_app",
      label: "Código App",
      _style: { width: "10%" },
    },
    {
      key: "status_code",
      label: "Estado",
      _style: { width: "10%" },
    },
    {
      key: "operations",
      label: "Acción",
      _style: { width: "10%" },
    }
  ];

  return (
    <div className="col-12">
      <CCard className="mb-4">
        <CCardHeader>
          <strong>Codigos de Error</strong>
        </CCardHeader>
        <CCardBody>
          <CButton
          // onClick={() => setVisible(!visible)}
          >
            <CIcon icon={cilPlus} /> Agregar
          </CButton>
          <CTabContent className="rounded-bottom">
            <CTabPane
              className="p-3 active preview show"
              role="tabpanel"
              id="preview-578"
            >
              <CSmartTable
                activePage={2}
                cleaner
                clickableRows
                columns={columns}
                columnFilter
                columnSorter
                loading={errorCodes === null ? true : false}
                items={errorCodes ?? []}
                itemsPerPageSelect
                itemsPerPage={10}
                pagination
                scopedColumns={{
                  status_code: (error_code: ErrorCodesItems) => (
                    <td>
                      <CBadge color={getBadge(error_code.status_code)}>
                        {error_code.status_code}
                      </CBadge>
                    </td>
                  ),
                  operations: (plan: PlansItems) => (
                    <td>
                        <CButton
                            color="info"
                            variant="outline"
                            shape="square"
                            className="m-1"
                            size="sm"
                            title="Ver más"
                            // onClick={() => { loginAsUser(user.id) }}
                        >
                            <CIcon icon={cilPencil} />
                        </CButton>

                    </td>
                )
                }}
                sorterValue={{ column: "status", state: "asc" }}
                tableFilter
                tableProps={{
                  className: "add-this-class",
                  responsive: true,
                  striped: true,
                  hover: true,
                }}
                tableBodyProps={{
                  className: "align-middle",
                }}
              />
            </CTabPane>
          </CTabContent>
        </CCardBody>
      </CCard>
    </div>
  );
};