import React, { useEffect, useRef, useState } from "react";
import {
  CCard,
  CCardHeader,
  CCardBody,
  CTabContent,
  CTabPane,
  CForm,
  CFormInput,
  CFormTextarea,
  CCol,
  CFormCheck,
  CButton,
  CRow,
  CSmartTable,
  CDatePicker,
} from "@coreui/react-pro";

import * as MarketsmsService from "../../services/MarketsmsService";
import { MessagesPostModel } from "../../models/Messages";
import {
  customMessage,
  getCurrentAccount,
  showToastTC,
  showToastTR,
} from "../../Utils/BaseApp";
import { GroupsItems } from "../../models/Groups";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { selectMessage } from "../../redux/selectors";
import { setSegments } from "../../redux/features/messageSlice";
import { getSegments } from "../../Utils/Messages";
import { useNavigate } from "react-router-dom";

interface SendCampaignProps { }

export const SendCampaign: React.FC<
  SendCampaignProps
> = ({ }: SendCampaignProps) => {
  const [validated, setValidated] = useState(false);
  const [scheduleCampaign, setScheduleCampaign] = useState(false);
  const [postSendMessage, setPostSendMessage] = useState<MessagesPostModel | null>(null);
  const bodyRef = useRef<HTMLTextAreaElement>(null);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { segments } = useAppSelector(selectMessage)

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const form = event.currentTarget as HTMLFormElement;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (formData.groups.length) {
        sendCampaign();
      } else {
        showToastTC("Debes seleccionar la menos un grupo para enviar la campaña", 4000, "error");
      }
    }
    setValidated(true);
  };

  const sendCampaign = async () => {
    const response = await MarketsmsService.sendCampaingToGroups(formData);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        showToastTR(customMessage(response.code_app), 1000, "success");
        navigate('/campaign/' + response.data.id + '/messages')
        setPostSendMessage(response);
      } else {
        showToastTR(customMessage(response.code_app), 5000, "error");
      }
    }
  };

  const [formData, setFormData] = useState<{
    name: string;
    description: string;
    schedule_date: null;
    body: string;
    groups: number[];
  }>({
    name: "",
    description: "",
    schedule_date: null,
    body: "",
    groups: [],
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("Handle");
    var { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date: any, format_date: any) => {
    formData.schedule_date = format_date;
  };

  const handleChangeBody = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    var { name, value } = event.target;
    dispatch(setSegments({ segments: getSegments(value) }))
    setFormData({ ...formData, [name]: value });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { name, value } = event.currentTarget;
    formData.body = formData.body + value;
    setFormData({ ...formData });
    if (bodyRef.current !== null) {
      bodyRef.current.focus();
    }
  };

  const [groups, setGroups] = useState<GroupsItems[]>([]);

  const getGroups = async () => {
    const current_account = await getCurrentAccount();
    console.log(current_account);
    var response = null;
    if (current_account) {
      response = await MarketsmsService.getActiveGroupsByAccoount();
    } else {
      // response = await MarketsmsService.getGroups();
      console.log("No existe una cuenta escogida 3");
    }
    if (response) {
      if (response.status === 200) {
        setGroups(response.data.items);
      }
    }
  };

  useEffect(() => {
    if (!scheduleCampaign) {
      formData.schedule_date = null;
    }
  }, [scheduleCampaign]);

  const columns = [
    {
      key: "id",
      _style: { width: "10%" },
    },
    {
      key: "name",
      label: "Nombre",
      _style: { width: "30%" },
    },
    {
      key: "description",
      label: "Descripción",
      _style: { width: "30%" },
    },
    {
      key: "quantity_contacts",
      label: "Cantidad de contactos",
      _style: { width: "30%" },
    },
  ];

  useEffect(() => {
    getGroups();
  }, []);

  return (
    <div className="col-12">
      <CCard className="mb-4">
        <CCardHeader>
          <strong>Enviar campaña</strong>
        </CCardHeader>
        <CCardBody>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Grupos</strong>
            </CCardHeader>
            <CCardBody>
              <CSmartTable
                // activePage={2}
                // cleaner
                clickableRows
                columns={columns}
                // columnFilter
                columnSorter
                // footer
                items={groups}
                // itemsPerPageSelect
                itemsPerPage={5}
                pagination
                // onFilteredItemsChange={(items) => {
                //   // console.log(items)
                // }}
                onSelectedItemsChange={(items) => {
                  const selectedGroups: number[] = []; // Array para almacenar los IDs seleccionados

                  items.forEach((element) => {
                    selectedGroups.push(element.id); // Agregar cada ID al array
                  });

                  setFormData({ ...formData, ["groups"]: selectedGroups });

                  // setSelectedGroups(selectedGroups);
                }}
                // scopedColumns={{}}
                selectable
                sorterValue={{ column: "id", state: "asc" }}
                tableFilter
                tableProps={{
                  className: "add-this-class",
                  responsive: true,
                  striped: true,
                  hover: true,
                }}
                tableBodyProps={{
                  className: "align-middle",
                }}
              />
            </CCardBody>
          </CCard>
          <CRow>
            <CCol>
              <CTabContent className="rounded-bottom">
                <CTabPane
                  className="p-3 active preview show"
                  role="tabpanel"
                  id="preview-578"
                >
                  <CForm
                    className="row g-3 needs-validation"
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <CCol md={12}>
                      <CFormInput
                        value={formData.name}
                        type="text"
                        name="name"
                        placeholder="Bienvenida a usuarios"
                        feedbackValid="Luce bien!"
                        feedbackInvalid="Por favor escriba un número de teléfono valido."
                        id="validationCustom02"
                        label="Nombre"
                        onChange={handleChange}
                        required
                      />
                    </CCol>
                    <CCol md={12}>
                      <CFormInput
                        value={formData.description}
                        type="text"
                        name="description"
                        placeholder="Realizar bienvenida a usuarios nuevos registrados"
                        feedbackValid="Luce bien!"
                        feedbackInvalid="Por favor escriba un número de teléfono valido."
                        id="validationCustom02"
                        label="Descripción"
                        onChange={handleChange}
                        required
                      />
                    </CCol>
                    <CCol md={12}>
                      <CRow>
                        <CCol md={6}>Mensaje</CCol>
                        <CCol md={6} className={`text-end text-${segments < 2 ? 'success' : 'danger'}`}><strong>Segmentos:</strong> {segments}</CCol>
                      </CRow>
                      <CFormTextarea
                        ref={bodyRef}
                        name="body"
                        placeholder="Escriba aqui el mensaje a enviar"
                        feedbackValid="Luce bien!"
                        id="validationCustom02"
                        className="mt-2"
                        // label="Mensaje"
                        onChange={handleChangeBody}
                        value={formData.body}
                        feedbackInvalid="Por favor escriba un mensaje valido."
                        rows={3}
                        required
                      />
                    </CCol>
                    <CCol md={12}>
                      <CFormCheck
                        id="checkSaveSource"
                        type="checkbox"
                        checked={scheduleCampaign}
                        onChange={() => setScheduleCampaign(!scheduleCampaign)}
                        label="Programar campaña"
                      />
                    </CCol>

                    {scheduleCampaign && (
                      <CDatePicker
                        id="scheduled_date"
                        locale="en-US"
                        timepicker
                        format="yyyy-MM-dd HH:mm"
                        onDateChange={handleDateChange}
                      />
                    )}

                    <CCol xs={12}>
                      <CButton color="primary" type="submit">
                        Enviar
                      </CButton>
                    </CCol>
                  </CForm>
                </CTabPane>
              </CTabContent>
            </CCol>
            <CCol>
              <CCard className="mb-4 h-100 text-center p-4 px-5">
                <CTabContent className="rounded-bottom">
                  <CTabPane
                    className="p-3 active preview show"
                    role="tabpanel"
                    id="preview-578"
                  >
                    <CRow>
                      <CButton
                        color="info"
                        variant="outline"
                        className="mb-3"
                        value={`{first_name}`}
                        onClick={handleClick}
                      >{`{first_name}`}</CButton>
                    </CRow>
                    <CRow>
                      <CButton
                        color="info"
                        variant="outline"
                        className="mb-3"
                        value={`{last_name}`}
                        onClick={handleClick}
                      >{`{last_name}`}</CButton>
                    </CRow>
                  </CTabPane>
                </CTabContent>
              </CCard>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </div>
  );
};
