import React from 'react';
import { selectLoad } from "../../redux/selectors";
import { CSpinner } from '@coreui/react-pro';
import { useAppSelector } from '../../hooks/hooks';

interface LoadingProps {
  message: string | null;
}

const LoadingContainer: React.FC<LoadingProps> = ({ message }) => {
  const { loading } = useAppSelector(selectLoad);

  const showModal = () => {
    document.body.classList.add('modal-open');
  };

  const hideModal = () => {
    document.body.classList.remove('modal-open');
  };


  if (loading) {
    showModal()
  } else {
    hideModal()
  }

  return <>
    {loading && (
      <div className="modal-container">
        <div className="loading-container d-flex justify-content-center text-center shadow-lg">
          <CSpinner color="success" />
        </div>
      </div>
    )}
  </>
};

export default LoadingContainer;

