import React, { useState } from "react";
import {
    CCard,
    CCardHeader,
    CCardBody,
    CCardFooter,
    CButton,
    CForm,
    CFormInput,
    CAlert,
} from "@coreui/react-pro";
import { Link } from "react-router-dom";
import { customMessage, encrypt, getCurrentUser, showToastTC, showToastTR } from "../../Utils/BaseApp";
import * as MarketsmsService from "../../services/MarketsmsService";
import { useAppDispatch } from "../../hooks/hooks";
import { startLoading, stopLoading } from "../../redux/features/loadSlice";
import { userLogout } from "../../Utils/Auth";

interface ChangePasswordProps { }

export const ChangePassword: React.FC<ChangePasswordProps> = ({ }: ChangePasswordProps) => {
    const dispatch = useAppDispatch();

    const [formData, setFormData] = useState({
        old_password: "",
        password: "",
    });

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        var { name, value } = event.target;
        value = await encrypt(value)
        setFormData({ ...formData, [name]: value });
    };

    const changePassword = () => {
        console.log(formData);

        dispatch(startLoading())
        getCurrentUser().then((current_user) => {
            if (current_user?.id) {
                MarketsmsService.changePassword(current_user.id, formData).then((response) => {
                    dispatch(stopLoading())
                    if (response.status == 200) {
                        showToastTR(customMessage(response.code_app), 2000, "success");
                        setTimeout(() => {
                            userLogout()
                        }, 2000);
                    } else {
                        showToastTC(customMessage(response.code_app), 3000, "error");
                    }
                })
            }
        })
    }

    return (
        <div className="col-12">
            <CCard className="mb-4">
                <CCardHeader>
                    <strong>Cambiar Contraseña</strong>
                </CCardHeader>
                <CCardBody>
                    <CAlert color="danger" className="mb-5">
                        Si estás cambiando tu contraseña debido a un acceso no autorizado a tu cuenta, te recomendamos encarecidamente que también cambies otras credenciales sensibles, como tu API Key, así como las direcciones IP de confianza asociadas. Estas medidas adicionales de seguridad pueden ayudar a proteger tus datos y mantener tu cuenta segura. Puedes realizar estos cambios en cada una de tus cuentas aquí mismo. <Link to={'/user/administration/accounts'} className="text-decoration-none cursor-pointer">aqui</Link>
                    </CAlert>
                    <CForm>
                        <CFormInput
                            type="password"
                            name="old_password"
                            label="Actual contraseña"
                            placeholder="*******************"
                            className="w-25 mb-4"
                            onChange={handleChange}
                        />
                        <CFormInput
                            type="password"
                            name="password"
                            label="Nueva contraseña"
                            placeholder="*******************"
                            className="w-25 mb-5"
                            onChange={handleChange}
                        />
                    </CForm>
                </CCardBody>
                <CCardFooter>
                    <CButton onClick={changePassword}>Cambiar Contraseña</CButton>
                </CCardFooter>
            </CCard>
        </div >
    );
};