import React, { useEffect, useState } from "react";
import {
  CSmartTable,
  CCard,
  CCardHeader,
  CCardBody,
  CTabContent,
  CTabPane,
  CBadge,
  CButton,
} from "@coreui/react-pro";

import * as MarketsmsService from "../../services/MarketsmsService";

import { getBadge } from "../../Utils/BaseApp";
import { AccountsItems } from "../../models/Accounts";
import { BIcon } from "../../components/icons/BIcon";
import CIcon from "@coreui/icons-react";
import { cilDollar, cilPencil, cilPlaylistAdd, cilPlus } from "@coreui/icons";
import { Link } from "react-router-dom";

interface AccountsProps { }

export const AccountsAdministrator: React.FC<AccountsProps> = ({ }: AccountsProps) => {
  const [accounts, setAccounts] = useState<AccountsItems[] | null>(null);

  const getAccounts = async () => {

    var response = await MarketsmsService.getAccounts();
    console.log(response)
    if (response) {
      if (response.status === 200) {
        console.log(response.data.items)
        setAccounts(response.data.items);
      }
    }
  };

  useEffect(() => {
    getAccounts();
  }, []);

  const columns = [
    {
      key: "id",
      _style: { width: "5%" },
    },
    {
      key: "name",
      label: "Nombre",
      _style: { width: "20%" },
    },
    {
      key: "plans_name",
      label: "Plan",
      _style: { width: "20%" },
    },
    {
      key: "status_name",
      label: "Estado",
      _style: { width: "10%" },
    },
    {
      key: "balance",
      label: "Saldo",
      _style: { width: "10%" },
    },
    {
      key: "created_at",
      label: "Fecha de creación",
      _style: { width: "20%" },
    },
    {
      key: "operations",
      label: "Acción",
      _style: { width: "10%" },
    },
  ];


  return (
    <div className="col-12">
      <CCard className="mb-4">
        <CCardHeader>
          <strong>Cuentas</strong>
        </CCardHeader>
        <CCardBody>
          <CTabContent className="rounded-bottom">
            <CTabPane
              className="p-3 active preview show"
              role="tabpanel"
              id="preview-578"
            >
              <CSmartTable
                activePage={2}
                cleaner
                clickableRows
                columns={columns}
                columnFilter
                columnSorter
                loading={accounts === null ? true : false}
                items={accounts ?? []}
                itemsPerPageSelect
                itemsPerPage={10}
                pagination
                scopedColumns={{
                  status_name: (account: AccountsItems) => (
                    <td>
                      <CBadge color={getBadge(account.status_id)}>
                        {account.status_name}
                      </CBadge>
                    </td>
                  ),
                  operations: (account: AccountsItems) => {
                    return (
                      <td className="py-2 text-center">

                        <CButton
                          color="info"
                          variant="outline"
                          shape="square"
                          className="m-1"
                          size="sm"
                        >
                          <CIcon icon={cilPencil} />
                        </CButton>



                        <CButton
                          color="success"
                          variant="outline"
                          shape="square"
                          className="m-1"
                          size="sm"
                          onClick={() => {
                            // showDetails(account);
                          }}
                        >
                          <CIcon icon={cilPlus} /> Ip
                        </CButton>
                      </td>
                    );
                  },
                }}
                sorterValue={{ column: "status", state: "asc" }}
                tableFilter
                tableProps={{
                  className: "add-this-class",
                  responsive: true,
                  striped: true,
                  hover: true,
                }}
                tableBodyProps={{
                  className: "align-middle",
                }}
              />
            </CTabPane>
          </CTabContent>
        </CCardBody>
      </CCard>
    </div>
  );
};