import React from "react";
import {
    CCard,
    CCardHeader,
    CCardBody
} from "@coreui/react-pro";

import SwaggerDocs from "../../components/swagger/SwaggerDocs";

interface HelpProps { }

export const Help: React.FC<HelpProps> = ({ }: HelpProps) => {

    return (
        <div className="col-12">
            <CCard className="mb-4">
                <CCardHeader>
                    <strong>Ayuda</strong>
                </CCardHeader>
                <CCardBody>
                    <p>Preguntas frecuentes</p>
                    <p>Correo y número de contacto por whatsapp, email,Tel</p>
                    <p>Bot en laparte izquierda que responda preguntas tipo IA</p>
                </CCardBody>
            </CCard>
        </div>
    );
};
