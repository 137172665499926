import React, { useEffect, useState } from "react";
import {
    CSmartTable,
    CCard,
    CCardHeader,
    CCardBody,
    CTabContent,
    CTabPane,
    CBadge,
    CButton,
} from "@coreui/react-pro";

import * as MarketsmsService from "../../services/MarketsmsService";

import { customMessage, getBadge, getCurrentAccount, showToastTR } from "../../Utils/BaseApp";
import { AccountsItems } from "../../models/Accounts";
import { BIcon } from "../../components/icons/BIcon";
import CIcon from "@coreui/icons-react";
import { cilDollar, cilPencil, cilPlaylistAdd, cilPlus } from "@coreui/icons";
import { Link } from "react-router-dom";
import { WhatsappPhoneNumbersItemsModel } from "../../models/WhatsappNumbers";

interface AccountsProps { }

export const WhatsappNumbers: React.FC<AccountsProps> = ({ }: AccountsProps) => {
    //   const [accounts, setAccounts] = useState<AccountsItems[] | null>(null);
    const [whatsappNumbers, setWhatsappNumbers] = useState<WhatsappPhoneNumbersItemsModel[] | null>(null);

    const getWhatsappNumbers = async () => {

        var response = await MarketsmsService.getWhatsappNumbers();
        console.log(response)
        if (response) {
            if (response.status === 200) {
                console.log(response.data.items)
                setWhatsappNumbers(response.data.items);
            }
        }
    };

    useEffect(() => {
        getWhatsappNumbers();
    }, []);

    const columns = [
        {
            key: "id",
            _style: { width: "5%" },
        },
        {
            key: "name",
            label: "Nombre",
            _style: { width: "20%" },
        },
        {
            key: "phone_number",
            label: "Número",
            _style: { width: "15%" },
        },
        {
            key: "quality_rating",
            label: "Estado",
            _style: { width: "10%" },
        },
        {
            key: "default",
            label: "Por defecto",
            _style: { width: "15%" },
        },
        {
            key: "created_at",
            label: "Fecha de creación",
            _style: { width: "15%" },
        },
        {
            key: "operations",
            label: "Operaciones",
            _style: { width: "15%" },
        },

    ];


    const setDefaultWhatsappPhoneNumber = (phone_number: WhatsappPhoneNumbersItemsModel) => {
        getCurrentAccount().then((currentAccount: AccountsItems | null) => {
            MarketsmsService.setDefaultWhatsappNumber(phone_number.id).then((response) => {
                if (response.status == 200) {

                    if (localStorage.getItem('default_phone_number') != null && (localStorage.getItem('default_phone_number') == phone_number.id.toString())) {
                        showToastTR(customMessage(response.code_app) + '\nPara deseleccionar la cuenta debes escoger otra por defecto', 5000, "info");
                    } else {
                        showToastTR(customMessage(response.code_app), 2000, "success");
                    }


                    localStorage.setItem("default_phone_number", (response.data?.id) + "");
                    getWhatsappNumbers();
                } else {
                    showToastTR(customMessage(response.code_app), 1000, "error");
                }
            });
        });
    };

    return (
        <div className="col-12">
            <CCard className="mb-4">
                <CCardHeader>
                    <strong>Números de WhatsApp</strong>
                </CCardHeader>
                <CCardBody>
                    <CTabContent className="rounded-bottom">
                        <CTabPane
                            className="p-3 active preview show"
                            role="tabpanel"
                            id="preview-578"
                        >
                            <CSmartTable
                                activePage={2}
                                cleaner
                                clickableRows
                                columns={columns}
                                columnFilter
                                columnSorter
                                loading={whatsappNumbers === null ? true : false}
                                items={whatsappNumbers ?? []}
                                itemsPerPageSelect
                                itemsPerPage={10}
                                pagination
                                scopedColumns={{
                                    default: (phone_number: WhatsappPhoneNumbersItemsModel) => {
                                        return (
                                            <td className="py-2 text-center">
                                                <BIcon icon={"star-fill"} className={`text-${phone_number.default === 1 ? 'warning' : 'dark'}`} onClick={() => { setDefaultWhatsappPhoneNumber(phone_number) }} />
                                            </td>
                                        )
                                    },
                                    operations: (phone_number: WhatsappPhoneNumbersItemsModel) => {
                                        return (
                                            <td className="py-2 text-center">
                                                <Link to={phone_number.id+"/settings"}>
                                                    <CButton
                                                        color="info"
                                                        variant="outline"
                                                        shape="square"
                                                        className="m-0"
                                                        size="sm"
                                                        title="Configuraciones"
                                                        onClick={() => {
                                                            // showDetails(message);
                                                        }}
                                                    >
                                                        <BIcon icon={"gear"} />
                                                    </CButton>
                                                </Link>
                                            </td>
                                        )
                                    }

                                }}
                                sorterValue={{ column: "status", state: "asc" }}
                                tableFilter
                                tableProps={{
                                    className: "add-this-class",
                                    responsive: true,
                                    striped: true,
                                    hover: true,
                                }}
                                tableBodyProps={{
                                    className: "align-middle",
                                }}
                            />
                        </CTabPane>
                    </CTabContent>
                </CCardBody>
            </CCard>
        </div>
    );
};