import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import { getFirestore } from "firebase/firestore";
import { env } from "../Utils/Environment";

const firebaseConfig = {
  apiKey: env('API_KEY'),
  authDomain: env('AUTH_DOMAIN'),
  projectId: env('PROJECT_ID'),
  storageBucket: env('STORAGE_BUCKET'),
  messagingSenderId: env('MESSAGING_SENDER_ID'),
  appId: env('APP_ID'),
  measurementId: env('MEASUREMENT_ID'),
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
export const db = getFirestore(app);