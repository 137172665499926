import React, { useEffect, useRef, useState } from "react";
import {
  CBadge,
  CButton,
  CSmartTable,
  CTable,
  CCard,
  CCardHeader,
  CCardBody,
  CTabContent,
  CTabPane,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CFormSelect,
  CForm,
  CCol,
  CFormInput,
  CTableBody,
  CTableRow,
  CTableHeaderCell,
  CTableDataCell,
  CImage,
  CRow,
} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import { PlansItems } from "../../models/Plans";
import * as MarketsmsService from "../../services/MarketsmsService";
import { AccountsPostModel, AccountsItems } from "../../models/Accounts";
import {
  customMessage,
  getCurrentAccount,
  setCurrentAccountDefault,
  showToastTC,
  showToastTR,
} from "../../Utils/BaseApp";
import {
  cilCheckAlt,
  cilCopy,
  cilDollar,
  cilPencil,
  cilPlus,
  cilReload,
  cilX,
} from "@coreui/icons";
import { ApiKeyPutModel } from "../../models/ApiKey";
import { IpDeleteModel, IpPostModel } from "../../models/Ip";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BIcon } from "../../components/icons/BIcon";

interface AccountsProps { action?: string }

export const Accounts: React.FC<AccountsProps> = ({ action }: AccountsProps) => {
  const { id } = useParams();
  const plan_id = parseInt(id + "");

  const [accounts, setAccounts] = useState<AccountsItems[] | null>(null);
  const [accountDefault, setAccountDefault] = useState<AccountsItems | null>(
    null
  );
  const [postAccount, setPostAccount] = useState<AccountsPostModel | null>(
    null
  );
  const [plans, setPlans] = useState<PlansItems[]>([]);
  const [putApiKey, setPutApiKey] = useState<ApiKeyPutModel | null>(null);
  const [deleteIp, setDeleteIp] = useState<IpDeleteModel | null>(null);
  const [visible, setVisible] = useState(false);
  const [visibleDetails, setVisibleDetails] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [validated, setValidated] = useState(false);
  const fileInput = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();


  // Abrir modal para crear cuenta
  useEffect(() => {
    if (action == 'createAccount') {
      setVisible(true);
    }
  }, [])

  const [previewImage, setPreviewImage] = useState<string | null>(null);

  const handleFileSelect = (files: FileList | null) => {
    if (files && files.length > 0) {
      const file = files[0];
      if (details) {
        MarketsmsService.uploadLogo(details?.id, file).then((response) => {
          setPreviewImage(response.data.url);
          const reader = new FileReader();
          reader.onload = () => {
            setPreviewImage(reader.result as string);
          };
          reader.readAsDataURL(file);
          getAccounts();
          showToastTR('Logo actualizado con éxito', 1000, 'success');
        })
      } else {
        showToastTR('Parece que no has selecciondo la cuenta para actualizar logo', 4000, 'error');
      }
    }
  };

  useEffect(() => {
    console.log(visibleDetails);
    if (visibleDetails === false) {
      setPreviewImage(null)
    }
  }, [visibleDetails])

  const [formData, setFormData] = useState({
    name: "",
    ip: "",
    plan_id: plan_id ?? 0,
  });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const form = event.currentTarget as HTMLFormElement;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      createAccount();
    }
    setValidated(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const getAccounts = async () => {
    const response = await MarketsmsService.getAccountsByUser();
    if (response.status === 200) {
      setAccounts(response.data.items);
    } else {
      setAccounts([])
    }
  };

  const getPlans = async () => {
    const response = await MarketsmsService.getPlansByStatus();
    if (response.status === 200) {
      setPlans(response.data?.items);
    }
  };

  const createAccount = async () => {
    MarketsmsService.createAccount(formData).then((response) => {
      if (response.status === 201) {
        showToastTR(customMessage(response.code_app), 1000, "success");
        setVisible(false);
        getAccounts();
        // Se hacen las dos redirecciones para que cuando este en payments y me devuelva no me muestre nuevamente la interfaz de crear cuenta
        navigate('/user/administration/accounts');
        navigate('/user/administration/accounts/payments/' + response.data.id);
      } else {
        showToastTR(customMessage(response.code_app), 1000, "error");
      }
    })
  };

  const refreshApiKey = async (id: number) => {
    const response = await MarketsmsService.refreshApiKey(id);
    if (response) {
      setPutApiKey(response);
      if (details?.api_key) {
        details.api_key = response.data.api_key;
        setDetails(details);
      }
    }
  };

  useEffect(() => {
    if (accounts) {
      if (accounts.length === 1) {
        setAccountDefault(accounts[0]);
        setCurrentAccountDefault(accounts[0]);
      }
    }
  }, [accounts]);

  useEffect(() => {
    if (putApiKey) {
      if (putApiKey?.status === 200) {
        showToastTR(customMessage(putApiKey.code_app), 500, "success");
        getAccounts();
      } else {
        showToastTR(customMessage(putApiKey?.code_app), 500, "error");
      }
    }
  }, [putApiKey]);

  useEffect(() => {
    getAccounts();
    getPlans();
  }, []);

  // useEffect(() => {
  //   if (postAccount) {
  //     if (postAccount.status === 201) {
  //       showToastTR(customMessage(postAccount.code_app), 1000, "success");
  //       setVisible(false);
  //       getAccounts();
  //     } else {
  //       showToastTR(customMessage(postAccount.code_app), 1000, "error");
  //     }
  //   }
  // }, [postAccount]);

  useEffect(() => {
    if (visible) {
      showToastTR(
        "Tenga en cuenta que para crear una nueva cuenta necesita haber recargado por lo menos una vez cada una de las cuentas actuales",
        4000,
        "info"
      );
    }
  }, [visible]);

  const [details, setDetails] = useState<AccountsItems>();

  const showDetails = (account: AccountsItems) => {
    if (account) {
      setDetails(account);
      setVisibleDetails(true);
    }
  };

  useEffect(() => {
    if (deleteIp) {
      if (deleteIp.status === 200) {
        showToastTR(customMessage(deleteIp.code_app), 500, "success");
      } else {
        showToastTR(
          customMessage(deleteIp.code_app) + " - " + deleteIp.code_app,
          1000,
          "error"
        );
      }
    }
  }, [deleteIp]);

  const removeIp = async (ip_delete: string, account_id: number) => {
    const response = await MarketsmsService.removeIpFromAccount(
      account_id,
      ip_delete
    );

    if (response) {
      setDeleteIp(response);
      if (details?.ip) {
        const ips = details.ip;
        details.ip = ips.filter((ip) => ip !== ip_delete);
        setDetails(details);
      }
    }
  };

  // Para agrergar Ip

  const [validatedAddIp, setValidatedAddIp] = useState(false);
  const [postIp, setPostIp] = useState<IpPostModel | null>(null);
  const [formDataAddIp, setFormDataAddIp] = useState({
    ip: "",
  });

  const handleSubmitAddIp = (event: React.FormEvent) => {
    event.preventDefault();
    const form = event.currentTarget as HTMLFormElement;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      addIpToAccount();
    }
    setValidatedAddIp(true);
  };

  const handleChangeAddIp = (event: React.ChangeEvent<HTMLInputElement>) => {
    var { name, value } = event.target;
    setFormDataAddIp({ ...formDataAddIp, [name]: value });
  };

  const addIpToAccount = async () => {
    if (details?.id) {
      const response = await MarketsmsService.addIpToAccount(
        details?.id,
        formDataAddIp
      );
      setPostIp(response);
    }
  };

  useEffect(() => {
    if (postIp) {
      if (postIp.status === 200) {
        showToastTR(customMessage(postIp.code_app), 500, "success");
        setVisibleEdit(false);
        getAccounts();
      } else {
        showToastTR(
          customMessage(postIp.code_app) + " - " + postIp.code,
          2000,
          "error"
        );
      }
    }
  }, [postIp]);

  const handleAccountDefault = (account: AccountsItems) => {
    getCurrentAccount().then((currentAccount: AccountsItems | null) => {
      console.log("getCurrentAccount")
      console.log(currentAccount)
      if (currentAccount) {
        if (account.id === currentAccount.id) {
          localStorage.removeItem("default_account");
          setAccountDefault(null);
        } else {
          if (account) {
            setCurrentAccountDefault(account);
            setAccountDefault(account);
          }
        }
      } else {
        console.log("Entra aqui");
        console.log(account);
        if (account) {
          console.log("Entra aqui 2");
          localStorage.setItem("default_account", JSON.stringify(account));
          setAccountDefault(account);
        }
      }
    });
  };

  // Recuperar desde localStorage al cargar el componente
  useEffect(() => {
    getCurrentAccount().then((account: AccountsItems | null) => {
      if (account) {
        setAccountDefault(account);
      }
    });
  }, []);

  const columns = [
    {
      key: "id",
      _style: { width: "5%" },
    },
    {
      key: "name",
      label: "Nombre",
      _style: { width: "10%" },
    },
    {
      key: "plans_name",
      label: "Plan",
      _style: { width: "10%" },
    },
    {
      key: "status_name",
      label: "Estado",
      _style: { width: "7%" },
    },
    {
      key: "balance",
      label: "Saldo",
      _style: { width: "10%" },
    },
    {
      key: "created_at",
      label: "Fecha de creación",
      _style: { width: "12%" },
    },
    {
      key: "operations",
      label: "Acción",
      _style: { width: "15%" },
    },
  ];

  const getBadge = (status: string) => {
    switch (status) {
      case "active":
        return "success";
      case "inactive":
        return "secondary";
      case "pending":
        return "warning";
      case "delete":
        return "danger";
      default:
        return "primary";
    }
  };

  useEffect(() => {
    console.log(accounts);
  }, [accounts])

  return (
    <div className="col-12">
      <CCard className="mb-4">
        <CCardHeader className="p-0 d-flex align-items-center ps-1">
          <BIcon icon="arrow-left-short" className="fs-3 me-1 pt-2 text-danger cursor-pointer" onClick={() => { navigate(-1) }} />
          <strong>Cuentas</strong>
        </CCardHeader>
        <CCardBody>
          <CButton onClick={() => setVisible(!visible)}>
            <CIcon icon={cilPlus} /> Agregar
          </CButton>
          <CTabContent className="rounded-bottom">
            <CTabPane
              className="p-3 active preview show"
              role="tabpanel"
              id="preview-578"
            >
              <CSmartTable
                activePage={2}
                cleaner
                clickableRows
                columns={columns}
                // columnFilter
                columnSorter
                // footer
                loading={accounts === null ? true : false}
                items={accounts ?? []}
                itemsPerPageSelect
                itemsPerPage={10}
                pagination
                onFilteredItemsChange={(items) => {
                  // console.log(items)
                }}
                onSelectedItemsChange={(items) => {
                  // console.log(items)
                }}
                scopedColumns={{
                  status_name: (account: AccountsItems) => (
                    <td>
                      <CBadge color={getBadge(account.status_name)}>
                        {account.status_name}
                      </CBadge>
                    </td>
                  ),
                  operations: (account: AccountsItems) => {
                    return (
                      <td className="py-2 text-center">
                        <Link
                          to={"/user/administration/accounts/payments/" + account.id}
                        >
                          <CButton
                            color="success"
                            variant="outline"
                            shape="square"
                            className="m-1"
                            size="sm"
                          >
                            <CIcon icon={cilDollar} />
                          </CButton>
                        </Link>
                        <CButton
                          color="info"
                          variant="outline"
                          shape="square"
                          className="m-1"
                          size="sm"
                          onClick={() => {
                            setDetails(account);
                            setVisibleEdit(true);
                          }}
                        >
                          <CIcon icon={cilPlus} /> Ip
                        </CButton>
                        <CButton
                          color="primary"
                          variant="outline"
                          shape="square"
                          className="m-1"
                          size="sm"
                          onClick={() => {
                            showDetails(account);
                          }}
                        >
                          <BIcon icon={"eye"} />
                        </CButton>
                        {(accounts !== null && accounts.length > 1) && (
                          <CButton
                            color={
                              account.id === accountDefault?.id
                                ? "warning"
                                : "dark"
                            }
                            title={
                              account.id === accountDefault?.id
                                ? "Desactivar cuenta por defecto"
                                : "Poner cuenta por defecto"
                            }
                            variant="outline"
                            shape="square"
                            className="m-1"
                            size="sm"
                            onClick={() => {
                              handleAccountDefault(account);
                            }}
                          >
                            <BIcon icon={"star-fill"} />
                          </CButton>
                        )}
                      </td>
                    );
                  },
                }}
                // selectable
                sorterValue={{ column: "status", state: "asc" }}
                tableFilter
                tableProps={{
                  className: "add-this-class",
                  responsive: true,
                  striped: true,
                  hover: true,
                }}
                tableBodyProps={{
                  className: "align-middle",
                }}
              />
            </CTabPane>
          </CTabContent>
        </CCardBody>
      </CCard>

      <CModal
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="LiveDemoExampleLabel"
      >
        <CModalHeader closeButton>
          <CModalTitle id="LiveDemoExampleLabel">Agregar cuenta</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CForm
            className="row g-3 needs-validation"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <CCol md={12}>
              <CFormInput
                type="text"
                name="name"
                minLength={2}
                placeholder="Escriba aqui el nombre de la cuenta"
                feedbackValid="Luce bien!"
                feedbackInvalid="Por favor escriba un nombre valido para la cuenta."
                id="validationCustom01"
                label="Nombre"
                onChange={handleChange}
                required
              />
            </CCol>
            <CCol md={12}>
              <CFormInput
                type="text"
                name="ip"
                minLength={7}
                maxLength={39}
                placeholder="Escriba aqui una ip permitida para el llamado de las APIs"
                feedbackValid="Luce bien!"
                feedbackInvalid="Por favor escriba una ip valida."
                id="validationCustom02"
                label="Ip"
                onChange={handleChange}
                required
              />
            </CCol>
            <CCol md={12}>
              <CFormSelect
                aria-describedby="validationCustom04Feedback"
                feedbackInvalid="Por favor selecciona un plan valido."
                id="validationCustom04"
                label="Plan"
                name="plan_id"
                onChange={handleSelectChange}
                required
              >
                <option disabled selected value={""}>
                  Seleccione el plan
                </option>
                {plans.map((plan, index) => {
                  return (
                    <option
                      key={index}
                      value={plan.id}
                      title={plan.description}
                      selected={plan.id == plan_id ? true : false}
                    >
                      {" "}
                      {plan.name}
                    </option>
                  );
                })}
              </CFormSelect>
            </CCol>

            <CModalFooter className="pb-0">
              <CButton color="light" onClick={() => setVisible(false)}>
                Close
              </CButton>
              <CButton color="primary" type="submit">
                Save changes
              </CButton>
            </CModalFooter>
          </CForm>
        </CModalBody>
      </CModal>

      <CModal
        visible={visibleDetails}
        onClose={() => setVisibleDetails(false)}
        aria-labelledby="LiveDemoExampleLabel"
      >
        <CModalHeader closeButton>
          <CModalTitle id="LiveDemoExampleLabel">{details?.name}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow className="justify-content-center mb-3">
            <CCol xs="auto" className="position-relative w-25 p-1">
              <CImage src={previewImage || (details?.logo || '')} className="w-100" />
              <div className="position-absolute bottom-0 end-0 m-1">
                <input
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileSelect(e.target.files)}
                  ref={fileInput as React.RefObject<HTMLInputElement>}
                />
                <CButton
                  color="info"
                  // variant="outline"
                  shape="square"
                  size="sm"
                  onClick={() => fileInput.current && fileInput.current.click()}
                >
                  <CIcon icon={cilPencil} />
                </CButton>
              </div>
            </CCol>
          </CRow>
          <CTable border={0}>
            <CTableBody>
              <CTableRow>
                <CTableHeaderCell scope="row">Id</CTableHeaderCell>
                <CTableDataCell>{details?.id}</CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableHeaderCell scope="row">Api Key</CTableHeaderCell>
                <CTableDataCell>
                  <div className="d-flex justify-content-between">
                    <p>{details?.api_key.substring(0, 30) + " ***"}</p>
                    <div>
                      <CButton
                        color="warning"
                        variant="outline"
                        shape="square"
                        className="m-1"
                        size="sm"
                        onClick={() => {
                          if (details?.id) {
                            refreshApiKey(details.id);
                          }
                        }}
                      >
                        <CIcon icon={cilReload} />
                      </CButton>

                      <CButton
                        color="primary"
                        variant="outline"
                        shape="square"
                        className="m-1"
                        size="sm"
                        onClick={() => {
                          navigator.clipboard.writeText(details?.api_key + "");
                          showToastTC("Copiado!", 100, "info");
                        }}
                      >
                        <CIcon icon={cilCopy} />
                      </CButton>
                    </div>
                  </div>
                </CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableHeaderCell scope="row">Plan</CTableHeaderCell>
                <CTableDataCell>{details?.plans_name}</CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableHeaderCell scope="row">Estado</CTableHeaderCell>
                <CTableDataCell>{details?.status_name}</CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableHeaderCell scope="row">Saldo</CTableHeaderCell>
                <CTableDataCell>{details?.balance}</CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableHeaderCell scope="row">Ips</CTableHeaderCell>
                <CTableDataCell>
                  {details &&
                    details.ip &&
                    details.ip.map(
                      (ip: string, index: number) =>
                        ip !== null && (
                          <CBadge color="info" className="m-1" key={index}>
                            {ip}{" "}
                            <CIcon
                              icon={cilX}
                              className="ctm-close"
                              onClick={() => {
                                removeIp(ip, details.id);
                              }}
                            />
                          </CBadge>
                        )
                    )}
                </CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableHeaderCell scope="row">Creación</CTableHeaderCell>
                <CTableDataCell>{details?.created_at}</CTableDataCell>
              </CTableRow>
            </CTableBody>
          </CTable>
        </CModalBody>
      </CModal>

      <CModal
        visible={visibleEdit}
        onClose={() => setVisibleEdit(false)}
        aria-labelledby="LiveDemoExampleLabel"
      >
        <CModalHeader closeButton>
          <CModalTitle id="LiveDemoExampleLabel">
            Editar cuenta <strong>{details?.name}</strong>
          </CModalTitle>
        </CModalHeader>
        <CModalBody className="mb-2">
          <CForm
            className="row g-3 needs-validation"
            noValidate
            validated={validatedAddIp}
            onClick={handleSubmitAddIp}
          >
            <CCol md={9}>
              <CFormInput
                type="text"
                name="ip"
                minLength={7}
                maxLength={39}
                placeholder="Escriba una ip permitida para uso de las APIs"
                feedbackValid="Luce bien!"
                feedbackInvalid="Por favor escriba una ip valida."
                id="validationCustom02"
                label="Agregar ip a lista blanca"
                onChange={handleChangeAddIp}
                required
              />
            </CCol>
            <CCol md={3}>
              <br />
              <CButton
                color="success"
                variant="outline"
                className="mt-2"
                type="submit"
              >
                <CIcon icon={cilCheckAlt} /> Guardar
              </CButton>
            </CCol>
          </CForm>
        </CModalBody>
      </CModal>
    </div>
  );
};
