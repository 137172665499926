import React, { useEffect, useState } from "react";
import {
  CSmartTable,
  CCard,
  CCardHeader,
  CCardBody,
  CTabContent,
  CTabPane,
  CBadge,
  CButton,
} from "@coreui/react-pro";

import * as MarketsmsService from "../../services/MarketsmsService";
import { UsersItems } from "../../models/Users";
import { getBadge, getBadgeRol } from "../../Utils/BaseApp";
import CIcon from "@coreui/icons-react";
import { cilPencil } from "@coreui/icons";

interface UsersProps { }

export const UsersAdministrator: React.FC<UsersProps> = ({ }: UsersProps) => {
  const [users, setUsers] = useState<UsersItems[] | null>(null);

  const getUsers = async () => {

    var response = await MarketsmsService.getUsers();

    if (response) {
      if (response.status === 200) {
        setUsers(response.data.items);
      }
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const columns = [
    {
      key: "id",
      label: "Id",
      _style: { width: "5%" },
    },
    {
      key: "email",
      label: "Email",
      _style: { width: "20%" },
    },
    {
      key: "first_name",
      label: "Nombre",
      _style: { width: "20%" },
    },
    {
      key: "last_name",
      label: "Apellido",
      _style: { width: "20%" },
    },
    {
      key: "status_name",
      label: "Estado",
      _style: { width: "10%" },
    },
    {
      key: "role_name",
      label: "Rol",
      _style: { width: "10%" },
    },
    {
      key: "operations",
      label: "Acción",
      _style: { width: "15%" },
    }
  ];

  return (
    <div className="col-12">
      <CCard className="mb-4">
        <CCardHeader>
          <strong>Usuarios</strong>
        </CCardHeader>
        <CCardBody>
          <CTabContent className="rounded-bottom">
            <CTabPane
              className="p-3 active preview show"
              role="tabpanel"
              id="preview-578"
            >
              <CSmartTable
                activePage={2}
                cleaner
                clickableRows
                columns={columns}
                columnFilter
                columnSorter
                loading={users === null ? true : false}
                items={users ?? []}
                itemsPerPageSelect
                itemsPerPage={10}
                pagination
                scopedColumns={{
                  status_name: (user: UsersItems) => (
                    <td>
                      <CBadge color={getBadge(user.status_id)}>
                        {user.status_name}
                      </CBadge>
                    </td>
                  ),
                  role_name: (user: UsersItems) => (
                    <td>
                      <CBadge color={getBadgeRol(user.roles_id)}>
                        {user.role_name}
                      </CBadge>
                    </td>
                  ),
                  operations: (user: UsersItems) => {
                    return (
                      <td className="py-2 text-center">

                        <CButton
                          color="info"
                          variant="outline"
                          shape="square"
                          className="m-1"
                          size="sm"
                        >
                          <CIcon icon={cilPencil} />
                        </CButton>
                      </td>
                    );
                  },
                }}
                sorterValue={{ column: "status", state: "asc" }}
                tableFilter
                tableProps={{
                  className: "add-this-class",
                  responsive: true,
                  striped: true,
                  hover: true,
                }}
                tableBodyProps={{
                  className: "align-middle",
                }}
              />
            </CTabPane>
          </CTabContent>
        </CCardBody>
      </CCard>
    </div>
  );
};