import CIcon from "@coreui/icons-react";
import {
  cibWhatsapp,
  cilCode,
  cilContact,
  cilMonitor,
  cilSpeedometer,
} from "@coreui/icons";
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react-pro";
import { BIcon } from "../components/icons/BIcon";
import { parseClassName } from "react-toastify/dist/utils";

const Nav = [
  {
    component: CNavItem,
    name: "Panel",
    to: "/dashboard",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon text-dark" />,
    // badge: {
    //   color: "info",
    //   text: "NUEVO",
    // },
  },
  {
    component: CNavTitle,
    name: "SERVICIOS",
  },
  {
    component: CNavGroup,
    name: "Mensajes",
    icon: <BIcon icon={'chat-dots'} className="text-warning" />,
    items: [
      {
        component: CNavItem,
        name: "Enviar",
        to: "/messages/send",
      },
      {
        component: CNavItem,
        name: "Logs",
        to: "/messages/logs",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Campañas",
    icon: <BIcon icon={'megaphone'} className="text-danger" />,
    items: [
      {
        component: CNavItem,
        name: "Enviar",
        to: "/campaigns/send",
      },
      {
        component: CNavItem,
        name: "Logs",
        to: "/campaigns/logs",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "WhatsApp",
    icon: <CIcon icon={cibWhatsapp} customClassName="nav-icon text-success" />,
    items: [
      {
        component: CNavItem,
        name: "Integración",
        to: "/whatsapp/integration",
      },
      {
        component: CNavItem,
        name: "Números",
        to: "/whatsapp/numbers",
      },
      {
        component: CNavItem,
        name: "Conversaciones",
        to: "/whatsapp/conversations",
      },
      {
        component: CNavItem,
        name: "Plantillas",
        to: "/whatsapp/templates",
      },
      {
        component: CNavGroup,
        name: "Campañas",
        icon: <BIcon icon={'megaphone'} className="text-danger ps-4 fs-6" style={{ marginLeft: "-35px", marginTop: "0px" }} />,
        items: [
          {
            component: CNavItem,
            className: "ms-3",
            name: "Enviar",
            to: "/whatsapp/campaigns/send",
          },
          {
            component: CNavItem,
            className: "ms-3",
            name: "Logs",
            to: "/whatsapp/campaigns",
          }
        ]
      }
    ],
  },
  {
    component: CNavGroup,
    name: "Contactos",
    icon: <CIcon icon={cilContact} customClassName="nav-icon text-info" />,
    items: [
      {
        component: CNavItem,
        name: "Listado",
        to: "/contacts",
      },
      {
        component: CNavItem,
        name: "Grupos",
        to: "/groups",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Desarrolladores",
    icon: <CIcon icon={cilCode} customClassName="nav-icon text-primary " />,
    items: [
      {
        component: CNavItem,
        name: "Documentación",
        to: "/developer/documentation",
      },
      {
        component: CNavItem,
        name: "Apis",
        to: "/developer/apis",
      },
      {
        component: CNavItem,
        name: "Codigos de error",
        to: "/developer/errorcodes",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Panel de Control",
    icon: <CIcon icon={cilMonitor} customClassName="nav-icon text-dark" />,
    items: [
      {
        component: CNavItem,
        name: "Usuarios",
        to: "/admin/cpanel/users",
      },
      {
        component: CNavItem,
        name: "Cuentas",
        to: "/admin/cpanel/accounts",
      },
      {
        component: CNavItem,
        name: "Proveedores",
        to: "/admin/cpanel/providers",
      },
      {
        component: CNavItem,
        name: "Ingresar como usuario",
        to: "/admin/cpanel/loginasuser",
      },
      {
        component: CNavItem,
        name: "Planes",
        to: "/admin/cpanel/plans",
      },
      {
        component: CNavItem,
        name: "Codigos de error",
        to: "/admin/cpanel/errorcodes",
      },
    ],
  },
];

export default Nav;
