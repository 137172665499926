import React, { useEffect, useState } from "react";
import {
  CButton,
  CSmartTable,
  CCard,
  CCardHeader,
  CCardBody,
  CTabContent,
  CTabPane,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CTable,
  CTableBody,
  CTableRow,
  CTableHeaderCell,
  CTableDataCell,
  CBadge,
} from "@coreui/react-pro";

import * as MarketsmsService from "../../services/MarketsmsService";

import { Link, useParams } from "react-router-dom";
import { MessagesItems } from "../../models/Messages";
import { BIcon } from "../../components/icons/BIcon";
import { getBadgeMessageStatus } from "../../Utils/BaseApp";

interface CampaingMessagesProps { }

export const CampaingMessages: React.FC<
  CampaingMessagesProps
> = ({ }: CampaingMessagesProps) => {
  const { id } = useParams();
  const campaign_id = parseInt(id + "");
  const [messages, setMessages] = useState<MessagesItems[]>([]);
  const [visibleDetails, setVisibleDetails] = useState(false);
  const [details, setDetails] = useState<MessagesItems>();
  const getMessages = async () => {
    var response = await MarketsmsService.getMessagesByCampaign(campaign_id);
    if (response) {
      if (response.status === 200) {
        setMessages(response.data.items);
      }
    }
  };

  const showDetails = (message: MessagesItems) => {
    if (message) {
      setDetails(message);
      setVisibleDetails(true);
    }
  };

  useEffect(() => {
    getMessages();
  }, []);

  const columns = [
    {
      key: "id",
      _style: { width: "4%" },
    },
    {
      key: "from",
      label: "Desde",
      _style: { width: "8%" },
    },
    {
      key: "to",
      label: "Hacia",
      _style: { width: "8%" },
    },
    {
      key: "body",
      label: "Cuerpo",
      _style: { width: "12%" },
    },
    {
      key: "segments",
      label: "Seg.",
      _style: { width: "5%" },
    },
    {
      key: "price",
      label: "Precio",
      _style: { width: "7%" },
    },
    {
      key: "account_name",
      label: "Cuenta",
      _style: { width: "10%" },
    },
    {
      key: "created_at",
      label: "Fecha",
      _style: { width: "12%" },
    },
    {
      key: "message_status_id",
      label: "Estado",
      _style: { width: "7%" },
    },
    {
      key: "operations",
      label: "Acción",
      _style: { width: "10%" },
    },
  ];

  return (
    <div className="col-12">
      <CCard className="mb-4">
        <CCardHeader className="p-0 d-flex align-items-center ps-1">
          <Link to={'/campaigns/logs'} >
            <BIcon icon="arrow-left-short" className="fs-3 me-1 pt-2 text-danger cursor-pointer" />
          </Link>
          <strong>Mensajes para la campaña</strong>
        </CCardHeader>
        <CCardBody>
          <CTabContent className="rounded-bottom">
            <CTabPane
              className="p-3 active preview show"
              role="tabpanel"
              id="preview-578"
            >
              <CSmartTable
                activePage={2}
                cleaner
                clickableRows
                columns={columns}
                columnFilter
                columnSorter
                // footer
                loading={messages.length === 0 ? true : false}
                items={messages}
                itemsPerPageSelect
                itemsPerPage={10}
                pagination
                onFilteredItemsChange={(items) => {
                  // console.log(items)
                }}
                onSelectedItemsChange={(items) => {
                  // console.log(items)
                }}
                scopedColumns={{
                  body: (message: MessagesItems) => (
                    <td>{message.body.substring(0, 15) + " ..."}</td>
                  ),
                  message_status_id: (message: MessagesItems) => (
                    <td>
                      <CBadge color={getBadgeMessageStatus(message.message_status_id)}>
                        {message.status_name}
                      </CBadge>
                    </td>
                  ),
                  operations: (message: MessagesItems) => {
                    return (
                      <td className="py-2 text-center">
                        <Link to={"#"}>
                          <CButton
                            color="primary"
                            variant="outline"
                            shape="square"
                            className="m-1"
                            size="sm"
                            title="Ver más"
                            onClick={() => {
                              showDetails(message);
                            }}
                          >
                            <BIcon icon={"eye"} />
                          </CButton>
                        </Link>
                      </td>
                    );
                  },
                }}
                // selectable
                sorterValue={{ column: "status", state: "asc" }}
                tableFilter
                tableProps={{
                  className: "add-this-class",
                  responsive: true,
                  striped: true,
                  hover: true,
                }}
                tableBodyProps={{
                  className: "align-middle",
                }}
              />
            </CTabPane>
          </CTabContent>
        </CCardBody>
      </CCard>

      <CModal
        visible={visibleDetails}
        onClose={() => setVisibleDetails(false)}
        aria-labelledby="LiveDemoExampleLabel"
      >
        <CModalHeader closeButton>
          <CModalTitle id="LiveDemoExampleLabel">Detalles</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CTable border={0}>
            <CTableBody>
              <CTableRow>
                <CTableHeaderCell scope="row">Id</CTableHeaderCell>
                <CTableDataCell>{details?.id}</CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableHeaderCell scope="row">
                  Id de Referencia
                </CTableHeaderCell>
                <CTableDataCell>{details?.reference_id}</CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableHeaderCell scope="row">Desde</CTableHeaderCell>
                <CTableDataCell>{details?.from}</CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableHeaderCell scope="row">Estado</CTableHeaderCell>
                <CTableDataCell>{details?.status_name}</CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableHeaderCell scope="row">Hacia</CTableHeaderCell>
                <CTableDataCell>{details?.to}</CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableHeaderCell scope="row">Mensaje</CTableHeaderCell>
                <CTableDataCell>{details?.body}</CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableHeaderCell scope="row">Segmentos</CTableHeaderCell>
                <CTableDataCell>{details?.segments}</CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableHeaderCell scope="row">Precio</CTableHeaderCell>
                <CTableDataCell>{details?.price}</CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableHeaderCell scope="row">Fecha</CTableHeaderCell>
                <CTableDataCell>{details?.created_at}</CTableDataCell>
              </CTableRow>
            </CTableBody>
          </CTable>
        </CModalBody>
      </CModal>
    </div>
  );
};
